import { ActionsTypes } from "./actionCreators"
import { TItem } from "../../api/cart"
import { TOrderStatus } from "../../types"

export type TError = {
  item_id: number
  message: string
}

export type TOrderProductForm = {
  city: number | null
  street: string | null
  house: string | null
  name: string | null
  phone: string | null
  email: string | null
  date: Date | null
  time: string | null
}

const initialState = {
  items: [] as TItem[],
  makeOrderError: null as string | null,
  status: "failed" as TOrderStatus,
  total_cost: 0,
  total_items: 0,
  success: null as boolean | null,
  orderSuccess: false,
  itemErrors: [] as TError[],
  QRCode: null as string | null,
  transitionId: null as string | null,
  orderProductForm: {
    city: null,
    street: null,
    house: null,
    name: null,
    phone: null,
    email: null,
    date: null,
    time: null
  } as TOrderProductForm,
  orderProductFormDataWasSaved: false
}

type TInitialState = typeof initialState

export const cartReducer = (
  state = initialState,
  action: ActionsTypes
): TInitialState => {
  switch (action.type) {
    case "cart/SET_MAKE_ORDER_ERROR":
    case "cart/SET_ORDER_PRODUCT_FORM_DATA_WAS_SAVED":
    case "cart/SET_ORDER_PRODUCT_FORM":
    case "cart/SET_TRANSITION_ID":
    case "cart/SET_QR_CODE":
    case "cart/SET_ORDER_SUCCESS":
    case "cart/SET_CART":
      return { ...state, ...action.payload }
    case "cart/RESET_ITEMS":
      return {
        ...state,
        items: [...initialState.items]
      }
    case "cart/CONCAT_ORDER_PRODUCT_FORM":
      return {
        ...state,
        orderProductForm: {
          ...state.orderProductForm,
          ...action.orderProductForm
        }
      }
    case "cart/RESET_ORDER_PRODUCT_FORM":
      return {
        ...state,
        orderProductForm: {
          ...initialState.orderProductForm
        }
      }
    case "cart/RESET_CART":
      return {
        ...initialState
      }
    case "cart/REMOVE_ITEM_ERROR":
      return {
        ...state,
        itemErrors: state.itemErrors.filter(
          (error) => error.item_id !== action.item_id
        )
      }
    case "cart/SET_ITEM_ERROR":
      return {
        ...state,
        itemErrors: [
          ...state.itemErrors.filter(
            (error) => error.item_id !== action.itemError.item_id
          ),
          action.itemError
        ]
      }
    case "cart/SET_PRODUCT_QUANTITY":
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.id === action.itemId) {
            return {
              ...item,
              ad: {
                ...item.ad,
                quantity: action.quantity
              }
            }
          }

          return item
        })
      }
    case "cart/SET_ADDING_TO_CART_SUCCESS":
      return { ...state, success: action.payload }
    default:
      return state
  }
}
