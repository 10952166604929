const calcSize = (
  img: HTMLImageElement,
  maxWidth: number,
  maxHeight: number
) => {
  const { width, height } = img;

  const size = { width, height };

  if (width > height && width > maxWidth) {
    size.height = height * (maxWidth / width);
    size.width = maxWidth;
  }
  if (width <= height && height > maxHeight) {
    size.width = width * (maxHeight / height);
    size.height = maxHeight;
  }

  return size;
};

// Takes a data URI and returns the Data URI corresponding to the resized image at the wanted size.
export const resizedataURL = (
  base64: string,
  wantedWidth: number,
  wantedHeight: number
) => {
  const promise: Promise<string> = new Promise((resolve) => {
    // We create an image to receive the Data URI
    const img = document.createElement("img");

    // When the event "onload" is triggered we can resize the image.
    img.onload = function () {
      // We create a canvas and get its context.
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // We set the dimensions at the wanted size.
      const size = calcSize(img, wantedWidth, wantedHeight);
      canvas.width = size.width;
      canvas.height = size.height;

      // We resize the image with the canvas method drawImage();
      ctx?.drawImage(img, 0, 0, size.width, size.height);

      const dataURI = canvas.toDataURL();

      resolve(dataURI);
    };

    // We put the Data URI in the image's src attribute
    img.src = base64;
  });

  return promise;
};
