import React, { useState } from "react";
import Logo from "../../../components/Logo/Logo";
import Button from "../../../components/Button/Button";
import { useHistory } from "react-router";
import Step1 from "./Step1/Step1";
import Step2 from "./Step2/Step2";

const CreateAdOldPage = () => {
  const history = useHistory();
  const [adType, setAdType] = useState<string | null>(null);

  return (
    <div className="p-10">
      <header>
        <div className="flex justify-between items-center">
          <Logo />
          <h2 className="text-32 font-medium">Новое объявление</h2>
          <Button className="text-16" onClick={() => history.goBack()}>
            Закрыть
          </Button>
        </div>
        <div className="mt-2 w-full h-[1px] bg-c-7" />
      </header>
      {!adType && <Step1 onSelect={(value: string) => setAdType(value)} />}
      {adType && (
        <div className="pt-10 flex flex-col gap-10 max-w-[636px]">
          <Step2 adType={adType} />
        </div>
      )}
    </div>
  );
};

export default CreateAdOldPage;
