import React from "react";
import cn from "clsx";
import { useParams } from "react-router";

import Button from "../../../components/Button/Button";
import Price from "../../../components/Price/Price";
import Gallery from "../../../components/Gallery/Gallery";
import { formatDate } from "../../../components/Date/date.utils";
import ContactButton from "./ContactButton";
import MapButton from "./MapButton";
import { useCarMock } from "../../../api/mock";
import { TPhoto } from "../../../types";
import { SuspencePreloader } from "../../../components/Preloader";
import AdPageBreadcrumbs from "./AdPageBreaadcrumbs";
import GalleryModal from '../../../shared/ui/GalleryModal';

const DISPALY_PROPERTIES = [
  "year",
  (props?: any[]) => ({
    header: "Пробег",
    text: `${getPropertyValue("mileage", props)} км`,
    value: null,
  }),
  "body-type",
  "color",
  (props?: any[]) => {
    const value = getPropertyValue("engine-value", props);
    const power = getPropertyValue("engine-power", props);
    const type = getPropertyValue("engine-type", props);
    return {
      header: "Двигатель",
      text: `${value} л / ${power} л.с. / ${type}`,
      value: null,
    };
  },
  "transmition",
  "drive",
  "direction",
];

const getPropertyValue = (key: string, properties: any[] = []) => {
  const property = properties.find((x) => x.id === key);

  return property.text || property.value;
};

const AdPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: product, isLoading } = useCarMock(id);

  const [isGalleryModalOpen, setIsGalleryModalOpen] = React.useState(false);

  if (isLoading) {
    return <SuspencePreloader />;
  }

  const manufacturer = getPropertyValue("manufacturer", product?.properties);
  const model = getPropertyValue("model", product?.properties);
  const year = getPropertyValue("year", product?.properties);

  const header = `${manufacturer} ${model}, ${year}`;

  return (
    <div className="pb-10">
      {/* TOP LINE */}
      <div className="px-10 flex justify-between items-center">
        {product && <AdPageBreadcrumbs properties={product.properties} />}
        <div className="flex gap-2 text-16 tablet:hidden">
          <Button>В избранное</Button>
          <Button>Пожаловаться</Button>
        </div>
      </div>
      {/* MAIN CONTENT */}
      <div className="px-10 mobile:px-0 flex gap-20 mt-6 tablet:block">
        {/* GALLERY */}
        <Gallery
          value={product?.photo.map((x) => ({ url: x } as TPhoto))}
          className="w-[640px] tablet:w-full"
          onClick={() => setIsGalleryModalOpen(true)}
        />
        {product?.photo && isGalleryModalOpen && (
          <GalleryModal
            onClose={() => setIsGalleryModalOpen(false)}
            photosUrls={product.photo}
            info={
              <div>
                <span className='text-16'>{header}</span>
                <Price
                  className='text-32 mb-[24px]'
                  value={product?.price || 0}
                />
                <span className='text-20 mb-[16px]'>{product?.user.name}</span>
                <ContactButton className="mt-6" value={product?.user.contacts} />
              </div>
            }
          />
        )}

        {/* GENERAL INFO */}
        <div className="tablet:mt-10 mobile:mt-6 mobile:px-10 tablet:flex tablet:gap-2 mobile:flex-col mobile:items-start">
          <div className="tablet:flex-1">
            <h1 className="text-32 font-medium">{header}</h1>
            <p className="text-16 text-c-10 mt-4">
              <span>
                {formatDate(
                  product?.createdDate,
                  (date) =>
                    `${date.date} ${date.month} в ${date.hour}:${date.minute}`
                )}
              </span>
              <span className="ml-6">№ {product?.id}</span>
            </p>
            <div className="py-4 border-t border-b border-c-7 mt-6 inline-block">
              <Price
                className="text-32 font-medium"
                value={product?.price || 0}
              />
            </div>
            {DISPALY_PROPERTIES.map((prop) => {
              if (typeof prop === "string") {
                return product?.properties.find((x) => x.id === prop);
              }
              if (typeof prop === "function") {
                return prop(product?.properties);
              }
              return null;
            }).map((prop, key) => (
              <div
                key={key}
                className={cn(
                  "flex gap-6 text-16",
                  key === 0 ? "mt-6" : "mt-4"
                )}
              >
                <span className="text-c-12 w-[120px]">{prop?.header}</span>
                <span>{prop?.text || prop?.value}</span>
              </div>
            ))}
            <div className="mt-12">
              <h3 className="text-24 font-medium">{product?.user.name}</h3>
              <p className="mt-4 text-16 text-c-12">
                улица Хатирчи, 34, Ташкент
              </p>
              <MapButton className="mt-2">Открыть карту</MapButton>
              <ContactButton className="mt-6" value={product?.user.contacts} />
            </div>
          </div>
          <div className="gap-2.5 text-16 hidden tablet:flex tablet:flex-col mobile:mt-6">
            <Button>В избранное</Button>
            <Button>Пожаловаться</Button>
          </div>
        </div>
      </div>
      {/* BOTTOM DESCRIPTION */}
      <div className="mt-10 px-10 max-w-[800px]">
        <h2 className="text-32 font-medium">Комментарий продавца</h2>
        <p className="mt-6 text-16 whitespace-pre-line">
          {product?.description}
        </p>
      </div>
    </div>
  );
};

export default AdPage;
