/**
 * форматирует цену по принципу: 1234567.89 => 1 234 567.89
 * @param value цена
 * @returns отформатированная строка
 */
export const formatPrice = (value: number) => {
  if (value === undefined || value === null) {
    return "";
  }

  return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};
