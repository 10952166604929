import React, { Dispatch, FC, SetStateAction, useEffect, useMemo } from "react";
import cn from "clsx";
import { useForm } from "react-hook-form";
import s from "./PhoneConfirmModal.module.scss";
import { Input } from "../../common/Input";
import { InputWrapper } from "../../common/InputWrapper/InputWrapper";

type Inputs = {
  code: string;
  msisdn: string;
};

export type Props = {
  msisdn: string; // phone number
  onGoStepBack: () => void;
  onRequestCode: () => void;
  countDownSeconds: number | null;
  errorMessage?: string | null;
  canRequestCode: boolean;
  setCanRequestCode: Dispatch<SetStateAction<boolean>>;
  onSubmit: (data: Inputs) => void;
};

export const PhoneConfirmModal: FC<Props> = ({
  msisdn,
  onGoStepBack,
  countDownSeconds,
  errorMessage,
  onRequestCode,
  canRequestCode,
  setCanRequestCode,
  onSubmit,
}) => {
  // eslint-disable-next-line
  const minutes = useMemo(() => Math.floor((countDownSeconds || 0) / 60), []);
  const { register, handleSubmit, watch } = useForm<Inputs>();

  const watchCode = watch("code");

  useEffect(() => {
    if (!countDownSeconds) {
      setCanRequestCode(true);
    }
  }, [countDownSeconds, setCanRequestCode]);

  return (
    <div className={s.container}>
      <h2 className={s.title}>Подтвердите номер телефона</h2>
      <p className={cn(s.block, s.paragraph)}>
        В течение {minutes} минут{minutes === 1 ? "ы" : ""} вы получите SMS с
        кодом подтверждения на номер {msisdn}.
      </p>
      <p className={cn(s.block, s.paragraph)}>
        Код действителен в течение 2 минут.
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" ref={register} name="msisdn" value={msisdn} />

        <InputWrapper
          error={!!errorMessage}
          helperText={errorMessage || undefined}
        >
          <Input
            classNames={[s.block]}
            input={{
              placeholder: "Код подтверждения",
              ref: register,
              name: "code",
            }}
          />
        </InputWrapper>

        <div className={cn(s.block, s.paragraph, s.timer)}>
          <span>
            Получить новый код можно через{" "}
            {Math.floor((countDownSeconds || 0) / 60)
              .toString()
              .padStart(2, "0")}
            :{((countDownSeconds || 0) % 60).toString().padStart(2, "0")}
          </span>{" "}
          <button
            disabled={!canRequestCode}
            type="button"
            className={cn(s.buttonGet, {
              [s.buttonGet__active]: canRequestCode,
            })}
            onClick={onRequestCode}
          >
            Получить
          </button>
        </div>

        <div className={cn(s.block, s.buttons)}>
          <div className={s.buttonsInner}>
            <div className={s.buttonsItem}>
              <button
                disabled={watchCode === ""}
                className={cn(s.button, s.buttonApprove)}
                type="submit"
              >
                Подтвердить
              </button>
            </div>
            <div className={s.buttonsItem}>
              <button
                className={cn(s.button, s.buttonBack)}
                type="button"
                onClick={onGoStepBack}
              >
                Изменить номер
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
