const MONTH_STR = [
  "января",
  "февраля",
  "марта",
  "апреля",
  "мая",
  "июня",
  "июля",
  "августа",
  "сентября",
  "октября",
  "ноября",
  "декабря",
];

type DestructedDate = {
  month: string;
  date: number;
  hour: number;
  minute: number;
};

export const formatDate = (
  value: string | number | undefined,
  pattern: (value: DestructedDate) => string
) => {
  if (!value) {
    return "";
  }
  const date = new Date(value);

  return pattern({
    month: MONTH_STR[date.getMonth()],
    date: date.getDate(),
    hour: date.getHours(),
    minute: date.getMinutes(),
  });
};
