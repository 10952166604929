import React from "react";
import cn from "clsx";
import { formatPrice } from "./price.utils";

type Props = {
  value: number;
  className?: string;
};

const Price: React.FC<Props> = ({ value, className }) => {
  const formattedValue = formatPrice(value);

  return (
    <h2 className={cn("whitespace-nowrap truncate", className)}>
      {formattedValue}
      &nbsp;
      <span className="text-[0.6em]">сумм</span>
    </h2>
  );
};

export default Price;
