import { TFetchProductsResponse } from "../../api/products";
import { TAdword } from "../../types";
import { ActionsTypes } from "./actionCreators";

const initialState = {
  products: {
    success: false,
    count: 0,
    records: [],
  } as TFetchProductsResponse,
  product: null as TAdword | null,
  adwords: [] as TAdword[],
  error: null as string | null,
  adwordsArchive: [] as TAdword[],
  showEditedModal: false,
  isProductRendering: false,
  isBlockAdwordSuccess: null as boolean | null,
  isReportAdwordSuccess: null as boolean | null,
  isAddFavoritesAdwordSuccess: null as boolean | null,
};

type TInitialState = typeof initialState;

export function productsReducer(
  state = initialState,
  action: ActionsTypes
): TInitialState {
  switch (action.type) {
    case "products/SET_IS_PRODUCT_RENDERING":
    case "products/SET_PRODUCTS":
    case "products/SET_SHOW_EDITED_MODAL":
    case "products/SET_IS_BLOCK_ADWORD_SUCCESS":
      return {
        ...state,
        ...action.payload,
      };
    case "products/BLOCK_ADWORD":
      return {
        ...state,
        products: {
          ...state.products,
          records: state.products.records.map((record) => {
            if (record.id === action.id) {
              return {
                ...record,
                status: "declined",
              };
            }
            return record;
          }),
        },
      };
    case "products/APPROVE_ADWORD":
      return {
        ...state,
        products: {
          ...state.products,
          records: state.products.records.map((record) => {
            if (record.id === action.id) {
              return {
                ...record,
                status: "approved",
              };
            }
            return record;
          }),
        },
      };
    case "products/EDIT_ADWORD":
      return {
        ...state,
        adwords: state.adwords.map((ad) => {
          if (ad.id === action.adword.id) {
            return {
              ...ad,
              ...action.adword,
            };
          }
          return ad;
        }),
      };
    case "products/UNPUBLISH_ADWORD":
      return {
        ...state,
        adwords: [...state.adwords].filter((record) => record.id !== action.id),
      };
    case "products/SET_PRODUCT":
      return { ...state, product: action.payload, error: null };
    case "products/SET_ERROR":
      return { ...state, error: action.error };
    case "products/SET_ADWORDS":
      return { ...state, adwords: action.payload, error: null };
    case "products/SET_ADWORDS_ARCHIVE":
      return { ...state, adwordsArchive: action.payload, error: null };
    case "products/ADD_PRODUCTS":
      return {
        ...state,
        products: {
          ...state.products,
          records: [...state.products.records, ...action.products],
        },
      };
    case "products/SET_TOTAL_COUNT":
      return {
        ...state,
        products: {
          ...state.products,
          count: action.count,
        },
      };
    default:
      return state;
  }
}
