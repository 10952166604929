import { useDispatch } from "react-redux";
import { useEffect } from "react";
import queryString from "query-string";
import { signInViaBabilon } from "../redux/auth/actionCreators";
import { actions } from "../redux/auth/actionCreators";

export const useBabilonAuth = (search: string) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const uriParams = queryString.parse(search);
    if (uriParams.from && uriParams.from === "babilon") {
      if (uriParams.phone && uriParams.token) {
        const body = {
          msisdn: Array.isArray(uriParams.phone)
            ? (uriParams.phone[0] as string)
            : uriParams.phone,
          token: Array.isArray(uriParams.token)
            ? (uriParams.token[0] as string)
            : uriParams.token,
        };
        dispatch(signInViaBabilon(body));
        dispatch(actions.setIsFromBabilon(true));
      }
    }
  }, []); // eslint-disable-line
};
