import React from "react";
import EstimateBlock from "./EstimateBlock";
import AutoBlock from "./AutoBlock";
import AdList from "../../../components/AdList/AdList";
import { useCarListMock } from "../../../api/mock";
import { SuspencePreloader } from "../../../components/Preloader";

const HomePage = () => {
  const { data, isLoading } = useCarListMock();

  if (isLoading) {
    return <SuspencePreloader />;
  }

  return (
    <div className="px-10">
      <div className="flex gap-[80px] items-start">
        <AutoBlock className="flex-1" />
        <EstimateBlock className="w-[352px]" />
      </div>
      <h1 className="mt-10 text-32 font-medium">Рекомендации</h1>
      <AdList data={data} className="mt-10" />
    </div>
  );
};

export default HomePage;
