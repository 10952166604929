import { instance } from "."
import { TAdword, TAdwordStatus } from "../types"

export type TSellerType = "user" | "company"

export type TFetchProductsBody = Partial<{
  limit: number
  offset: number
  order: "date" | "price_asc" | "price_desc"
  city: number
  category: number
  condition: "new" | "used"
  sellerType: TSellerType
  minPrice: number
  maxPrice: number
  word: string
  status: TAdwordStatus
}>
export type TFetchProductsResponse = {
  success: boolean
  count: number
  records: TAdword[]
}
export const fetchProducts = async (body: TFetchProductsBody = {}) => {
  const { data } = await instance.post<TFetchProductsResponse>("/search/items", body, {
    headers: {
      "Content-Type": "application/json",
    },
  })
  return data
}

type TFetchProduct = {
  success: boolean
  data: TAdword
}
export const fetchProduct = async (id: number) => {
  const { data } = await instance.get<TFetchProduct>(`/view/${id}`)
  return data
}

export type TPostData = {
  display: boolean
  limit: number
  offset: number
}
export type TFetchAdwordsResponse = {
  count: number
  records: TAdword[]
}
export const fetchAdwords = async (postData: TPostData) => {
  const { data } = await instance.get<TFetchAdwordsResponse>("/adword/list", {
    params: postData,
  })
  return data
}
