import { useMemo, useState } from "react";
import queryString from "query-string";
import { useOptionsMock } from "../../api/mock";
import Path from "../../pages/router.const";

const defaultArgumentBuilder = (x: any, linkArgument: string) =>
  queryString.stringify({
    [linkArgument]: x.id,
  });

const search = (
  search: string,
  linkArgument: string,
  data?: any[],
  argumentBuilder = defaultArgumentBuilder
) => {
  const searchValue = search.toLowerCase();

  return (data || [])
    .filter((x) => x.name.toLowerCase().indexOf(searchValue) >= 0)
    .map((x) => ({
      text: x.name,
      link: `${Path.CategoryCars}?${argumentBuilder(x, linkArgument)}`,
    }));
};

export const useSearch = () => {
  const [value, setValue] = useState("");

  const { data, isLoading } = useOptionsMock();

  const options = useMemo(() => {
    const result: { text: string; link: string }[] = [];
    if (isLoading || !value) {
      return result;
    }

    result.push(...search(value, "body_type", data?.bodyType));
    result.push(...search(value, "manufacturer", data?.manufacturer));

    const models: any[] = [];
    data?.manufacturer.forEach((manufacturer) => {
      manufacturer.model.forEach((model) => {
        models.push({
          id: model.id,
          name: `${manufacturer.name} ${model.name}`,
          manufacturer: manufacturer.id,
          model: model.id,
        });
      });
    });
    result.push(
      ...search(value, "", models, (x) =>
        queryString.stringify({ manufacturer: x.manufacturer, model: x.model })
      )
    );

    result.push(...search(value, "color", data?.colors));
    result.push(...search(value, "transmission_type", data?.transmitionType));
    result.push(...search(value, "drive_type", data?.driveType));
    result.push(...search(value, "engine_type", data?.engineType));

    return result;
  }, [data, isLoading, value]);

  return { value, setValue, isLoading, options };
};
