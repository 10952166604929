import React from "react";
import Button from "../Button/Button";

type Props = {
  tabs: { text: string; id?: string }[];
  selectedTab?: string;
  onSelect: (id?: string) => void;
};

const Tabs: React.FC<Props> = ({ tabs, selectedTab, onSelect }) => {
  return (
    <div className="flex gap-1">
      {tabs.map((tab, i) => (
        <Button
          key={i}
          compact
          theme={selectedTab === tab.id ? "black" : undefined}
          onClick={() => onSelect(tab.id)}
        >
          {tab.text}
        </Button>
      ))}
    </div>
  );
};

export default Tabs;
