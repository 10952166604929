import React from "react";
import s from "./Footer.module.scss";
import { PageContainer } from "../PageContainer";
// import { Categories } from "./Categories/Categories"
import { Socials } from "./Socials/Socials";

export const Footer = React.memo(() => {
  return (
    <div className={s.footer}>
      <PageContainer>
        {/* <Categories /> */}
        <Socials />
      </PageContainer>
    </div>
  );
});
