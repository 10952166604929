import React, { FC } from 'react';
import { useGetRootCategoryValues } from '../../ad.hooks';
import AdTypeItem from '../../../../pages/app/CreateAdOldPage/Step1/AdTypeItem';
import { ReactComponent as CarIcon } from "../../../../assets/images/icons/icon-car.svg";
import cn from 'clsx';
import { Link } from 'react-router-dom';
import { getPagePath } from '../../../../shared/utils/router';
import Path from '../../../../pages/router.const';

interface RootCategoryValuesProps {

}

const RootCategoryValues: FC<RootCategoryValuesProps> = (props) => {
  const values = useGetRootCategoryValues()

  if (values.isLoading || !values.data) {
    return (
      <div>
        Loading...
      </div>
    )
  }

  return (
    <div className='mt-10 grid grid-cols-6 gap-4'>
      {values.data.map((value, index) => (
        <Link
          to={getPagePath(Path.CreateAd, {categoryValueId: value.id })}
          className={cn(
            index === 0 || index === 1 ? 'col-span-3' : 'col-span-2'
          )}
        >
          <AdTypeItem
            key={value.id}
            text={value.name}
            icon={CarIcon}
          />
        </Link>
      ))}
    </div>
  );
};

export default RootCategoryValues;