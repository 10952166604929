import { ActionsTypes } from "./actionCreators"
import { TGetOrdersResponse, TProfileSummary } from "../../api/profile"

export type TSupportFeedback = {
  success: boolean
  message: string | null
  display: boolean
}
export type TSupport = {
  feedback: TSupportFeedback
  isFetching: boolean
}

const initialState = {
  summary: {} as TProfileSummary,
  isProfileSummarySaving: false,
  passwordNotification: null as string | null,
  passwordChangeSuccess: false,
  isPasswordFetching: false,
  saveProfileNotification: null as string | null,
  saveProfileChangeSuccess: false,
  isSaveProfileFetching: false,
  ordersTab: {
    count: 0,
    orders: []
  } as TGetOrdersResponse,
  support: {
    feedback: {
      success: false,
      message: null,
      display: false
    },
    isFetching: false
  } as TSupport,
  isAvatarChangeFetching: false
}

type TInitialState = typeof initialState

export const profileReducer = (
  state = initialState,
  action: ActionsTypes
): TInitialState => {
  switch (action.type) {
    case 'profile/SET_IS_PROFILE_SUMMARY_SAVING':
    case "profile/SET_ORDERS_TAB":
    case "profile/SET_SAVE_PROFILE_CHANGE_SUCCESS":
    case "profile/SET_SAVE_PROFILE_NOTIFICATION":
    case "profile/SET_IS_PROFILE_FETCHING":
    case "profile/SET_PASSWORD_CHANGE_SUCCESS":
    case "profile/SET_PASSWORD_NOTIFICATION":
    case "profile/SET_IS_PASSWORD_FETCHING":
    case "profile/SET_IS_AVATAR_CHANGE_FETCHING":
    case "profile/SET_PROFILE_SUMMARY":
      return {
        ...state,
        ...action.payload
      }
    case "profile/DELETE_AVATAR":
      return {
        ...state,
        summary: {
          ...state.summary,
          user: {
            ...state.summary.user,
            avatar: ""
          }
        }
      }
    case "profile/SET_SUPPORT_IS_FETCHING":
      return {
        ...state,
        support: {
          ...state.support,
          isFetching: action.isFetching
        }
      }
    case "profile/SET_SUPPORT_FEEDBACK":
      return {
        ...state,
        support: {
          ...state.support,
          feedback: { ...action.feedback }
        }
      }
    case "profile/RESET_SUPPORT_FEEDBACK":
      return {
        ...state,
        support: {
          ...state.support,
          feedback: { ...initialState.support.feedback }
        }
      }
    case "profile/CONCAT_SUPPORT_FEEDBACK":
      return {
        ...state,
        support: {
          ...state.support,
          feedback: {
            ...state.support.feedback,
            ...action.feedback
          }
        }
      }
    case "profile/DELETE_BILL":
      return {
        ...state,
        summary: {
          ...state.summary,
          user: {
            ...state.summary.user,
            bills: state.summary.user.bills.filter(
              (b) => b.id !== action.billId
            )
          }
        }
      }
    case "profile/DELETE_DOC":
      return {
        ...state,
        summary: {
          ...state.summary,
          docs: state.summary.docs.filter((doc) => {
            const filenameSplitted = doc.filename.split("_")
            const idFromFilename = +filenameSplitted[
              filenameSplitted.length - 1
            ]
            if (idFromFilename !== action.docId) {
              return true
            }

            return false
          })
        }
      }
    case "profile/CHANGE_PROFILE_SUMMARY":
      return {
        ...state,
        summary: {
          ...state.summary,
          ...action.summary
        }
      }
    default:
      return state
  }
}
