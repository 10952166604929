import { TAdword } from "../../types";
import { ActionsTypes } from "./actionCreators";

export type TCreateAd = {
  isFetching: boolean;
  error: string | null;
  isSuccess: boolean;
};

const initialState = {
  ads: [] as TAdword[],
  myAds: [] as TAdword[],
  search: [] as Array<any>,
  error: null as string | null,
  createAd: {
    isFetching: false,
    isSuccess: false,
    error: null,
  } as TCreateAd,
};

type TInitialState = typeof initialState;

export function adsReducer(
  state = initialState,
  action: ActionsTypes
): TInitialState {
  switch (action.type) {
    case "ads/CONCAT_CREATE_AD":
      return {
        ...state,
        createAd: {
          ...state.createAd,
          ...action.createAd,
        },
      };
    case "ads/DELETE_ADS_PHOTO":
      return {
        ...state,
        ads: state.ads.map((ad) => {
          if (ad.id === action.adId) {
            return {
              ...ad,
              photos: ad.photos.filter((photo) => photo.id !== action.photoId),
            };
          }
          return ad;
        }),
      };

    case "ads/DELETE_MY_ADS_PHOTO":
      return {
        ...state,
        myAds: state.myAds.map((ad) => {
          if (ad.id === action.adId) {
            return {
              ...ad,
              photos: ad.photos.filter((photo) => photo.id !== action.photoId),
            };
          }
          return ad;
        }),
      };
    case "ads/ADD_AD":
      return { ...state, myAds: [...state.myAds, action.payload] };
    case "ads/SEARCH_ADDRESS":
      return { ...state, search: action.results };
    default:
      return state;
  }
}
