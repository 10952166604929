import React, { FC, CSSProperties, HTMLProps, useState } from "react";
import cn from "clsx";
import styles from "./Input.module.scss";

type Props = {
  label?: string | null;
  input: HTMLProps<HTMLInputElement>;
  inputClassNames?: string[];
  nameClassNames?: string[];
  classNames?: string[];
  style?: CSSProperties;
  isPassword?: boolean;
};

export const Input: FC<Props> = React.memo(
  ({
    label,
    input = {},
    inputClassNames = [],
    nameClassNames = [],
    classNames = [],
    style = {},
    isPassword = false,
  }) => {
    const [showPassword, setShowPassword] = useState(false);

    if (input?.type === "checkbox" || input?.type === "radio") {
      const isCheckbox = input.type === "checkbox";
      const isRadio = input.type === "radio";

      return (
        <label
          className={cn(
            styles.label,
            { [styles.checkbox]: isCheckbox, [styles.radio]: isRadio },
            ...classNames
          )}
          style={style}
        >
          <input {...input} />
          <div
            className={cn(
              {
                [styles.checkboxInput]: isCheckbox,
                [styles.radioInput]: isRadio,
              },
              ...inputClassNames
            )}
          >
            {isCheckbox && <i className="icon-ok" />}
            {isRadio && <div />}
          </div>

          {label && (
            <span className={cn(styles.labelName, ...nameClassNames)}>
              {label}
            </span>
          )}
        </label>
      );
    }

    return (
      <label className={cn(styles.inputLabel, ...classNames)} style={style}>
        {label && (
          <div className={cn(styles.name, ...nameClassNames)}>{label}</div>
        )}

        <div className={styles.inputWrapper}>
          <input
            className={cn(styles.input, ...inputClassNames)}
            {...input}
            type={showPassword ? "text" : input.type}
          />
          {isPassword && (
            <div className={styles.password}>
              {!showPassword && (
                <i
                  className="watcheye-eye"
                  onClick={() => setShowPassword(true)}
                />
              )}
              {showPassword && (
                <i
                  className="watcheye-eye-off"
                  onClick={() => setShowPassword(false)}
                />
              )}
            </div>
          )}
        </div>
      </label>
    );
  }
);
