import React, { useState } from "react";
import Button from "../../../components/Button/Button";
import Slider from "../../../components/Slider/Slider";
import CarGrid from "../../../components/CarGrid/CarGrid";
import { useOptionsMock } from "../../../api/mock";

type Props = {
  className?: string;
};

const AutoBlock: React.FC<Props> = ({ className }) => {
  const { data } = useOptionsMock();

  const [priceFilter, setPriceFilter] = useState<(number | undefined)[]>([]);
  return (
    <div className={className}>
      <CarGrid className="w-full" maxOptions={29} />
      <div className="mt-10 flex gap-10">
        <div className="flex items-center">Цена</div>
        <Slider
          className="flex-1"
          min={data?.filter.price.min}
          max={data?.filter.price.max}
          value={priceFilter}
          onChange={setPriceFilter}
        />
        <Button>Показать 84 458 предложений</Button>
      </div>
    </div>
  );
};

export default AutoBlock;
