import { TFetchProductsBody } from "../../api/products"
import { ActionsTypes } from "./actionCreators"

type TInitialState = {
  isFilterPanelOpened: boolean
  search: TFetchProductsBody
}

export const initialState: TInitialState = {
  isFilterPanelOpened: false,
  search: {
    limit: 5,
    offset: 0,
    condition: undefined,
    sellerType: undefined,
    word: undefined,
    minPrice: undefined,
    maxPrice: undefined,
    category: undefined,
    city: undefined,
    order: undefined,
  },
}

export const categoryReducer = (
  state = initialState,
  action: ActionsTypes
): TInitialState => {
  switch (action.type) {
    case "category/SET_IS_FILTER_PANEL_OPENED":
      return {
        ...state,
        ...action.payload,
      }
    case "category/CONCAT_SEARCH":
      return {
        ...state,
        search: {
          ...state.search,
          ...action.search,
        },
      }
    case "category/RESET_SEARCH":
      return {
        ...state,
        search: {
          ...initialState.search,
          limit: state.search.limit
        },
      }
    default:
      return state
  }
}
