import { useHistory } from 'react-router';
import React from 'react';
import Logo from '../../../../components/Logo/Logo';
import Button from '../../../../components/Button/Button';
import RootCategoryValues from '../../ui/RootCategoryValues';

const SelectAdCategoryPage = () => {
  const history = useHistory();

  return (
    <div className="p-10">
      <header>
        <div className="flex justify-between items-center">
          <Logo />
          <h2 className="text-32 font-medium">Новое объявление</h2>
          <Button className="text-16" onClick={() => history.goBack()}>
            Закрыть
          </Button>
        </div>
        <div className="mt-2 w-full h-[1px] bg-c-7" />
      </header>
      <RootCategoryValues />
    </div>
  );
};

export default SelectAdCategoryPage;