import { fetchBase, TFetchBaseResponse } from "../../api/base";
import { InferActionsTypes, TBaseThunk } from "../store";

export const actions = {
  setBaseAC: (payload: TFetchBaseResponse) =>
    ({ type: "base/SET_BASE", payload } as const),
  setIsLoading: (isLoading: boolean) =>
    ({
      type: "base/SET_IS_LOADING",
      payload: { isLoading },
    } as const),
};

export const getBase = (): TBaseThunk<ActionsType> => (dispatch) => {
  dispatch(actions.setIsLoading(true));
  return fetchBase().then((data) => {
    dispatch(actions.setBaseAC(data));
    dispatch(actions.setIsLoading(false));
  });
};

export type ActionsType = InferActionsTypes<typeof actions>;
