import React, { FC } from "react";
import { useLocation } from "react-router";
import cn from "clsx";
import s from "./PageContainer.module.scss";
import Path from "../../pages/router.const";

export const PageContainer: FC = React.memo(({ children }) => {
  const location = useLocation();
  const onAdminPage = location.pathname.includes(Path.Admin);

  return (
    <div className={cn(s.container, { [s.containerAdmin]: onAdminPage })}>
      {children}
    </div>
  );
});
