import { TAppState } from "../store"

export const getUser = (state: TAppState) => {
  return state.auth.user
}

export const getLoggedIn = (state: TAppState) => {
  return state.auth.loggedIn
}

export const getError = (state: TAppState) => {
  return state.auth.error
}

export const getActiveAuthModal = (state: TAppState) => {
  return state.auth.activeAuthModal
}

export const getPhoneModal = (state: TAppState) => {
  return state.auth.phoneModal
}

export const getIsFetching = (state: TAppState) => {
  return state.auth.isFetching
}

export const getIsProfileCompleted = (state: TAppState) => {
  return state.auth.isProfileCompleted
}

export const getResetPasswordModal = (state: TAppState) => {
  return state.auth.resetPasswordModal
}

export const getResetPasswordModalChange = (state: TAppState) => {
  return state.auth.resetPasswordModalChange
}

export const getResetPasswordModalChangeMessage = (state: TAppState) => {
  return state.auth.resetPasswordModalChangeMessage
}

export const getIsFromBabilon = (state: TAppState) => {
  return state.auth.isFromBabilon
}
