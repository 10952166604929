import queryString from "query-string";
import { useHistory, useLocation } from "react-router";

export const useUrlParams = () => {
  const location = useLocation();
  const history = useHistory();
  const urlParams = { ...queryString.parse(location.search) };

  const setUrlParam = (key: string, value?: string | string[]) => {
    if (value) {
      urlParams[key] = value;
    } else {
      delete urlParams[key];
    }
    const url = location.pathname + "?" + queryString.stringify(urlParams);
    history.push(url);
  };

  return { urlParams, setUrlParam };
};
