import React from "react";
import cn from "clsx";
import MuiSlider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core";

const THUMB_SIZE = 24;

const StyledMuiSlider = withStyles({
  thumb: {
    height: THUMB_SIZE,
    width: THUMB_SIZE,
    marginTop: -THUMB_SIZE / 2,
    marginLeft: -THUMB_SIZE / 2,
  },
})(MuiSlider);

type Props = {
  className?: string;
  min?: number;
  max?: number;
  value?: (number | undefined)[];
  onChange: (value: number[]) => void;
};

const Slider: React.FC<Props> = ({
  className,
  min,
  max,
  value = [],
  onChange,
}) => {
  return (
    <div
      style={{ paddingLeft: THUMB_SIZE / 2, paddingRight: THUMB_SIZE / 2 }}
      className={cn("flex flex-col text-d", className)}
    >
      <StyledMuiSlider
        min={min}
        max={max}
        value={[value[0] || min || 0, value[1] || max || Infinity]}
        onChange={(e, newValue) => onChange?.(newValue as number[])}
        style={{ color: "currentColor" }}
      />
      <div className="flex justify-between text-c-17 text-16">
        <div>от {value?.[0] || min} сум</div>
        <div className="text-right">до {value?.[1] || max} сум</div>
      </div>
    </div>
  );
};

export default Slider;
