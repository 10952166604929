import { ReactComponent as CarIcon } from "../../../assets/images/icons/icon-car.svg";
import { ReactComponent as MotoIcon } from "../../../assets/images/icons/icon-motorcycle.svg";
import { ReactComponent as TruckIcon } from "../../../assets/images/icons/icon-truck.svg";
import { ReactComponent as BusIcon } from "../../../assets/images/icons/icon-bus.svg";
import { ReactComponent as BuldozerIcon } from "../../../assets/images/icons/icon-bulldozer.svg";
import { ReactComponent as BrakesIcon } from "../../../assets/images/icons/icon-brakes.svg";
import { ReactComponent as CarLiftIcon } from "../../../assets/images/icons/icon-car-lift.svg";

export const CATEGORIES = [
  { title: "Легковые автомобили", header: "Авто", colSpan: 3, icon: CarIcon },
  {
    title: "Мототехника",
    header: "Мото",
    colSpan: 3,
    icon: MotoIcon,
    subCategories: [
      { title: "Мотоциклы" },
      { title: "Скутеры" },
      { title: "Квадроциклы" },
      { title: "Багги" },
      { title: "Вездеходы" },
    ],
  },
  {
    title: "Коммерческий транспорт",
    header: "Комтранс",
    colSpan: 2,
    icon: [TruckIcon, BusIcon, BuldozerIcon],
    subCategories: [
      { title: "Лёгкие коммерческие" },
      { title: "Грузовики" },
      { title: "Седельные тягачи" },
      { title: "Автобусы" },
      { title: "Прицепы и полуприцепы" },
      { title: "Сельхозтехника" },
      { title: "Строительная и дорожная" },
      { title: "Погрузчики" },
      { title: "Автокраны" },
      { title: "Экскаваторы" },
      { title: "Бульдозеры" },
      { title: "Коммунальная" },
    ],
  },
  {
    title: "Запчасти и аксессуары",
    header: "Запчасти",
    colSpan: 2,
    icon: BrakesIcon,
    subCategories: [
      { title: "Запчасти" },
      { title: "Шины/Диски/Колёса" },
      { title: "Аудио/Видео" },
      { title: "Аксессуары" },
      { title: "Тюнинг/Стайлинг" },
      { title: "Багажники и фаркопы" },
      { title: "Масла и автохимия" },
      { title: "Противоугонные устройства" },
    ],
  },
  {
    title: "Автосервис",
    header: "Сервис",
    colSpan: 2,
    icon: CarLiftIcon,
    subCategories: [
      { title: "Техобслуживание" },
      { title: "Кузовной ремонт и покраска" },
      { title: "Ремонт" },
      { title: "Тюнинг, стайлинг, оборудование" },
      { title: "Шиномонтаж и ремонт дисков" },
    ],
  },
];

export const CARS = [
  "ГАЗ",
  "ЗАЗ",
  "ЗИЛ",
  "ЗиС",
  "ИЖ",
  "ЛуАЗ",
  "Москвич",
  "СМЗ",
  "ТагАЗ",
  "УАЗ",
  "Acura",
  "Adler",
  "Aito",
  "Alfa Romeo",
  "Alpina",
  "Ariel",
  "Asia",
  "Aston Martin",
  "Audi",
  "Aurus",
  "Austin",
  "Avatr",
  "BAIC",
  "Bajaj",
  "Baojun",
  "Bentley",
  "BMW",
  "Brilliance",
  "Buick",
  "BYD",
  "Cadillac",
  "Changan",
  "Chery",
  "Chevrolet",
  "Chrysler",
  "Ciimo (DongFeng-Honda)",
  "Citroen",
  "Dacia",
  "Daewoo",
  "Daihatsu",
  "Datsun",
  "Delage",
  "Denza",
  "Derways",
  "Dodge",
  "DongFeng",
  "Doninvest",
  "DS",
  "DW Hower",
  "Eagle",
  "Evolute",
  "EXEED",
  "FAW",
  "Ferrari",
  "Fiat",
  "Fisker",
  "Ford",
  "Forthing",
  "Foton",
  "GAC",
  "GAC Aion",
  "GAC Trumpchi",
  "Geely",
  "Genesis",
  "Geo",
  "GMC",
  "Great Wall",
  "Hafei",
  "Haima",
  "Haval",
  "HiPhi",
  "Honda",
  "Hongqi",
  "Hummer",
  "Hyundai",
  "Infiniti",
  "Iran Khodro",
  "Isuzu",
  "JAC",
  "Jaguar",
  "Jeep",
  "Jetour",
  "Jetta",
  "Kaiyi",
  "Kia",
  "LADA (ВАЗ)",
  "Lamborghini",
  "Lancia",
  "Land Rover",
  "Leapmotor",
  "Lexus",
  "Lifan",
  "Lincoln",
  "Livan",
  "LiXiang",
  "Lotus",
  "Luxgen",
  "Lynk & Co",
  "Mahindra",
  "Maserati",
  "Maybach",
  "Mazda",
  "McLaren",
  "Mercedes-Benz",
  "Mercury",
  "Metrocab",
  "MG",
  "MINI",
  "Mitsubishi",
  "Mitsuoka",
  "Morgan",
  "Nio",
  "Nissan",
  "Oldsmobile",
  "OMODA",
  "Opel",
  "Peugeot",
  "Plymouth",
  "Polestar",
  "Pontiac",
  "Porsche",
  "Proton",
  "PUCH",
  "RAM",
  "Ravon",
  "Renault",
  "Renault Samsung",
  "Rivian",
  "Roewe",
  "Rolls-Royce",
  "Rover",
  "Saab",
  "Saturn",
  "Scion",
  "SEAT",
  "Seres",
  "Skoda",
  "Skywell",
  "Smart",
  "Soueast",
  "SsangYong",
  "Subaru",
  "Suzuki",
  "SWM",
  "Tank",
  "Tazzari",
  "Tesla",
  "Toyota",
  "TVR",
  "Volkswagen",
  "Volvo",
  "Vortex",
  "Voyah",
  "Willys",
  "Xpeng",
  "Zeekr",
  "Zotye",
];
