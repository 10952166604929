import React, { FC } from "react";
import cn from "clsx";
import s from "./InputWrapper.module.scss";

type Props = {
  error: boolean;
  helperText: string | undefined;
  classNames?: string[];
};

export const InputWrapper: FC<Props> = React.memo(
  ({ classNames = [], error, helperText, children }) => {
    return (
      <div
        className={cn(s.wrapper, { [s.wrapper__error]: error }, ...classNames)}
      >
        {children}
        {error && !!helperText && (
          <div className={s.errorMessage}>{helperText}</div>
        )}
      </div>
    );
  }
);
