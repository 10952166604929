import { instance } from '../../api';

const GEO_API_KEY = "a5c755ff-f3bd-4fde-bd28-64a6089054b4";
const GEO_API_URL = 'https://geocode-maps.yandex.ru/1.x/'

export const getGeoAddress = (search: string, kind: string): Promise<{geo: string, name: string}[]> => {
  return instance.get(GEO_API_URL, {
    params: {
      apikey: GEO_API_KEY,
      geocode: search,
      format: "json",
      kind,
    },
  }).then(response => response.data)
    .then((data) => {
      const collection = data.response.GeoObjectCollection;
      return collection.featureMember
        // .filter((x: any) => x.GeoObject.metaDataProperty.GeocoderMetaData.kind === kind)
        // .slice(0, 10)
        .map((x: any) => x.GeoObject)
        .map((x: any) => ({
          geo: x.Point.pos,
          name: `${x.name} (${x.description})`,
        }));
  })
};