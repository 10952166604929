import React, { FC, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import cn from "clsx";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import s from "./ResetModal.module.scss";
import { TResetPasswordResponseStatus } from "../../../api/auth";
import { resetPassword } from "../../../redux/auth/actionCreators";
import { getResetPasswordModal } from "../../../redux/auth/selectors";
import {
  getResetPasswordFormData,
  setResetPasswordFormData,
} from "../../../utils/services";

import { Input } from "../../common/Input";
import { Button } from "../../common/Button/Button";
import { InputWrapper } from "../../common/InputWrapper/InputWrapper";
import { TLocationState } from "../ResetPasswordStepper/ResetPasswordStepper";

const schema = yup.object({
  email: yup
    .string()
    .email("Неправильный формат почты")
    .required("Введите почту"),
});

type ResetFormValues = {
  email: string;
};

export const ResetModal: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation<TLocationState>();
  const history = useHistory<TLocationState>();
  const resetPasswordModal = useSelector(getResetPasswordModal);
  const error = useMemo(
    () => resetPasswordModal.errorMessage,
    [resetPasswordModal.errorMessage]
  );
  const [initialValues] = useState(getResetPasswordFormData());

  const { handleSubmit, register, errors, setError } = useForm<ResetFormValues>(
    {
      defaultValues: initialValues,
      mode: "all",
      resolver: yupResolver(schema),
    }
  );

  const onSubmit: SubmitHandler<ResetFormValues> = async (values) => {
    if (!values.email) {
      return setError("email", {
        type: "manual",
        message: "Поле не должно быть пустым",
      });
    }

    setResetPasswordFormData(values);
    let activeStep = 1;
    // @ts-expect-error
    const status: TResetPasswordResponseStatus = await dispatch(
      resetPassword(values.email)
    );
    if (status === "verified") activeStep = 2;

    if (status) {
      history.push({
        ...location,
        state: { activeStep },
      });
    }
  };

  return (
    <form className={s.modal} onSubmit={handleSubmit(onSubmit)}>
      <div className={s.item}>
        <InputWrapper error={!!errors.email} helperText={errors.email?.message}>
          <Input
            input={{
              type: "email",
              name: "email",
              placeholder: "Введите электронную почту",
              ref: (ref) => register(ref),
            }}
          />
        </InputWrapper>
      </div>
      {error && <div className={cn(s.item, s.error)}>{error}</div>}
      <div className={s.item}>
        <Button type="submit">Отправить</Button>
      </div>
    </form>
  );
};
