import React, {
  FC,
  RefObject,
  useState,
  useEffect,
  useMemo,
  useCallback,
  ChangeEvent,
} from "react";
import cn from "clsx";
import { Redirect, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import AvatarEditor from "react-avatar-editor";
import { Map, Placemark, YMaps } from "react-yandex-maps";
import { Select } from "../../../components/common/Select";
import { getLoggedIn, getUser } from "../../../redux/auth/selectors";
import {
  getCities,
  getCategories,
  getConditions,
  getAddressSearchResult,
} from "../../../redux/base/selectors";
import styles from "./createAd.module.scss";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import {
  postAd,
  actions,
  searchAddress,
} from "../../../redux/ads/actionCreators";
import { Preloader } from "../../../components/Preloader";
import { TAdCondition, TAdword } from "../../../types";
import { Modal } from "../../../components/common/Modal/Modal";
import { CreateAdModalSuccess } from "./CreateAdModalSuccess/CreateAdModalSuccess";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  normalizePhoneNumber,
  formatPriceString,
} from "../../../utils/helpers";
import { InputWrapper } from "../../../components/common/InputWrapper/InputWrapper";
import { ModerateModalContent } from "../Profile/Settings/ModerateModalContent/ModerateModalContent";
import { getCreateAd } from "../../../redux/ads/selectors";
import { resizedataURL } from "./utils";
import debounce from "lodash.debounce";
import Path from "../../router.const";

const schema = yup.object().shape({
  city_id: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Обязательное поле"),
  category_id: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Обязательное поле"),
  title: yup
    .string()
    .required("Обязательное поле")
    .max(300, "Не больше 300 символов"),
  description: yup
    .string()
    .required("Обязательное поле")
    .max(3000, "Не больше 3000 символов"),
  quantity: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(1, "Не может быть меньше одного")
    .required("Обязательное поле"),
  price: yup.string().required("Обязательное поле"),
  phone: yup
    .string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Некорректный телефон"
    ),
  geo: yup.string().required("Обязательное поле"),
});

export type FormValues = {
  category_id: number;
  city_id: number;
  title: string;
  description: string;
  phone: string;
  price: number;
  quantity: number;
  condition: TAdCondition;
  geo: string;
};

type Image = {
  ref: RefObject<AvatarEditor>;
  data_url: string;
  rotate: number;
};

export const CreateAd: FC = React.memo(() => {
  const [priceValue, setPriceValue] = useState("");
  const [displayModal, setDisplayModal] = useState(false);
  const [showModerateModal, setShowModerateModal] = useState(false);
  const [showUserBlockedModal, setShowUserBlockedModal] = useState(false);
  const [geo, setGeo] = useState<number[] | null>(null);
  const user = useSelector(getUser);
  const { register, errors, handleSubmit } = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const [images, setImages] = useState<Image[]>([]);
  const [descriptionCharactersLeft, setDescriptionCharactersLeft] =
    useState(3000);
  const loggedIn = useSelector(getLoggedIn);
  const cities = useSelector(getCities);
  const citiesOptions = useMemo(
    () =>
      cities.map((city) => ({
        id: city?.id.toString(),
        name: city?.name,
      })),
    [cities]
  );
  const categories = useSelector(getCategories);
  const createAd = useSelector(getCreateAd);
  const categoriesOptions = useMemo(
    () =>
      categories.map((cat) => ({
        id: cat?.id.toString(),
        name: cat?.name,
      })),
    [categories]
  );
  const conditions = useSelector(getConditions);
  const maxNumber = useMemo(() => 69, []);
  const isMobile = useMediaQuery({ maxWidth: 900 });

  const searchResults = useSelector(getAddressSearchResult);
  const searchResultOptions = useMemo(
    () =>
      searchResults.map((search) => ({ id: search.geo, name: search.name })),
    [searchResults]
  );

  const onChange = useCallback(
    async (imageList: any, addUpdateIndex: number) => {
      const images = await Promise.all(
        imageList.map(async (img: { data_url: string }) => {
          const compressedImg = await resizedataURL(img?.data_url, 480, 480);
          return { ...img, data_url: compressedImg, ref: React.createRef() };
        })
      );

      setImages(images);
    },
    []
  );

  const onImageRotate = useCallback(
    (index: number) => {
      setImages(
        images.map((img, i) => {
          return index === i
            ? { ...img, rotate: img.rotate ? img.rotate - 90 : -90 }
            : img;
        })
      );
    },
    [images]
  );

  const handleDescriptionChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      const value = e.target.value;
      const result = 3001 - value.length < 0 ? 0 : 3001 - value.length;
      setDescriptionCharactersLeft(result);
    },
    []
  );

  const onSubmit = useCallback(
    async (data: TAdword) => {
      const request = {
        ...data,
        price: data.price.toString().split(" ").join(""),
        photos: images.map((img) => img.data_url.split("base64,")[1]),
        display: true,
      };
      dispatch(postAd(request));
    },
    [dispatch, images]
  );

  useEffect(() => {
    if (user?.status === "blocked") {
      setShowUserBlockedModal(true);
    } else {
      setShowUserBlockedModal(false);
    }
  }, [user]);

  useEffect(() => {
    return () => {
      dispatch(
        actions.concatCreateAd({
          error: null,
          isFetching: false,
          isSuccess: false,
        })
      );
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (user?.status === "created" && user.role === "company") {
      setShowModerateModal(true);
    }
  }, [user]);

  useEffect(() => {
    if (createAd.isSuccess) {
      setDisplayModal(true);
    }
  }, [createAd.isSuccess]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onAddressSearch = useCallback(
    debounce((address) => dispatch(searchAddress(address)), 300),
    []
  );

  if (!loggedIn) {
    return <Redirect to={Path.Home} />;
  }

  return (
    <div className={styles.createAd}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <ul className={styles.list}>
          <li className={styles.item}>
            <label>Категория</label>
            <InputWrapper
              error={!!errors?.category_id}
              helperText={errors?.category_id?.message}
              classNames={[styles.categoryInputWrapper]}
            >
              <Select
                style={{ width: "100%" }}
                name={"category_id"}
                label="Все категории"
                options={categoriesOptions}
                register={register({
                  valueAsNumber: true,
                  required: "Это поле обязательное",
                })}
                withSearch
              />
            </InputWrapper>
          </li>
          <li className={styles.item}>
            <label>Состояние</label>
            <Select
              name={"condition"}
              style={{
                width: isMobile ? "100%" : 100,
              }}
              register={register({
                required: "Это поле обязательное",
              })}
              options={conditions.map((cond) => {
                return { id: cond.key, name: cond?.name };
              })}
              withSearch
            />
          </li>

          <li className={styles.item}>
            <label>Название объявления</label>
            <InputWrapper
              classNames={[styles.createAdInputWrapper]}
              error={!!errors?.title}
              helperText={errors?.title?.message}
            >
              <input
                ref={register}
                name="title"
                className={styles.createAdInput}
                type="text"
              />
            </InputWrapper>
          </li>
          <li className={styles.item}>
            <label>Описание объявления</label>
            <InputWrapper
              classNames={[styles.createAdInputWrapper]}
              error={!!errors?.description}
              helperText={errors?.description?.message}
            >
              <div>
                <textarea
                  className={cn(styles.description, styles.createAdInput)}
                  ref={register({
                    required: "Это поле обязательное",
                  })}
                  name="description"
                  onChange={handleDescriptionChange}
                />
                <div className={styles.descriptionCharacters}>
                  {descriptionCharactersLeft} символов осталось
                </div>
              </div>
            </InputWrapper>
          </li>
          <li className={styles.item}>
            <label>Количество</label>
            <InputWrapper
              classNames={[
                styles.withDimension,
                styles.createAdInput,
                styles.quantity,
              ]}
              error={!!errors?.quantity}
              helperText={errors?.quantity?.message}
            >
              <input
                ref={register({
                  valueAsNumber: true,
                })}
                name="quantity"
                type="text"
              />
            </InputWrapper>
          </li>
          <li className={styles.item}>
            <label>Цена</label>
            <InputWrapper
              classNames={[
                styles.withDimension,
                styles.createAdInput,
                styles.price,
              ]}
              error={!!errors?.price}
              helperText={errors?.price?.message}
            >
              <input
                ref={register()}
                name="price"
                type="text"
                value={formatPriceString(priceValue)}
                onChange={(e) => {
                  let value = e.target.value;
                  value = value.replaceAll(/[^\d]/g, "");
                  setPriceValue(value);
                }}
              />
            </InputWrapper>
          </li>
          <li className={styles.item}>
            <label>Фотографии</label>
            <ImageUploading
              multiple
              value={images}
              // @ts-expect-error
              onChange={onChange}
              maxNumber={maxNumber}
              dataURLKey="data_url"
            >
              {({ onImageUpload, onImageRemove, dragProps }) => (
                // write your building UI
                <div className={styles.uploadWrapper}>
                  {images.map((image, index) => {
                    return (
                      <div key={index} className={styles.imageItem}>
                        <AvatarEditor
                          ref={image.ref}
                          image={image["data_url"]}
                          style={{
                            borderRadius: 4,
                            marginRight: 10,
                            width: 106,
                            height: 86,
                            cursor: "auto",
                            objectFit: "contain",
                          }}
                          height={86}
                          position={{
                            x: 0.5,
                            y: 0.5,
                          }}
                          color={[255, 255, 255, 0]} // RGBA
                          rotate={image.rotate || 0}
                        />
                        <button
                          type="button"
                          className={styles.buttonRotate}
                          onClick={() => onImageRotate(index)}
                        />
                        <button
                          type="button"
                          className={styles.buttonDelete}
                          onClick={() => onImageRemove(index)}
                        />
                      </div>
                    );
                  })}
                  <button
                    type="button"
                    className={styles.uploadBtn}
                    onClick={onImageUpload}
                    {...dragProps}
                  ></button>
                </div>
              )}
            </ImageUploading>
          </li>
          <li className={styles.item}>
            <label>Город</label>
            <InputWrapper
              error={!!errors?.city_id}
              helperText={errors?.city_id?.message}
              classNames={[styles.categoryInputWrapper]}
            >
              <Select
                style={{ width: "100%" }}
                name={"city_id"}
                label="Все города"
                options={citiesOptions}
                register={register({
                  valueAsNumber: true,
                  required: "Это поле обязательное",
                })}
                withSearch
              />
            </InputWrapper>
          </li>
          <li className={styles.item}>
            <label>Телефон</label>
            <InputWrapper
              error={!!errors?.phone}
              helperText={errors?.phone?.message}
            >
              <input
                ref={register({
                  required: "Это поле обязательное",
                })}
                name="phone"
                style={{ width: isMobile ? "100%" : 225 }}
                className={styles.createAdInput}
                type="tel"
                value={user?.phone}
                disabled
                onChange={(e) => {
                  e.target.value = normalizePhoneNumber(e.target.value);
                }}
              />
            </InputWrapper>
          </li>
          <li className={styles.item}>
            <label>Адрес</label>
            <InputWrapper
              classNames={[styles.categoryInputWrapper]}
              error={!!errors?.geo}
              helperText={errors?.geo?.message}
            >
              <Select
                style={{ width: "100%" }}
                label="адрес"
                name="geo"
                options={searchResultOptions}
                register={register({
                  required: "Это поле обязательное",
                })}
                withSearch
                onSearch={onAddressSearch}
                onChange={(e, option) => {
                  const geo = ("" + option.id)
                    .split(" ")
                    .map((x) => +x)
                    .reverse();
                  setGeo(geo);
                }}
              />
            </InputWrapper>
          </li>
          {geo && (
            <li className={styles.item}>
              <YMaps>
                <Map
                  width={"100%"}
                  height={450}
                  state={{ center: geo, zoom: 17 }}
                >
                  <Placemark geometry={geo} />
                </Map>
              </YMaps>
            </li>
          )}
        </ul>
      </form>
      {!!createAd.error && <div className={styles.error}>{createAd.error}</div>}
      <div className={styles.submitBtnWrapper}>
        <button
          type="submit"
          disabled={createAd.isFetching}
          className={styles.submitBtn}
          onClick={handleSubmit(onSubmit)}
        >
          Разместить объявление
          <div className={styles.submitBtnPreloader}>
            <Preloader classNames={[styles.submitBtnPreloaderElement]} />
          </div>
        </button>
      </div>

      {displayModal && (
        <Modal
          handleClose={() => {
            setDisplayModal(false);
            history.push(Path.Home);
          }}
          classNames={[styles.createAdModal]}
        >
          <CreateAdModalSuccess
            onClose={() => {
              setDisplayModal(false);
              history.push(Path.Home);
            }}
          />
        </Modal>
      )}
      {showModerateModal && (
        <Modal
          classNames={[styles.moderateModal]}
          handleClose={() => {
            setShowModerateModal(false);
            history.push(Path.Home);
          }}
          onContinue={() => {
            setShowModerateModal(false);
            history.push(Path.Home);
          }}
        >
          <ModerateModalContent>
            <p>Для того, чтобы подать объявление, вы должны пройти модерацию</p>
          </ModerateModalContent>
        </Modal>
      )}
      {showUserBlockedModal && (
        <Modal
          classNames={[styles.moderateModal]}
          handleClose={() => {
            setShowUserBlockedModal(false);
            history.replace(Path.Home);
          }}
        >
          <ModerateModalContent>
            <p>Вы не можете создавать объявления, так как вы заблокированны</p>
          </ModerateModalContent>
        </Modal>
      )}
    </div>
  );
});
