import React, { useState } from "react";
import cn from "clsx";
import AdItem from "./AdItem";
import { AdType } from "./ad.type";
import Button from "../Button/Button";

const SORT = ["По времени размещения", "По цене"];

type Props = {
  className?: string;
  type?: "grid" | "column";
  sort?: boolean;
  favorites?: boolean;
  data?: AdType[];
};

const AdList: React.FC<Props> = ({
  className,
  type = "grid",
  sort,
  favorites,
  data,
}) => {
  const [selected, setSelected] = useState(-1);
  return (
    <div className={className}>
      {sort && (
        <div className="flex gap-4 mb-4">
          <span className="text-c-10">Сортировка</span>
          {SORT.map((sort, i) => (
            <span
              key={i}
              className="cursor-pointer select-none"
              onClick={() => setSelected(selected === i ? -1 : i)}
            >
              {sort}
              <span className={cn(selected !== i && "invisible")}>{" ↓"}</span>
            </span>
          ))}
        </div>
      )}
      <div
        className={cn(type === "grid" && "grid grid-cols-4 gap-x-6 gap-y-10")}
      >
        {data?.map((ad) => (
          <AdItem key={ad.id} type={type} ad={ad}>
            {favorites && <Button theme="gray">Удалить из избранного</Button>}
          </AdItem>
        ))}
      </div>
    </div>
  );
};

export default AdList;
