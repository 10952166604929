import React, { ChangeEvent, useRef, useState, DragEvent, useMemo, useEffect } from 'react';
import Button from '../../../../components/Button/Button';
import { filterFilesByFormats } from '../../../../shared/utils/files';
import { XCircle } from 'lucide-react';
import cn from 'clsx';

const allowedFormats = ['.svg', '.gif', '.png', '.jpg', '.jpeg']

interface DndPhotoInputProps {
  value?: string[];
  onChange?: (files: File[]) => void;
}

const DndPhotoInput: React.FC<DndPhotoInputProps> = (props) => {
  const { onChange } = props;
  const [isDrag, setDrag] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (onChange) {
      onChange(files)
    }
  }, [files.length]);

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDrag(true);
  };

  const handleDragLeave = (e: DragEvent) => {
    e.preventDefault();
    setDrag(false);
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();

    if (!e.dataTransfer.files?.length) {
      return;
    }

    setDrag(false);
    const files = filterFilesByFormats(Array.from(e.dataTransfer.files), allowedFormats)
    setFiles((prevFiles) => [...prevFiles, ...files])
  };


  const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) {
      return;
    }

    const files = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...files])
    e.target.value = ''
  }

  const handleInputClick = () => inputRef.current?.click()

  return (
    <>
      <input
        ref={inputRef}
        multiple
        type='file'
        accept={allowedFormats?.join(',')}
        style={{ display: 'none' }}
        onChange={handleInputChange}
      />
      {files.length ? (
        <div className='w-full flex flex-wrap gap-3'>
          {files.map((file, fileIndex) => (
            <div className='group relative' key={`${file.name}_${fileIndex}`}>
              <div
                className='w-[32px] h-[32px] rounded-full bg-c-3 hidden hover:flex group-hover:flex absolute top-1 right-1 items-center justify-center cursor-pointer'
                onClick={() => setFiles((prevFiles) => {
                  return prevFiles.filter((prevFile, prevFileIndex) => `${prevFile.name}_${prevFileIndex}` !== `${file.name}_${fileIndex}`);
                })}
              >
                <XCircle/>
              </div>
              <img className='w-[130px] h-[130px] rounded-3 object-cover' src={URL.createObjectURL(file)} alt={'preview'}/>
            </div>
          ))}
          <div
            className='w-[130px] h-[130px] bg-c-3 p-3 flex items-center justify-center text-center rounded-3 cursor-pointer text-16'
            onClick={handleInputClick}
          >
            Добавить фото
          </div>
        </div>
      ) : (
        <div
          className={cn('w-full h-[320px] rounded-6 border-2 border-dashed border-c-10 flex flex-col items-center justify-center gap-6', isDrag && 'border-d')}
          onDragEnter={(e) => handleDragEnter(e)}
          onDragLeave={(e) => handleDragLeave(e)}
          onDragOver={(e) => handleDragOver(e)}
          onDrop={(e) => handleDrop(e)}
          onClick={handleInputClick}
        >
          <h3 className="text-24 font-medium">Перетащите фото</h3>
          <p className="text-16 text-c-12">или</p>
          <Button
            className="text-16"
            onClick={handleInputClick}
          >
            Выберите файлы
          </Button>
        </div>
      )}
    </>
  );
};

export default DndPhotoInput;
