import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router";
import { getActiveAuthModal, getLoggedIn } from "../../redux/auth/selectors";

type Props = {
  path: string;
  component: React.FC;
  exact?: boolean;
};

export const LoggedInRoute: FC<Props> = ({
  path,
  component,
  exact = false,
}) => {
  const openAuthType = useSelector(getActiveAuthModal);
  const loggedIn = useSelector(getLoggedIn);
  const isAuthType = openAuthType ? <div /> : <Redirect to="/" />;
  const Component = component;
  return (
    <Route
      exact={exact}
      path={path}
      render={() => {
        return loggedIn ? <Component /> : isAuthType;
      }}
    />
  );
};
