import { instance } from ".";

const API_KEY = "a5c755ff-f3bd-4fde-bd28-64a6089054b4";

export const searchAddress = async (search: string) => {
  const data = await instance.get("https://geocode-maps.yandex.ru/1.x/", {
    params: {
      apikey: API_KEY,
      geocode: search,
      format: "json",
      kind: "house",
    },
  });
  return data;
};
