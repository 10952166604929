import React, { useState } from "react";
import cn from "clsx";
import { Map, Placemark, YMaps } from "react-yandex-maps";
import Modal from "../../../components/Modal/Modal";
import Button from "../../../components/Button/Button";
import ContactButton from "./ContactButton";

type Props = {
  className?: string;
};

const MapButton: React.FC<Props> = ({ className, children }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <p
        className={cn("text-16 text-c-10 cursor-pointer", className)}
        onClick={() => setShow(true)}
      >
        {children}
      </p>
      {show && (
        <Modal className="py-10" handleClose={() => setShow(false)}>
          <div className="px-10">
            <h2 className="text-24 font-medium">Улица Хатирчи, 34, Ташкент</h2>
            <p className="mt-2 text-16">Владелец</p>
            <Button className="mt-6 bg-c-3 text-16">Доехать на такси</Button>
          </div>
          <div className="mt-10 h-[440px] w-[640px] bg-c-2">
            <YMaps>
              <Map
                width={"100%"}
                height={"100%"}
                state={{ center: [55.753215, 37.622504], zoom: 17 }}
              >
                <Placemark geometry={[55.753215, 37.622504]} />
              </Map>
            </YMaps>
          </div>
          <div className="mt-10 px-10 flex justify-between">
            <h3 className="text-24 fofnt-medium">Alexander</h3>
            <ContactButton />
          </div>
        </Modal>
      )}
    </>
  );
};

export default MapButton;
