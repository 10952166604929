import { useEffect, useRef } from "react";

export const useClickOutside = (onClick?: () => void) => {
  const ref = useRef<HTMLDivElement>(null); // HTMLElement

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClick?.();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClick]);

  return ref;
};
