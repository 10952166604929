import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsProfileCompleted,
  getLoggedIn,
  getUser,
} from "../../redux/auth/selectors";
import { Link } from "react-router-dom";

import {
  PROFILE_IS_NOT_MODERATED,
  PROFILE_IS_NOT_COMPLEETED,
} from "./CreateButton.const";
import SimpleModal from "../SimpleModal/SimpleModal";
import { actions } from "../../redux/auth/actionCreators";
import Path from "../../pages/router.const";

const CreateButton: React.FC = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(getLoggedIn);
  const user = useSelector(getUser);
  const isProfileCompleted = useSelector(getIsProfileCompleted);
  const [modalMessage, setModalMessage] = useState<string | null>(null);

  const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const userIsCompany = user?.role === "company";
    if (!loggedIn) {
      e.stopPropagation();
      dispatch(actions.setActiveAuthModalAC("SignIn"));
    }
    if (!userIsCompany) {
      return;
    }
    if (!isProfileCompleted) {
      e.stopPropagation();
      setModalMessage(PROFILE_IS_NOT_COMPLEETED);
      return;
    }
    if (user?.status === "created") {
      e.stopPropagation();
      setModalMessage(PROFILE_IS_NOT_MODERATED);
      return;
    }
  };

  return (
    <>
      <Link
        className="bg-d hover:bg-a text-c-1 text-16 px-8 pt-2.5 pb-3 rounded-3 whitespace-nowrap mobile:hidden"
        to={Path.SelectAdCategory}
        onClick={onClick}
      >
        Выставить объявление
      </Link>
      <SimpleModal onClose={() => setModalMessage(null)}>
        {modalMessage && <p>{modalMessage}</p>}
      </SimpleModal>
    </>
  );
};

export default CreateButton;
