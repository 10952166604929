import React, { FC, CSSProperties, HTMLProps } from "react";
import cn from "clsx";
import s from "./Button.module.scss";

type Props = {
  classNames?: string[];
  style?: CSSProperties;
  button?: HTMLProps<HTMLButtonElement>;
  type?: "button" | "reset" | "submit";
  theme?: "default" | "gray" | "red";
  onClick?: () => void;
  isDisabled?: boolean;
};

export const Button: FC<Props> = ({
  children,
  classNames = [],
  style = {},
  button = {},
  type,
  theme = "default",
  onClick = () => {},
  isDisabled = false,
}) => {
  return (
    <button
      className={cn(
        s.btn,
        { [s[`btn__${theme}`]]: theme !== "default" },
        ...classNames
      )}
      style={style}
      onClick={onClick}
      disabled={isDisabled}
      {...button}
      type={type}
    >
      {children}
    </button>
  );
};
