import { instance, Response } from "."
import { TAdword, TAdCondition } from "../types"

export type TSendAdResponse = {
  ad: TAdword
}

export type TSendAd = {
  title: string
  price: string
  category_id: number
  quantity: number
  description: string
  condition: string
  phone: string
  city_id: number
  photos: string[]
  display: boolean
}

export const sendAd = async (postData: TSendAd) => {
  const { data } = await instance.post<TSendAdResponse>(
    "/adword/create",
    postData
  )
  return data
}

export const unpublish = async (id: number) => {
  const { data } = await instance.post<Response>("/adword/unpublish", { id })
  return data
}

export type TUpdateAd = {
  id: number
  title: string
  price: string
  category_id: number
  quantity: number
  description: string
  condition: TAdCondition
  phone: string
  city: number
  photos: string[]
  display: boolean
}

export const updateAd = async (body: TUpdateAd) => {
  const { data } = await instance.put<Response>("/adword/update", body)
  return data
}

export const deletePhoto = async (id: number) => {
  const res = await instance.delete("/adword/photos", {
    params: {
      id,
    },
  })
  return res
}
