import React from "react";
import { useSelector } from "react-redux";
import { getUser } from "../../../redux/auth/selectors";
import Avatar from "../../../components/AvatarButton/Avatar";
import Input from "../../../shared/ui/Input/Input";
import Button from "../../../components/Button/Button";

const ProfilePage = () => {
  const user = useSelector(getUser);

  return (
    <div className="px-10">
      <h1 className="text-32">
        Контактные данные
        <span className="ml-4 text-c-10">ID</span>
        <span className="ml-4 text-c-10">{user?.id}</span>
      </h1>
      <div className="mt-10 flex gap-4 items-center cursor-pointer">
        <Avatar />
        <span className="text-c-12">Загрузить фото</span>
      </div>
      <div className="mt-10 w-[409px]">
        <div className="text-20 cursor-pointer">Изменить данные</div>
        <div className="mt-4 flex flex-col gap-4">
          <div className="flex gap-6 items-center">
            <div className="w-[83px]">Имя</div>
            <Input className="flex-1" value={user?.name} />
          </div>
          <div className="flex gap-6 items-center">
            <div className="w-[83px]">Телефон</div>
            <Input className="flex-1" value={user?.phone} />
          </div>
          <div className="flex gap-6 items-center">
            <div className="w-[83px]">Телеграм</div>
            <Input className="flex-1" value={"perfecti-ist"} />
          </div>
          <div className="flex gap-6 items-center">
            <div className="w-[83px]">Эл. почта</div>
            <Input className="flex-1" value={user?.email} />
          </div>
          <div className="flex gap-6 items-center">
            <div className="w-[83px]">Город</div>
            <Input className="flex-1" value={"Ташкент"} />
          </div>
        </div>
        <Button className="mt-6" theme="green">
          Сохранить
        </Button>
      </div>
      <div className="mt-[56px] w-[409px]">
        <div className="text-20 cursor-pointer">Изменить пароль</div>
        <div className="mt-4 flex flex-col gap-4">
          <div className="flex gap-6 items-center">
            <div className="w-[143px]">Текущий пароль</div>
            <Input className="flex-1" value={user?.phone} />
          </div>
          <div className="flex gap-6 items-center">
            <div className="w-[143px]">Новый пароль</div>
            <Input className="flex-1" value={"perfecti-ist"} />
          </div>
          <div className="flex gap-6 items-center">
            <div className="w-[143px]">Повторите пароль</div>
            <Input className="flex-1" value={user?.email} />
          </div>
        </div>
        <Button className="mt-6" theme="green">
          Изменить
        </Button>
      </div>
    </div>
  );
};

export default ProfilePage;
