import React from "react";
import AdList from "../../../components/AdList/AdList";
import { useFavorites } from "../../../api/mock";
import { SuspencePreloader } from "../../../components/Preloader";

const FavoritesPage = () => {
  const { data, isLoading } = useFavorites();

  if (isLoading) {
    return <SuspencePreloader />;
  }

  return (
    <div className="px-10 w-[960px]">
      <h1 className="text-32 font-medium">Избранное</h1>
      <AdList sort favorites type="column" className="mt-10" data={data} />
    </div>
  );
};

export default FavoritesPage;
