import React, { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from 'react';
import cn from "clsx";

type InputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { className, value, ...restProps } = props;

  return (
    <input
      className={cn("text-16 bg-c-3 px-4 pt-2.5 pb-3 rounded-3", className)}
      ref={ref}
      value={value || ''}
      {...restProps}
    />
  );
});

export default Input;
