import { useMemo, useEffect } from "react"
import { useDispatch } from "react-redux"
import { getAdwords } from "../redux/products/actionCreators"

export const useGetAdwords = () => {
  const dispatch = useDispatch()
  const request = useMemo(() => ({ display: true, limit: 500, offset: 0 }), [])
  const requestArchive = useMemo(
    () => ({ display: false, limit: 500, offset: 0 }),
    []
  )

  useEffect(() => {
    dispatch(getAdwords(request))
    dispatch(getAdwords(requestArchive))
  }, [dispatch, request, requestArchive])
}
