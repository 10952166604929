import noPhoto from "../../assets/images/common/default-avatar.png";
import { parsePhoneNumberFromString } from "libphonenumber-js";

export const getBase64 = (file: File) => {
  return new Promise<string | ArrayBuffer | null>((resolve) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      const baseURL = reader.result;
      resolve(baseURL);
    };
  });
};

export const formatPrice = (price: number) =>
  new Intl.NumberFormat("tg-TJ", {
    style: "currency",
    currency: "TJS",
    minimumFractionDigits: 0,
  })
    .format(price)
    .replace(/[\xc2\xa0]+/g, " ");

export const getImageSrc = (image?: string, imagesType = "photos") =>
  image ? `${process.env.REACT_APP_API_URL}/${imagesType}/${image}` : noPhoto;

export function throttle(func: Function, ms: number) {
  let isThrottled = false;
  let savedArgs: IArguments | null;
  let savedThis: ThisType<any> | null;

  function wrapper() {
    if (isThrottled) {
      savedArgs = arguments;
      // @ts-expect-error
      savedThis = this;
      return;
    }

    // @ts-expect-error
    func.apply(this, arguments);

    isThrottled = true;

    setTimeout(() => {
      isThrottled = false;
      if (savedArgs) {
        // @ts-expect-error
        wrapper.apply(savedThis, savedArgs);
        savedArgs = savedThis = null;
      }
    }, ms);
  }

  return wrapper;
}

export const removeFalsyPropertiesFromObject = (obj: object): object => {
  const entries = Object.entries(obj).filter(([, value]) => {
    if (Array.isArray(value) && !value.length) {
      return false;
    }
    if (typeof value === "object" && !Object.entries(value).length) {
      return false;
    }

    return !!value;
  });

  return Object.fromEntries(entries);
};

export const normalizePhoneNumber = (value: string) => {
  const phoneNumber = parsePhoneNumberFromString(value);
  if (!phoneNumber) {
    return value;
  }

  return phoneNumber.formatInternational();
};

type TChangePropertiesInObjectValue =
  | string
  | number
  | undefined
  | null
  | boolean;

// TODO: Сделать норм типизацию
export const changePropertiesInObject = <
  O extends object,
  V extends TChangePropertiesInObjectValue
>(
  obj: O,
  value: V,
  replacer: TChangePropertiesInObjectValue
): { [key in keyof O]: Exclude<TChangePropertiesInObjectValue, V> } => {
  const newObjCopy = { ...obj };
  const entries = Object.entries(newObjCopy);
  const resultEntries = entries.map((entry) => {
    if (entry[1] === value) {
      entry[1] = replacer;
    }

    return entry;
  });
  const resultObj = Object.fromEntries(resultEntries);
  // @ts-expect-error
  return resultObj;
};

export const formatPriceString = (price: string) => {
  return price.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const portionPrice = (
  price: string | number,
  portionSize: number,
  replacer: string | undefined = " "
) => {
  const priceNormalized = typeof price === "number" ? price.toString() : price;
  let priceArray = priceNormalized.split("");
  priceArray.reverse();

  for (let i = 0; i < priceArray.length; i++) {
    if (i % (portionSize + 1) === 0) {
      priceArray.splice(i, 0, replacer);
    }
  }

  priceArray.reverse().pop();

  return priceArray.join("");
};

export function shuffle(array: Array<any>) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
