import { instance, ResponseCodes, Response, ErrorResponse } from ".";
import { TUser } from "../types";

export type TSignInUserBody = {
  email?: string;
  phone?: string;
  password: string;
};

export type TSignInUserResponse = {
  code: ResponseCodes;
  user: TUser;
  completed_profile: boolean;
  token: string;
  message: string;
};

export const signInUser = async (userData: TSignInUserBody) => {
  const response = await instance.post<TSignInUserResponse>(
    "/auth/login",
    userData
  );
  return response;
};

export type TSignUpUserBody = {
  role: TUser["role"];
  name: TUser["name"];
  msisdn: string;
  email: TUser["email"];
  password: string;
};

export const signUpUser = async (userData: TSignUpUserBody) => {
  const response = await instance.post<TSignInUserResponse>(
    "/auth/register",
    userData
  );
  // @ts-expect-error
  if (response.data.error) {
    // @ts-expect-error
    throw response.data.error;
  } else {
    return response.data;
  }
};

export type TLogoutUserResponse = {
  code: ResponseCodes;
};

export const logoutUser = async () => {
  const { data } = await instance.post<TLogoutUserResponse>("/auth/logout");
  return data;
};

export type TSignInUserByTokenBody = {
  token: string;
  user_id: number;
};

type TSession = {
  token: string;
  user: TUser;
};

export type TSignInUserByTokenResponse = {
  completed_profile: boolean;
  success: boolean;
  session: TSession;
};

export const signInUserByToken = async (authData: TSignInUserByTokenBody) => {
  try {
    const {
      data: { success, ...data },
      ...res
    } = await instance.post<TSignInUserByTokenResponse>(
      "/auth/checkToken",
      authData
    );

    if (success && res.status === ResponseCodes.StatusCodeSuccess) {
      return data;
    } else {
      localStorage.clear();
      return null;
    }
  } catch (e: any) {
    console.error(e);
    localStorage.clear();
  }
};

export type TSendConfirmCodeBody = {
  msisdn: string;
};

type TError = {
  message: string;
  inner_message: string;
};

export type TRequestConfirmCodeResponseSuccess = {
  code: ResponseCodes.Success;
  status: "codeSent" | "verified";
  ttl: number;
};

export type TRequestConfirmCodeResponseFail = {
  code: ResponseCodes.FailedRequestConfirmCode;
  error: TError;
  request_id: string;
};

export type TRequestConfirmCodeResponse =
  | TRequestConfirmCodeResponseSuccess
  | TRequestConfirmCodeResponseFail;

export const requestConfirmCode = async (body: TSendConfirmCodeBody) => {
  const { data } = await instance.post<TRequestConfirmCodeResponse>(
    "/auth/sendConfirmCode",
    body
  );
  return data;
};

export type TConfirmPhoneBody = {
  msisdn: string;
  code: string;
};

export type TConfirmPhoneResponse =
  | {
      code: ResponseCodes.Success;
    }
  | {
      code: ResponseCodes.FailedConfirmPhone;
      error: TError;
      request_id: string;
    };

export const confirmPhone = async (body: TConfirmPhoneBody) => {
  const { data } = await instance.post<TConfirmPhoneResponse>(
    "/auth/confirmPhone",
    body
  );
  return data;
};

export type TResetPasswordResponseStatus = "codeSent" | "verified";

export type TResetPasswordResponse =
  | Response<{
      status: TResetPasswordResponseStatus;
      ttl: number;
    }>
  | ErrorResponse;

export const resetPassword = (email: string) => {
  return instance
    .post<TResetPasswordResponse>("/auth/resetPassword", {
      email: email,
    })
    .then((r) => r.data);
};

export type TResetPasswordConfirmBody = {
  msisdn: string;
  code: string;
};

export const resetPasswordConfirm = (body: TResetPasswordConfirmBody) => {
  return instance
    .post<Response | ErrorResponse>("/auth/resetPasswordConfirm", body)
    .then((r) => r.data);
};

export type TSetPasswordBody = {
  msisdn: string;
  password: string;
  confirm_password: string;
};

export const setPassword = (body: TSetPasswordBody) => {
  return instance
    .post<Response | ErrorResponse>("/auth/resetPasswordSetPassword", body)
    .then((r) => r.data);
};

export type TSignInViaBabilonBody = {
  msisdn: string;
  token: string;
};

export type TSignInViaBabilonResponse = {
  success: boolean;
  session: {
    token: string;
    user: TUser;
  };
  completed_profile: boolean;
};

export const signInViaBabilon = (body: TSignInViaBabilonBody) => {
  return instance
    .post<TSignInViaBabilonResponse>("/auth/babilon", body)
    .then((r) => r.data);
};
