import { TSearchUserBody } from "../../api/cms"
import { TCompany } from "../../types"
import { ActionsTypes } from "./actionCreators"

const initialState = {
  users: [] as TCompany[],
  totalUsersCount: 0,
  searchUsers: {
    offset: 0,
    limit: 14,
    email: undefined,
    id: undefined,
    phone: undefined,
    role: undefined,
    status: undefined,
    sort: undefined,
    sort_type: undefined,
  } as TSearchUserBody,
  isFetching: false,
}

type TInitialState = typeof initialState

export const cmsReducer = (
  state = initialState,
  action: ActionsTypes
): TInitialState => {
  switch (action.type) {
    case "cms/SET_IS_FETCHING":
    case "cms/SET_TOTAL_USERS_COUNT":
    case "cms/SET_COMPANIES":
      return { ...state, ...action.payload }
    case "cms/CONCAT_USERS":
      return {
        ...state,
        users: {
          ...state.users,
          ...action.users,
        },
      }
    case "cms/CONCAT_SEARCH_USERS":
      return {
        ...state,
        searchUsers: {
          ...state.searchUsers,
          ...action.searchUsers,
        },
      }
    case "cms/APPROVE_USER_SUCCESS":
      return {
        ...state,
        users: state.users.map((company) => {
          if (company.id === action.id)
            return { ...company, status: "approved" }
          return company
        }),
      }
    case "cms/BLOCK_USER_SUCCESS":
      return {
        ...state,
        users: state.users.map((company) => {
          if (company.id === action.id) return { ...company, status: "blocked" }
          return company
        }),
      }
    default:
      return state
  }
}
