import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose,
  Action,
} from "redux"
import thunkMiddleware, { ThunkAction } from "redux-thunk"
import { adsReducer } from "./ads/reducer"
import { appReducer } from "./app/reducer"
import { authReducer } from "./auth/reducer"
import { baseReducer } from "./base/reducer"
import { cmsReducer } from "./cms/reducer"
import { cartReducer } from "./cart/reducer"
import { productsReducer } from "./products/reducer"
import { categoryReducer } from "./category/reducer"
import { profileReducer } from "./profile/reducer"

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  ads: adsReducer,
  base: baseReducer,
  products: productsReducer,
  cart: cartReducer,
  cms: cmsReducer,
  category: categoryReducer,
  profile: profileReducer,
})

// Needed for the Redux DevTools extension
// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunkMiddleware))
)

export default store

export type TAppState = ReturnType<typeof rootReducer>

export type InferActionsTypes<T> = T extends {
  [key: string]: (...args: any[]) => infer U
}
  ? U
  : never

export type TBaseThunk<A extends Action, R = Promise<void>> = ThunkAction<
  R,
  TAppState,
  unknown,
  A
>
