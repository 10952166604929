import React, { useState } from "react";
import { Link } from "react-router-dom";
import Input from "../../shared/ui/Input/Input";
import { useSearch } from "./useSearch";
import { useClickOutside } from "../../hooks/useClickOutside";

const Search: React.FC = () => {
  const { value, setValue, options, isLoading } = useSearch();
  const [isOpen, setIsOpen] = useState(false);

  const ref = useClickOutside(() => setIsOpen(false));

  return (
    <div ref={ref} className="flex gap-4 items-center tablet:hidden">
      Поиск
      <div className="relative w-[400px]">
        <Input
          className="w-full"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            setIsOpen(true);
          }}
          onClick={() => {
            setIsOpen(true);
          }}
        />
        {!isLoading && isOpen && (
          <div className="absolute top-full w-full bg-c-3 rounded-2 overflow-auto max-h-[205px] flex flex-col">
            {options.map((option) => (
              <Link
                key={option.link}
                to={option.link}
                onClick={() => setIsOpen(false)}
                className="py-2 px-4 hover:bg-c-5"
              >
                {option.text}
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Search;
