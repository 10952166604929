import React from "react";
import cn from "clsx";
import { Link, useLocation } from "react-router-dom";

import AvatarButton from "../AvatarButton/AvatarButton";
import CreateButton from "../CreateButton/CreateButton";
import Logo from "../Logo/Logo";
import Search from "../Search/Search";
import { useCategoriesMock } from "../../api/mock";

type Props = {
  className?: string;
};

const Header: React.FC<Props> = ({ className }) => {
  const location = useLocation();
  const { data: categories } = useCategoriesMock();

  // text-c-17 сделал как базовый цвет для текста внутри этого компонента пока не сделаю глобально
  return (
    <header className={cn("text-c-17 pt-10", className)}>
      <div className="px-10 flex justify-between">
        {/* TOP-LEFT PART */}
        <div className="flex gap-10 items-center">
          <Logo />
          <Search />
        </div>

        {/* TOP-RIGHT PART */}
        <div className="flex gap-6">
          <AvatarButton />
          <CreateButton />
        </div>
      </div>
      {/* BOTTOM-PART */}
      <div className="mt-6 pb-4 px-10 w-full overflow-auto ">
        <ul className="flex gap-10">
          {categories?.map(({ id, name, path }) => (
            <li key={id} className="text-16 text-c-12 whitespace-nowrap">
              <Link
                to={path}
                className={cn(
                  path === location.pathname && "text-h font-medium"
                )}
              >
                {name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="px-10">
        <div className="w-full h-[1px] bg-c-7" />
      </div>
    </header>
  );
};

export default Header;
