const enum Path {
  Home = "/",
  CategoryCars = "/cars",
  CategoryMoto = "/moto",
  CategoryCommercial = "/commercial",
  CategoryParts = "/parts",
  CategoryService = "/service",
  SelectAdCategory = '/ad/select-category',
  CreateAd = '/ad/:categoryValueId/create',
  CreateAdOld = "/ad-old/create",
  EditAd = "/ad/edit/:id",
  ViewAd = "/ad/view/:id",
  Category = "/category/:id?",
  Profile = "/profile",
  ProfileAds = "/profile/ads",
  ProfileSettings = "/profile/settings",
  ProfileSupport = "/profile/support",
  Favorites = "/favorites",
  Policy = "/policy",
  Agreement = "/agreement",
  Admin = "/admin",
  AdminCompanies = "/admin/companies",
  AdminAds = "/admin/adwords/:id?",

  AdminCategoryTypes = '/admin/category-types',
  AdminCategoryTypeAdd = '/admin/category-types/add',
  AdminCategoryTypeEdit = '/admin/category-types/:categoryTypeId/edit',
  AdminCategoryType = '/admin/category-types/:categoryTypeId/view',
  AdminCategoryValue = '/admin/category-values/:categoryValueId/view',
  AdminCategoryTypeAddValue = '/admin/category-types/:categoryTypeId/add-value',
  AdminCategoryValueEdit = '/admin/category-values/:categoryValueId/edit',
  AdminCategoryValueEditOrder = '/admin/category-values/:categoryValueId/edit-order',
}

export default Path;
