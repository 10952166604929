import { ErrorResponse, ResponseCodes } from "../../api";
import * as adsApi from "../../api/ads";
import * as yaApi from "../../api/ya";
import { TAdword } from "../../types";
import { InferActionsTypes, TBaseThunk } from "../store";
import { TCreateAd } from "./reducer";

export const actions = {
  addAdAC: (payload: TAdword) => ({ type: "ads/ADD_AD", payload } as const),
  deleteAdsPhoto: (adId: number, photoId: number) =>
    ({ type: "ads/DELETE_ADS_PHOTO", adId, photoId } as const),
  deleteMyAdsPhoto: (adId: number, photoId: number) =>
    ({ type: "ads/DELETE_MY_ADS_PHOTO", adId, photoId } as const),
  concatCreateAd: (createAd: Partial<TCreateAd>) =>
    ({
      type: "ads/CONCAT_CREATE_AD",
      createAd,
    } as const),
  searchAddress: (results: Array<any>) =>
    ({ type: "ads/SEARCH_ADDRESS", results } as const),
};

export const postAd =
  (postData: adsApi.TSendAd): Thunk =>
  async (dispatch) => {
    dispatch(actions.concatCreateAd({ isFetching: true }));

    try {
      const data = await adsApi.sendAd(postData);

      if (data?.ad) {
        dispatch(actions.addAdAC(data.ad));
        dispatch(actions.concatCreateAd({ isSuccess: true, error: null }));
      } else {
        dispatch(
          actions.concatCreateAd({
            isSuccess: false,
            error: "Некорректно указаны данные",
          })
        );
      }
    } catch (e: any) {
      const data: ErrorResponse = e.response.data;
      dispatch(
        actions.concatCreateAd({ isSuccess: false, error: data.error.message })
      );
    }

    dispatch(actions.concatCreateAd({ isFetching: false }));
  };

export const deletePhoto =
  (adId: number, photoId: number): Thunk =>
  async (dispatch) => {
    const res = await adsApi.deletePhoto(photoId);
    if (res.status === ResponseCodes.StatusCodeSuccess) {
      dispatch(actions.deleteMyAdsPhoto(adId, photoId));
    }
  };

export const searchAddress =
  (search: string): Thunk =>
  async (dispatch) => {
    // TODO: throttle or debounce
    if (!search) {
      return;
    }
    const res = await yaApi.searchAddress(search.replaceAll(" ", "+"));
    if (res.status === ResponseCodes.StatusCodeSuccess) {
      const collection = res.data.response.GeoObjectCollection;
      const result = collection.featureMember
        .map((x: any) => x.GeoObject)
        .map((x: any) => ({
          name: `${x.name} (${x.description})`,
          geo: x.Point.pos,
        }));
      dispatch(actions.searchAddress(result));
    }
  };

export type ActionsTypes = InferActionsTypes<typeof actions>;
type Thunk = TBaseThunk<ActionsTypes, void>;
