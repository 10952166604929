import React, { useState } from "react";
import cn from "clsx";
import Button from "../../../components/Button/Button";
import { ReactComponent as TgIcon } from "../../../assets/images/icons/telegram.svg";

type Props = {
  className?: string;
  value?: { type: string; value: string }[];
};

const ContactButton: React.FC<Props> = ({ value, className }) => {
  const [show, setShow] = useState(false);

  if (show && value) {
    return (
      <ul className={cn("text-d text-16", className)}>
        {value.map((x) => {
          if (x.type === "phone") return <li>{x.value}</li>;
          if (x.type === "telegram")
            return (
              <li>
                <TgIcon className="inline-block mr-1" />
                {x.value}
              </li>
            );
          return null;
        })}
      </ul>
    );
  }

  return (
    <Button
      disabled={show}
      className={cn(
        show ? "bg-c-2 text-c-17" : "bg-d text-c-1",
        "text-16",
        className
      )}
      onClick={() => setShow(true)}
    >
      {show ? value || "n/a" : "Показать контакты"}
    </Button>
  );
};

export default ContactButton;
