import React, { useState } from "react";
import cn from "clsx";
import CarGridItem from "./CarGridItem";
import { useOptionsMock } from "../../api/mock";

type Props = {
  className?: string;
  maxOptions: number;
};

const CarGrid: React.FC<Props> = ({ className, maxOptions }) => {
  const { data } = useOptionsMock();

  const [showAll, setShowAll] = useState(false);

  return (
    <div className={cn("grid grid-cols-5 gap-4", className)}>
      {data?.manufacturer.slice(0, showAll ? -1 : maxOptions).map((x, i) => (
        <CarGridItem
          key={i}
          id={x.id}
          description={"" + x.model.reduce((acc, val) => acc + val.count, 0)}
        >
          {x.name}
        </CarGridItem>
      ))}
      {(data?.manufacturer || []).length > maxOptions + 1 && (
        <CarGridItem
          description={showAll ? "↑" : "↓"}
          onClick={(cancel) => {
            cancel();
            setShowAll((value) => !value);
          }}
        >
          <span className="text-c-10">{showAll ? "Меньше" : "Все марки"}</span>
        </CarGridItem>
      )}
    </div>
  );
};

export default CarGrid;
