import React, { FC, Suspense } from "react";
import { Route } from "react-router";
import { SuspencePreloader } from "../Preloader";

type Props = {
  path: string;
  component: React.FC;
};

export const SuspenseRoute: FC<Props> = ({ path, component }) => {
  const Component = component;

  return (
    <Route
      path={path}
      render={() => (
        <Suspense fallback={<SuspencePreloader />}>
          <Component />
        </Suspense>
      )}
    />
  );
};
