import React, { FC } from "react";
import { Route, Switch } from "react-router";
import { LoggedInRoute } from "../../components/Router/LoggedInRoute";
import { EditAd } from "./EditAd/EditAd";
import AdPage from "./AdPage/AdPage";
import CreateAdOldPage from "./CreateAdOldPage/CreateAdOldPage";
import CreateAdPage from "../../features/ad/pages/CreateAdPage";
import Header from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import Path from "../router.const";
import { SuspenseRoute } from "../../components/Router/SuspenseRoute";
import HomePage from "./HomePage/HomePage";
import CarFilterPage from "./CarFilterPage/CarFilterPage";
import { CreateAd } from "./CreateAd";
import FavoritesPage from "./FavoritesPage/FavoritesPage";
import ProfileAdsPage from "./ProfileAdsPage/ProfileAdsPage";
import ProfilePage from "./ProfilePage/ProfilePage";
import MotoFilterPage from "./MotoFilterPage/MotoFilterPage";
import SelectAdCategoryPage from '../../features/ad/pages/SelectAdCategoryPage';

const Policy = React.lazy(() =>
  import("./Policy/Policy").then(({ Policy }) => ({ default: Policy }))
);
const Agreement = React.lazy(() =>
  import("./Agreement/Agreement").then(({ Agreement }) => ({
    default: Agreement,
  }))
);

const AppRouter: FC = () => {
  return (
    <>
      <Switch>
        <LoggedInRoute path={Path.SelectAdCategory} component={SelectAdCategoryPage} />
        <LoggedInRoute path={Path.CreateAd} component={CreateAdPage} />
        <LoggedInRoute path={Path.CreateAdOld} component={CreateAdOldPage} />
        <LoggedInRoute path={"/old/ad/create"} component={CreateAd} />
        <Route>
          <Header className="max-w-[1240px] mx-auto text-16" />
          <div className="max-w-[1240px] mx-auto py-10 text-16">
            <Route exact path={Path.Home} component={HomePage} />
            <Route path={Path.CategoryCars} component={CarFilterPage} />
            <Route path={Path.CategoryCommercial} component={MotoFilterPage} />
            <Route path={Path.CategoryMoto} component={MotoFilterPage} />
            <Route path={Path.CategoryParts} component={MotoFilterPage} />
            <Route path={Path.CategoryService} component={MotoFilterPage} />
            <Route path={Path.ViewAd} component={AdPage} />
            <LoggedInRoute path={Path.EditAd} component={EditAd} />
            <SuspenseRoute path={Path.Policy} component={Policy} />
            <SuspenseRoute path={Path.Agreement} component={Agreement} />
            <LoggedInRoute path={Path.Favorites} component={FavoritesPage} />
            <LoggedInRoute path={Path.ProfileAds} component={ProfileAdsPage} />
            <LoggedInRoute path={Path.Profile} exact component={ProfilePage} />
            {/* роут пока что еще нужен, но скоро следует выкосить */}
            {/* <LoggedInRoute path={Path.Profile} exact component={Profile} /> */}
          </div>
          <Footer />
        </Route>
      </Switch>
    </>
  );
};

export default React.memo(AppRouter);
