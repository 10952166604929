import React from "react";
import cn from "clsx";

type Props = {
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  compact?: boolean;
  theme?: "green" | "gray" | "black" | "transparent";
};

const Button: React.FC<Props> = ({
  className,
  onClick,
  disabled,
  compact,
  theme = "gray",
  children,
}) => {
  return (
    <button
      disabled={disabled}
      className={cn(
        "select-none",
        compact ? "px-4 pt-1 pb-1.5 rounded-2" : "rounded-3 px-8 pt-2.5 pb-3",
        theme === "green" && "bg-d text-c-1 hover:bg-h",
        theme === "gray" && "bg-c-3 hover:bg-d hover:text-c-1",
        theme === "black" && "bg-c-17 text-c-1 hover:bg-c-15",
        theme === "transparent" && "hover:bg-c-3",
        disabled && "pointer-events-none",
        className
      )}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick?.();
      }}
    >
      {children}
    </button>
  );
};

export default Button;
