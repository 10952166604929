import { TAppState } from "../store";

export const getBase = (state: TAppState) => {
  return state.base;
};
export const getCategories = (state: TAppState) => {
  return state.base.categories;
};
export const getCities = (state: TAppState) => {
  return state.base.cities;
};
export const getConditions = (state: TAppState) => {
  return state.base.conditions;
};

export const getAddressSearchResult = (state: TAppState) => {
  return state.ads.search;
};

export const getCategoryName = (catId: number) => (state: TAppState) =>
  state.base.categories.find((cat) => cat.id === catId);

export const getCategoryById = (id?: number) => (state: TAppState) => {
  if (id === undefined) return null;
  return state.base.categories.find((cat) => cat.id === id);
};

export const getIsLoading = (state: TAppState) => {
  return state.base.isLoading;
};
