import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { ResetModal } from "../ResetModal/ResetModal";
import {
  PhoneConfirmModal,
  Props as PhoneProps,
} from "../PhoneConfirmModal/PhoneConfirmModal";
import {
  ResetPasswordChangeModal,
  Props as ResetPasswordChangeModalProps,
} from "../ResetPasswordChangeModal/ResetPasswordChangeModal";
import { getResetPasswordModal } from "../../../redux/auth/selectors";
import {
  resetPassword,
  resetPasswordConfirm,
  actions,
} from "../../../redux/auth/actionCreators";
import {
  getResetPasswordFormData,
  clearResetPasswordFormData,
} from "../../../utils/services";

type TTab = {
  component: React.ComponentType<PhoneProps & ResetPasswordChangeModalProps>;
};

const TABS: TTab[] = [
  {
    component: ResetModal,
  },
  {
    component: PhoneConfirmModal,
  },
  {
    component: ResetPasswordChangeModal,
  },
];

export type TLocationState = {
  activeStep: number;
};

type ApprovePhoneFormValues = {
  msisdn: string;
  code: string;
};

type Props = {
  setShowResetModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ResetPasswordStepper: React.FC<Props> = ({
  setShowResetModal,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const resetPasswordModal = useSelector(getResetPasswordModal);
  const [canRequestCode, setCanRequestCode] = useState(false);

  const { state = { activeStep: 0 } } = useLocation<TLocationState>();

  const onResetPhoneSuccessSubmit = (data: ApprovePhoneFormValues) => {
    dispatch(resetPasswordConfirm(data));
    history.push({
      ...location,
      state: {
        activeStep: 2,
      },
    });
  };

  const requestCode = () => {
    setCanRequestCode(false);
    dispatch(resetPassword(getResetPasswordFormData().phone));
  };

  const onGoStepBack = () => {
    dispatch(
      actions.setResetPasswordModal({
        timer: null,
        showModal: false,
        errorMessage: null,
      })
    );
    history.push({
      ...location,
      state: {
        activeStep: 0,
      },
    });
  };

  const tab = TABS[state.activeStep];

  useEffect(() => {
    return () => {
      clearResetPasswordFormData();
      dispatch(actions.resetResetPasswordModal());
      dispatch(actions.resetResetPasswordModalChange());
      history.push({
        ...location,
        state: {
          activeStep: 0,
        },
      });
    };
  }, []); // eslint-disable-line

  return (
    <tab.component
      countDownSeconds={resetPasswordModal.timer}
      msisdn={getResetPasswordFormData().phone}
      errorMessage={resetPasswordModal.errorMessage}
      onRequestCode={requestCode}
      canRequestCode={canRequestCode}
      setCanRequestCode={setCanRequestCode}
      onSubmit={onResetPhoneSuccessSubmit}
      onGoStepBack={onGoStepBack}
      setShowResetModal={setShowResetModal}
    />
  );
};
