import { useQuery } from "@tanstack/react-query";
import { createArray, delay, random } from "../utils/common";
import { AdType } from "../components/AdList/ad.type";
import Path from "../pages/router.const";

const createCarMock: (isFavorite?: boolean, isOwner?: boolean) => AdType = (
  isFavorite = false,
  isOwner = false
) => ({
  id: random(1000000, 9999999),
  description: `Rolls-Royce Wraith
  Цвет кузова: Black Kirsch (Черный)
  Цвет салона: Oatmeal/Dark Spice (Бежевый /Темный шоколад)
  Отделка панели: Piano Black (Черный рояльный лак).
  Камеры 360
  Частично полированные десяти-спицевые диски
  Индивидуальная отделка салона Bespoke
  Контрастный кант в цветеDark spice (Темный шоколад)
  Контрастная строчка в цвете Dark spice (Темный шоколад)
  Вышивка логотипа RR на четырех подголовниках в цвете Dark spice (Темный шоколад)
  Выбор цвета рулевого колеса Dark spice (Темный шоколад)
  Система комфортного входа
  Потолок «Звездное небо»
  Аудиосистема Rolls-Royce Bespoke Audio
  Передние сиденья вентилируемые
  Передние сидения с функцией массажа
  Система ассистирования водителю:
  - контроль движения по полосе
  - проекция на лобовое стекло
  - автоматическое управление дальним светом
  
  Автомобиль покупался и обслуживался у Официального дилера.
  Состояние автомобиля идеальное.
  Полностью обслужен в Авилон .
  Кузов покрыт керамикой.
  
  Обмен на автомобиль.`,
  isFavorite,
  isOwner,
  status: isOwner
    ? (["draft", "active", "canceled", "sold"][
        random(0, 3)
      ] as AdType["status"])
    : "draft",
  photo: [
    "https://avatars.mds.yandex.net/get-autoru-vos/7067970/5a37b78d951bc249a260655c0ba7993a/1200x900n",
    "https://avatars.mds.yandex.net/get-autoru-vos/2154580/9e29d81afbcfa838dd163e13d7506a10/1200x900n",
    "https://avatars.mds.yandex.net/get-autoru-vos/7067970/57f8a07168f07a923f963f7bfbe7c2eb/1200x900n",
    "https://avatars.mds.yandex.net/get-autoru-vos/2163731/179cf7cba6fb736003496a6f162e1db4/1200x900n",
    "https://avatars.mds.yandex.net/get-autoru-vos/2168684/b6e3f452c423eeb0eb9171d69c2a542f/1200x900n",
    "https://avatars.mds.yandex.net/get-autoru-vos/2161408/e67192e1ccd43cab5f032011b3d3cd53/1200x900n",
    "https://avatars.mds.yandex.net/get-autoru-vos/1670150/5dc9e137a5670ddc901df3c701c14e43/1200x900n",
    "https://avatars.mds.yandex.net/get-autoru-vos/2062947/f80ca51c4d08f875cd6b436a22689ac1/1200x900n",
    "https://avatars.mds.yandex.net/get-autoru-vos/2105323/2d65d237cb8073ee60bd3a0d46327b7a/1200x900n",
    "https://avatars.mds.yandex.net/get-autoru-vos/2062947/4b9c10a4a5ab4aaba80f4c2ad1de7b74/1200x900n",
    "https://avatars.mds.yandex.net/get-autoru-vos/2155976/8a841100c155e2fcdd4d236beb16f574/1200x900n",
    "https://avatars.mds.yandex.net/get-autoru-vos/2157088/db647b198e8172adc4b6eaea2d5170fa/1200x900n",
    "https://avatars.mds.yandex.net/get-autoru-vos/2181439/c39148e3c05f0e6ee017f18141689e9e/1200x900n",
    "https://avatars.mds.yandex.net/get-autoru-vos/1655347/984b8c7ff4e7ef781e62683db5444ba1/1200x900n",
    "https://avatars.mds.yandex.net/get-autoru-vos/2165520/28c825810fa3935a2c3c95e29de4c584/1200x900n",
  ],
  price: random(100, 12000) * 1000 * 1000,
  createdDate: Date.now() - 1000 * 60 * 60 * 24 * random(3, 55),
  properties: [
    { id: "category", header: "Категория", text: "Авто", value: "auto" },
    { id: "isNew", header: "Тип", text: "б/у", value: false },
    {
      id: "manufacturer",
      header: "Производитель",
      text: "Rolls-Royce",
      value: "rollsroyce",
    },
    { id: "model", header: "Модель", text: "Wraith", value: "wraith" },
    { id: "year", header: "Год выпуска", value: random(2018, 2022) },
    { id: "mileage", header: "Пробег", value: random(50, 130) * 100 },
    { id: "body-type", header: "Кузов", text: "Седан", value: "sedan" },
    { id: "color", header: "Цвет", text: "Голубой", value: "blue" },
    { id: "engine-value", header: "Объем", value: 6.6 },
    { id: "engine-power", header: "Мощность", value: 632 },
    {
      id: "engine-type",
      header: "Тип двигателя",
      text: "Бензин",
      value: "gasoline",
    },
    {
      id: "transmition",
      header: "Коробка",
      text: "Автоматическая",
      value: "automatic",
    },
    { id: "drive", header: "Привод", text: "Полный", value: "full" },
    { id: "direction", header: "Руль", text: "Левый", value: "left" },
  ],

  user: {
    name: "Alexander",
    address: "улица Хатирчи, 34, Ташкент",
    geo: [55.753215, 37.622504],
    contacts: [
      { type: "phone", value: "+998 789 654 321" },
      { type: "telegram", value: "@perfectiist" },
    ],
  },
});

export const getProductMock = async (id: string) => {
  await delay(random(1000, 5000));
  return createCarMock();
};

export const useCarMock = (id: string) =>
  useQuery({
    queryKey: ["car", id],
    queryFn: () => getProductMock(id),
  });

export const getProductListMock = async (
  isFavorite = false,
  isOwner = false
) => {
  await delay(random(1000, 5000));
  return createArray(10).map(() => createCarMock(isFavorite, isOwner));
};

export const useCarListMock = () =>
  useQuery({
    queryKey: ["car-list"],
    queryFn: () => getProductListMock(),
  });

export const useFavorites = () =>
  useQuery({
    queryKey: ["user", "favorites"],
    queryFn: () => getProductListMock(true),
  });

export const useUserAds = () =>
  useQuery({
    queryKey: ["user", "ads"],
    queryFn: () => getProductListMock(false, true),
  });

const optionsMock = {
  condition: [{ id: "new" }, { id: "used" }],
  bodyType: [
    { id: "sedan", name: "седан" },
    { id: "universal", name: "универсал" },
    { id: "hatchback", name: "хэтчбек" },
    { id: "coupe", name: "купе" },
  ],
  transmitionType: [
    { id: "manual", name: "МКПП" },
    { id: "auto", name: "АКПП" },
    { id: "robot", name: "Робот" },
  ],
  driveType: [
    { id: "fwd", name: "Передний" },
    { id: "bwd", name: "Задний" },
    { id: "4x4", name: "Полный" },
  ],
  engineType: [
    { id: "gas", name: "Бензин" },
    { id: "diesel", name: "Дизель" },
    { id: "hybrid", name: "Гибрид" },
    { id: "electro", name: "Электро" },
  ],
  colors: [
    { id: "black", name: "Черный", value: "#000" },
    { id: "white", name: "Белый", value: "#FFF" },
    { id: "green", name: "Зеленый", value: "green" },
    { id: "pink", name: "Розовый", value: "hotpink" },
    { id: "red", name: "Красный", value: "red" },
    { id: "gray", name: "Серый", value: "gray" },
    { id: "yellow", name: "Желтый", value: "yellow" },
  ],
  filter: {
    price: {
      min: 100000000,
      max: 23477234949,
    },
    seatCount: {
      min: 1,
      max: 7,
    },
    year: {
      min: 1950,
    },
  },
  manufacturer: [
    {
      id: "chevrolet",
      name: "Chevrolet",
      model: [
        { id: "aveo", name: "Aveo", count: random(500, 2000) },
        { id: "cruze", name: "Cruze", count: random(400, 1500) },
        { id: "lanos", name: "Lanos", count: random(300, 1000) },
        { id: "niva", name: "Niva", count: random(200, 500) },
      ],
    },
    {
      id: "audi",
      name: "Audi",
      model: [
        { id: "100", name: "100", count: random(500, 2000) },
        { id: "a4", name: "A4", count: random(400, 1500) },
        { id: "q7", name: "Q7", count: random(300, 1000) },
        { id: "a6", name: "A6", count: random(200, 500) },
      ],
    },
    {
      id: "vaz",
      name: "LADA (ВАЗ)",
      model: [
        { id: "granta", name: "Granta", count: random(500, 2000) },
        { id: "kalina", name: "Kalina", count: random(400, 1500) },
        { id: "vesta", name: "Vesta", count: random(300, 1000) },
        { id: "2106", name: "2106", count: random(200, 500) },
      ],
    },
    {
      id: "chery",
      name: "Chery",
      model: [
        { id: "tiggo4p", name: "Tiggo 4 Pro", count: random(500, 2000) },
        { id: "tiggo7pm", name: "Tiggo 7 Pro Max", count: random(400, 1500) },
        { id: "a15", name: "Amulet (A15)", count: random(300, 1000) },
      ],
    },
    {
      id: "haval",
      name: "Haval",
      model: [
        { id: "jolion", name: "Jolion", count: random(500, 2000) },
        { id: "f7", name: "F7", count: random(400, 1500) },
        { id: "m6", name: "M6", count: random(300, 1000) },
        { id: "h9", name: "H9", count: random(200, 500) },
      ],
    },
    {
      id: "hyundai",
      name: "Hyundai",
      model: [
        { id: "solaris", name: "Solaris", count: random(500, 2000) },
        { id: "tucson", name: "Tucson", count: random(400, 1500) },
        { id: "getz", name: "Getz", count: random(300, 1000) },
        { id: "accent", name: "Accent", count: random(200, 500) },
      ],
    },
    {
      id: "kia",
      name: "Kia",
      model: [
        { id: "ceed", name: "Ceed", count: random(500, 2000) },
        { id: "soul", name: "Soul", count: random(400, 1500) },
        { id: "rio", name: "Rio", count: random(300, 1000) },
        { id: "sportage", name: "Sportage", count: random(200, 500) },
      ],
    },
    {
      id: "mercedes",
      name: "Mercedes-Benz",
      model: [
        { id: "c", name: "C-Класс", count: random(500, 2000) },
        { id: "e", name: "E-Класс", count: random(400, 1500) },
        { id: "g", name: "G-Класс", count: random(300, 1000) },
        { id: "s", name: "S-Класс", count: random(200, 500) },
      ],
    },
  ],
};

export const getOptionsMock = async () => {
  await delay(random(1000, 5000));
  return {
    ...optionsMock,
    manufacturer: [
      ...optionsMock.manufacturer,
      ...optionsMock.manufacturer,
      ...optionsMock.manufacturer,
      ...optionsMock.manufacturer,
    ],
  };
};

export const useOptionsMock = () =>
  useQuery({
    queryKey: ["options"],
    queryFn: getOptionsMock,
  });

const categoriesMock = [
  { id: 0, name: "Авто", path: Path.CategoryCars },
  { id: 1, name: "Коммерческие", path: Path.CategoryCommercial },
  { id: 2, name: "Мото", path: Path.CategoryMoto },
  { id: 3, name: "Запчасти и аксессуары", path: Path.CategoryParts },
  { id: 4, name: "Автосервис", path: Path.CategoryService },
];

export const getCategoriesMock = async () => {
  await delay(random(1000, 3000));
  return categoriesMock;
};

export const useCategoriesMock = () =>
  useQuery({
    queryKey: ["categories"],
    queryFn: getCategoriesMock,
  });
