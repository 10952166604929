import React from "react";
import cn from "clsx";

type Props = {
  className?: string;
  text: string;
  icon: React.FC | React.FC[];
  subCategories?: { title: string }[];
  onClick?: () => void;
};

const AdTypeItem: React.FC<Props> = ({
  className,
  text,
  icon,
  subCategories,
  onClick,
}) => {
  const IconComponent = icon;

  return (
    <div
      className={cn(
        "bg-c-3 rounded-4 p-6 cursor-pointer transition",
        "flex flex-col items-center",
        "hover:bg-c-17 hover:text-c-1",
        className
      )}
      onClick={onClick}
    >
      {Array.isArray(IconComponent) ? (
        <div className="flex">
          {IconComponent.map((Icon, i) => (
            <Icon key={i} />
          ))}
        </div>
      ) : (
        <IconComponent />
      )}
      <div className="text-32 font-medium truncate">{text}</div>
    </div>
  );
};

export default AdTypeItem;
