import React from "react";
import { Link } from "react-router-dom";
import Path from "../../pages/router.const";

type Props = {
  id?: string;
  description?: string;
  onClick?: (cancel: () => void) => void;
};

const CarGridItem: React.FC<Props> = ({
  id,
  description,
  onClick,
  children,
}) => {
  return (
    <Link
      to={`${Path.CategoryCars}?manufacturer=${id}`}
      className="cursor-pointer flex"
      onClick={(e) => onClick?.(() => e.preventDefault())}
    >
      <div className="truncate">{children}</div>
      {description && <span className="ml-2 text-c-10">{description}</span>}
    </Link>
  );
};

export default CarGridItem;
