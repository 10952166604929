import React from "react";
import { generatePath } from "react-router";
import Path from "../../router.const";
import { Breadcrumbs } from "../../../components/Breadcrumbs/Breadcrumbs";

const DISPLAY_PROPERTIES = [
  "category",
  "type",
  "manufacturer",
  "model",
  "year",
  "body-type",
];

type Props = {
  properties: {
    id: string;
    header: string;
    text?: string | undefined;
    value: string | number | boolean;
  }[];
};

const AdPageBreadcrumbs: React.FC<Props> = ({ properties }) => {
  const items: { text: string; path?: string }[] = [];

  DISPLAY_PROPERTIES.forEach((property) => {
    const prop = properties.find((x) => x.id === property);
    if (!prop) {
      return null;
    }

    const params = new URLSearchParams();
    params.append(prop.id, "" + prop.value);

    const item = {
      path: generatePath(Path.CategoryCars, {}) + "?" + params.toString(),
      text: (prop.text || prop.value).toString(),
    };

    items.push(item);
  });

  const manufacturer = properties.find((x) => x.id === "manufacturer")?.text;
  const model = properties.find((x) => x.id === "model")?.text;
  const year = properties.find((x) => x.id === "year")?.value;

  const text = `${manufacturer} ${model}, ${year}`;

  items.push({ text });

  return <Breadcrumbs items={items} />;
};

export default AdPageBreadcrumbs;
