import { createTheme } from "@material-ui/core";
import { RESET_PASSWORD_STEPPER_FORM, SIGN_UP_FORM } from "../constants";

const clear = (constant: string) => {
  sessionStorage.removeItem(constant);
};

const get = (constant: string) => {
  const data = sessionStorage.getItem(constant);

  if (!data) {
    return {};
  }

  return JSON.parse(data);
};

const set = (constant: string, values: { [key: string]: string }) => {
  const data = get(constant);
  sessionStorage.setItem(constant, JSON.stringify({ ...data, ...values }));
};

export const clearResetPasswordFormData = () => {
  clear(RESET_PASSWORD_STEPPER_FORM);
};

export const getResetPasswordFormData = () => {
  return get(RESET_PASSWORD_STEPPER_FORM);
};

export const setResetPasswordFormData = (values: { [key: string]: string }) => {
  set(RESET_PASSWORD_STEPPER_FORM, values);
};

export const clearSignUpFormData = () => {
  clear(SIGN_UP_FORM);
};

export const getSignUpFormData = () => {
  return get(SIGN_UP_FORM);
};

export const setSignUpFormData = (values: { [key: string]: string }) => {
  set(SIGN_UP_FORM, values);
};

export const getMaterialUITheme = () =>
  createTheme({
    palette: {
      primary: {
        light: "#757ce8",
        main: "#3ea1f9",
        dark: "#002884",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff7961",
        main: "#f44336",
        dark: "#ba000d",
        contrastText: "#000",
      },
    },
  });
