import React, { useEffect, useState } from 'react';
import cn from "clsx";

import Button from "../../../../components/Button/Button";
import Input from "../../../../shared/ui/Input/Input";
import TextArea from "../../../../shared/ui/TextArea/TextArea";
import {
  useGetCategoriesByRoot,
  useGetNextCategoriesCache,
  usePostAdCreate,
  usePostNextCategoriesValues
} from '../../ad.hooks';
import { CategoryValue } from '../../../cms/categories/cms.categories.types';
import CategoryValueSelect from '../CategoryValueSelect';
import ColorPicker from '../../../../shared/ui/ColorPicker/ColorPicker';
import DndPhotoInput from '../DndPhotoInput';
import { convertToBase64 } from '../../../../shared/utils/files';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import GeoMap from '../../../geo/ui/GeoMap';
import { PostAdCreateData } from '../../ad.types';


interface AdFormProps {
  rootCategoryValue: CategoryValue;
}

let showLoading = true

const AdFrom: React.FC<AdFormProps> = (props) => {
  const {rootCategoryValue} = props;

  const { control, handleSubmit, setValue, watch } = useForm<PostAdCreateData>();

  const [nextValues, setNextValues] = useState<{
    category_type_id: number;
    category_value_id: number
  }[]>([])

  console.log('nextValues', nextValues)

  const values = useGetNextCategoriesCache()
  const categories = useGetCategoriesByRoot(rootCategoryValue.category_type_id)
  const next = usePostNextCategoriesValues()

  const createAd = usePostAdCreate()

  const handleFormSubmit: SubmitHandler<PostAdCreateData> = (data) => {
    const preparedCategories = nextValues.reduce((acc, next) => ({
      ...acc,
      [next.category_type_id]: next.category_value_id
    }), {})

    const preparedData = {
      ...data,
      //TODO: задать вопрос про то как формируется title?
      title: 'title',
      categories: preparedCategories,
      display: true
    }
    console.log('handleFormSubmit data', preparedData)

    createAd.mutateAsync(preparedData)
      .then(() => {
        alert('Объявление успешно создано')
      })
      .catch((err) => {
        alert(`Ошибка создания объявления \n ${err}`)
      })
  }

  useEffect(() => {
    next.mutate({root_category_value_id: rootCategoryValue.id, current_categories: nextValues})
  }, [nextValues])

  if (values.isLoading || categories.isLoading || !categories.data || !values.data) {
    return (
      <div>Loading...</div>
    )
  }

  return (
    <>
      <div className="flex gap-4">
        <h2 className="text-32 font-medium">{rootCategoryValue.name}</h2>
        <h2 className="text-c-10 text-32 font-medium">Новое объявление</h2>
      </div>
      <div>
        {values.data.slice(0, nextValues.length + 1).map((value, index) => (
          <div
            key={value.position}
            className={cn('flex gap-4 min-h-[43px] text-16', index !== 0 && 'mt-4')}
          >
            <div className='w-[106px] py-2.5'>{value.category_type.name}</div>
            <CategoryValueSelect
              search={true}
              values={value.category_values || []}
              selectedValueId={nextValues[index]?.category_value_id}
              searchHeaderText={value.category_type.name}
              onSelect={(valueId) => {
                showLoading = true
                setNextValues((prevState) => [...prevState, {
                  category_type_id: value.category_type_id,
                  category_value_id: valueId
                }]);
              }}
              onUnSelect={() => {
                showLoading = false
                setNextValues((prevState) => prevState.slice(0, index));
              }}
            />
          </div>
        ))}
        {next.isLoading && showLoading && nextValues.length != categories.data.length && (
          <div className={'text-16 py-2.5'}>Загрузка...</div>
        )}
      </div>
      {nextValues.length === categories.data.length && (
        <>
          {rootCategoryValue.options?.map((option, index) => {
            switch (option.type) {
              case ('color'):
                return (
                  <div key={option.id} className='flex gap-4 min-h-[43px] text-16 '>
                    <div className='w-[106px] py-2.5'>{option.name}</div>
                    <Controller
                      name={`options.${index}`}
                      control={control}
                      render={(props) => (
                        <ColorPicker
                          className='pt-1'
                          colors={['#21C64F', '#126E2C', '#212529', '#9c9999', '#334dff', '#fc4829', '#926547', '#36a1ff']}
                          value={props.value?.value}
                          onChange={(value) => props.onChange({
                            id: option.id,
                            value
                          })}
                        />
                      )}
                    />
                  </div>
                )
              case ('number'):
                return (
                  <div key={option.id} className='flex gap-4 min-h-[43px] text-16 '>
                    <div className='w-[106px] py-2.5'>{option.name}</div>
                    <div className='flex gap-2'>
                      <Controller
                        name={`options.${index}`}
                        control={control}
                        render={(props) => (
                          <Input
                            className='w-[120px]'
                            type='number'
                            maxLength={6}
                            {...props}
                            value={props.value?.value || ''}
                            onChange={(e) => props.onChange({
                              id: option.id,
                              value: Number(e.target.value)
                            })}
                          />
                        )}
                      />
                      <span className='py-2.5'>{option.unit}</span>
                    </div>
                  </div>
                )
              case ('text'):
              default:
                return (
                  <div key={option.id} className='flex gap-4 min-h-[43px] text-16 '>
                    <div className='w-[106px] py-2.5'>{option.name}</div>
                    <div className='flex gap-2'>
                      <Controller
                        name={`options.${index}`}
                        control={control}
                        render={(props) => (
                          <Input
                            className='w-[120px]'
                            type='text'
                            maxLength={6}
                            {...props}
                            value={props.value?.value || ''}
                            onChange={(e) => props.onChange({
                              id: option.id,
                              value: e.target.value
                            })}
                          />
                        )}
                      />
                      <span className='py-2.5'>{option.unit}</span>
                    </div>
                  </div>
                )
            }
          })}
          <div className='flex gap-4 min-h-[43px] text-16 '>
            <div className='w-[106px] py-2.5'>Цена</div>
            <div className='flex gap-2'>
              <Controller
                control={control}
                name={'price'}
                rules={{
                  valueAsNumber: true,
                }}
                render={(props) => (
                  <Input
                    type='number'
                    maxLength={10}
                    className='w-[240px]'
                    {...props}
                  />
                )}
              />
              <span className='py-2.5'>сум</span>
            </div>
          </div>
          <div className='flex flex-col gap-4'>
            <h2 className='text-32 font-medium'>Фото</h2>
            <p className='text-20 text-c-13'>
              Не показывайте на фото контакты, надписи, людей, водяные знаки и
              посторонние предметы.
            </p>
            <Controller
              name={'photos'}
              control={control}
              render={(props) => (
                <DndPhotoInput onChange={async (files) => {
                  const base64Files = await Promise.all(files.map(file => convertToBase64(file)));
                  props.onChange(base64Files.map(base64 => (base64 as string).split('base64,')[1]))
                }}/>
              )}
            />
          </div>
          <div>
            <h2 className='text-32 font-medium'>Комментарий продавца</h2>
            <p className='mt-4 text-20 text-c-13'>
              Не указывайте ссылки, цену, контактные данные и не предлагайте
              услуги, иначе объявление не пройдёт модерацию.
            </p>
            <Controller
              name={'description'}
              control={control}
              render={(props) => (
                <TextArea className="mt-6 w-full h-[240px]" maxLength={3000} {...props}/>
              )}
            />
          </div>
          <div>
            <h2 className="text-32 font-medium">Адрес осмотра</h2>
            <Controller
              name={'geo'}
              control={control}
              render={(props) => (
                <GeoMap onChange={(geo) => props.onChange(geo)}/>
              )}
            />
          </div>
          <div>
            <div className="flex gap-4">
              <Button onClick={handleSubmit(handleFormSubmit)} theme="green">Опубликовать</Button>
              <Button theme="transparent">Сохранить черновик</Button>
            </div>
            <p className="mt-4 text-20 text-c-10">
              Публикуя объявление, вы соглашаетесь с правилами auto uz
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default AdFrom;
