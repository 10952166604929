import Axios from "axios";
import store from "../redux/store";

export const instance = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

store.subscribe(() => {
  if (store.getState().auth.token === null) {
    return instance.defaults.headers.common["Authorization"];
  }
  return (instance.defaults.headers.Authorization =
    store.getState().auth.token);
});

export enum ErrorCodes {
  WrongPassword = 300005,
  NoToken = 300003,
  NoData = 100001,
  FailedConfirmPhone = 200006,
  FailedRequestConfirmCode = 200007,
  WrongProductQuantity = 250001,
}

export enum ResponseCodes {
  Success = 0,
  ChangeAdwordsQuantitySuccess = 1,
  NotFound = 2,
  StatusCodeSuccess = 200,
  StatusPostSuccess = 201,
  WrongPassword = 300005,
  NoToken = 300003,
  NoData = 100001,
  FailedConfirmPhone = 200006,
  FailedRequestConfirmCode = 200007,
  WrongProductQuantity = 250001,
}

export type Response<T extends object = {}> = T & {
  code: ResponseCodes;
};

export type TError = {
  message: string;
  inner_message: string;
};

export type ErrorResponse<T extends object = {}> = T & {
  code: ErrorCodes;
  error: TError;
  request_id: string;
};
