import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import cn from "clsx";
import closeIcon from "../../assets/images/icons/close.svg";

const MODAL_ROOT_ID = "modal-root";
const ROOT_ID = "root";

const modalRoot = document.getElementById(MODAL_ROOT_ID)!;
const rootElement = document.getElementById(ROOT_ID)!;

type Props = {
  className?: string;
  handleClose: () => void;
};

const Modal: React.FC<Props> = ({ className, handleClose, children }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    if (rootElement) {
      rootElement.classList.add("blur");
    }

    return () => {
      document.body.style.overflow = "initial";
      if (rootElement) {
        rootElement.classList.remove("blur");
      }
    };
  }, []);

  const backdrop = (
    <>
      <div
        className="fixed top-0 right-0 bottom-0 left-0 bg-c-17/75 flex items-center justify-center"
        onClick={handleClose}
      >
        <div className="flex items-start" onClick={(e) => e.stopPropagation()}>
          <div
            className={cn(
              "rounded-6 bg-c-1 max-h-[90vh] overflow-auto",
              className
            )}
          >
            {children}
          </div>
          <button className="ml-4" onClick={handleClose}>
            <img alt="" src={closeIcon} />
          </button>
        </div>
      </div>
    </>
  );

  return modalRoot ? createPortal(backdrop, modalRoot) : null;
};

export default Modal;
