import { instance, Response } from ".";
import { TCompany, TUserStatus, TUserRole } from "../types";

export type TGetPendingCompaniesBody =
  | Partial<{
      limit: number;
      offset: number;
      sort: "id" | "created_at";
      sort_type: "asc" | "desc";
    }>
  | undefined;

export type TGetPendingCompaniesResponse = Response<{
  companies: TCompany[];
}>;

export const getPendingCompanies = (
  search: TGetPendingCompaniesBody = undefined
) => {
  return instance
    .get<TGetPendingCompaniesResponse>("/cms/pendingCompanies", {
      params: search,
    })
    .then((res) => {
      return res.data;
    });
};

export type TApproveUserBody =
  | {
      id: number;
      merchant_id: string;
      acquirer_id: string;
      token: string;
    }
  | {
      id: number;
    };

export const approveUser = (body: TApproveUserBody) => {
  return instance
    .post<Response>("/cms/approveUser", body)
    .then((res) => res.data);
};

export const blockUser = (id: number) => {
  return instance
    .post<Response>("/cms/blockUser", { id })
    .then((res) => res.data);
};

export type TSort = "id" | "created_at" | "status" | "type";
export type TSortType = "asc" | "desc";

export type TSearchUserBody = Partial<{
  id: number | string;
  email: string;
  phone: string;
  status: TUserStatus;
  role: TUserRole;
  offset: number;
  limit: number;
  sort: TSort;
  sort_type: TSortType;
}>;

export type TSearchUserResponse = {
  code: number;
  users: TCompany[];
  total: number;
};

export const searchUser = (searchObj: TSearchUserBody) => {
  return instance
    .post<TSearchUserResponse>("/cms/userSearch", searchObj)
    .then((res) => res.data);
};

export const approveAd = async (id: number) => {
  const { data } = await instance.post<Response>("/cms/approveAd", { id });
  return data;
};

export const blockAd = async (id: number) => {
  const { data } = await instance.post<Response>("/cms/blockAd", { id });
  return data;
};

export const reportAd = async (id: number) => {
  const { data } = await instance.post<Response>("/cms/reportAd", { id });
  return data;
};

export const addFavoritesAd = async (id: number) => {
  const { data } = await instance.post<Response>("/cms/favorites", { id });
  return data;
};
