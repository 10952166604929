import { signInByToken } from "../auth/actionCreators";
import { getBase } from "../base/actionCreators";
import { InferActionsTypes, TBaseThunk } from "../store";
import { getDoc } from "../../api/docs";
import { TDocType } from "../../types";
import { ResponseCodes } from "../../api";

export const actions = {
  setIsInitializedSuccess: () =>
    ({
      type: "app/SET_IS_INITIALIZED_SUCCESS",
    } as const),
  setViewingDocument: (viewingDocument: string) =>
    ({
      type: "app/SET_VIEWING_DOCUMENT",
      payload: { viewingDocument },
    } as const),
};

function getExtensionFromBlobType(type: string) {
  switch (type) {
    case "image/jpeg":
      return "jpg";
    case "image/png":
      return "png";
    case "application/pdf":
      return "pdf";
    default:
      return "pdf";
  }
}

export const getDocument =
  (userId: number, docType: TDocType, docId: number): TThunk =>
  async (dispatch) => {
    const filename = `${userId}_${docType}_${docId}`;

    try {
      const res = await getDoc(filename);
      if (res) {
        if (res.status === ResponseCodes.StatusCodeSuccess) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${filename}.${getExtensionFromBlobType(res.data.type)}`
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          // @ts-expect-error
          dispatch(actions.setViewingDocument(res.data.doc));
        }
      }
    } catch (e: any) {
      console.error(e);
    }
  };

export const initializeApp = (): TThunk => async (dispatch) => {
  const promises = [];

  promises.push(dispatch(getBase()));

  const userIdStorage = localStorage.getItem("userId");
  const tokenStorage = localStorage.getItem("token");
  if (tokenStorage !== null && userIdStorage) {
    // dispatch(getCart()) works only for authorized users, so 'await' is necessary here
    const userId = +userIdStorage;
    const signInPromise = await dispatch(
      signInByToken({
        token: tokenStorage,
        user_id: userId,
      })
    );
    promises.push(signInPromise);
  }

  try {
    await Promise.all(promises);
  } catch (error) {
    console.log(error);
  }

  dispatch(actions.setIsInitializedSuccess());
};

export type ActionsTypes = InferActionsTypes<typeof actions>;
type TThunk = TBaseThunk<ActionsTypes, void>;
