import React, { useState, useRef, FC, CSSProperties } from "react";
import cn from "clsx";
import styles from "./customSelector.module.scss";

type Option = {
  id: number;
  name: string;
};

type Props = {
  name: string;
  label: string;
  register: any;
  removeLavelFromOptions?: boolean;
  dontSetSelectedItemAsTitle?: boolean;
  options: Option[];
  disableDefaultButtonStyles?: boolean;
  onChange: (name: string, el: HTMLSelectElement) => void;
  classNames?: string[];
  style?: CSSProperties;
};

export const SearchSelect: FC<Props> = ({
  name,
  label,
  register,
  options,
  dontSetSelectedItemAsTitle = false,
  removeLavelFromOptions = false,
  disableDefaultButtonStyles = false,
  onChange = () => {},
  classNames = [],
}) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(label ? "" : options[0]?.id);
  const [title, setTitle] = useState(label || options[0]?.name);
  const selectRef = useRef<HTMLSelectElement>(null);

  const selectOption = (value: number | string, title: string) => {
    setValue(value);
    if (!dontSetSelectedItemAsTitle) setTitle(title);

    for (let i = 0; i < selectRef.current!.options.length; i++) {
      const option = selectRef.current?.options[i];

      if (option) {
        if (option.value === value.toString()) {
          option.selected = true;
        } else {
          option.selected = false;
        }
      }
    }

    onChange(name, selectRef.current!);
  };

  return (
    <>
      {open && (
        <div className={styles.overlay} onClick={() => setOpen(false)} />
      )}
      <button
        type="button"
        onClick={() => setOpen(!open)}
        className={cn(
          styles.rootElement,
          {
            [styles.selectorWrapper]: !disableDefaultButtonStyles,
          },
          ...classNames
        )}
      >
        {title}
        {!disableDefaultButtonStyles && (
          <svg
            id="Capa_1"
            x="0px"
            y="0px"
            viewBox="0 0 477.175 477.175"
            style={{
              width: 8,
              height: 8,
              position: "absolute",
              right: 15,
              top: "50%",
              color: "#8c8c8c",
            }}
          >
            <g>
              <path
                d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5
		c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z
		"
              />
            </g>
          </svg>
        )}

        {open && (
          <div className={styles.list}>
            <ul>
              {!removeLavelFromOptions && (
                <li
                  className={value === 0 ? styles.selected : ""}
                  onClick={() => selectOption("", label)}
                >
                  {label}
                </li>
              )}

              {options.map(({ name, id }, index) => {
                return (
                  <li
                    key={id}
                    className={value === index + 1 ? styles.selected : ""}
                    onClick={() => selectOption(id, name)}
                  >
                    {name}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <select
          className={styles.select}
          value={value}
          name={name}
          ref={(ref) => {
            // @ts-expect-error
            selectRef.current = ref;
            register(ref);
          }}
          // can't really recall what I was doing this for
          // seems like there was an error in the console from react hook form
          // if select value is stuck while submitting, umcomment this onChange thing below
          // onChange={e => {
          //   const target = e.target
          //   const option = target.options[target.selectedIndex]
          //   const id = option.value
          //   const name = option.textContent
          //   selectOption(id, name)
          // }}
        >
          <option value={""} />
          {options.map(({ name, id }) => {
            return (
              <option key={id} value={id}>
                {name}
              </option>
            );
          })}
        </select>
      </button>
    </>
  );
};
