import { useEffect, useState } from 'react';

export const useDebounce = (value: string, delay: number) => {
  // Состояние и сеттер для отложенного значения
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Выставить debouncedValue равным value (переданное значение)
      // после заданной задержки
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // если пользователь печатает что-то внутри
      // нашего приложения в поле поиска, мы не хотим, чтобы debouncedValue не менялось до тех пор,
      // пока он не прекратит печатать дольше, чем ${value}ms
      return () => {
        clearTimeout(handler);
      };
    },
    // Вызывается снова, только если значение изменится
    // мы так же можем добавить переменную "delay" в массив зависимостей
    // если собираемся менять ее динамически.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  return debouncedValue;
};