import { TResetPasswordResponseStatus } from "./../../api/auth";
import { Dispatch } from "react";
import { TAppState } from "./../store";
import { InferActionsTypes, TBaseThunk } from "../store";
import {
  TActiveAuthModal,
  TPhoneModal,
  TResetPasswordModalChange,
} from "./reducer";
import {
  logoutUser,
  signInUser,
  signInUserByToken,
  signUpUser,
  TSignInUserBody,
  TSignInUserResponse,
  TSignInUserByTokenBody,
  TSignUpUserBody,
  confirmPhone as confirmPhoneApi,
  requestConfirmCode as requestConfirmCodeApi,
  TConfirmPhoneBody,
  TSendConfirmCodeBody,
  resetPassword as resetPasswordApi,
  resetPasswordConfirm as resetPasswordConfirmApi,
  setPassword as setPasswordApi,
  TResetPasswordConfirmBody,
  TSetPasswordBody,
  TSignInViaBabilonBody,
  signInViaBabilon as signInViaBabilonApi,
} from "../../api/auth";
import { TUser } from "../../types";
import { ErrorCodes, ErrorResponse, ResponseCodes } from "../../api";

type TSetUserDataAcPayload = Omit<TSignInUserResponse, "code" | "message">;
type TSetActiveAuthModalACPayload = TActiveAuthModal | null;

export const actions = {
  setUserDataAc: (payload: TSetUserDataAcPayload) =>
    ({ type: "auth/SET_USER", payload } as const),
  setErrorAC: (error: string | null) =>
    ({ type: "auth/SET_ERROR", error } as const),
  deleteUserAC: () => ({ type: "auth/DELETE_USER" } as const),
  setActiveAuthModalAC: (payload: TSetActiveAuthModalACPayload) =>
    ({
      type: "auth/SET_ACTIVE_AUTH_MODAL",
      payload,
    } as const),
  concatUser: (user: Partial<TUser>) =>
    ({
      type: "auth/CONCAT_USER",
      user,
    } as const),
  setPhoneModal: (phoneModal: TPhoneModal) =>
    ({
      type: "auth/SET_PHONE_MODAL",
      payload: { phoneModal },
    } as const),
  concatPhoneModal: (phoneModal: Partial<TPhoneModal>) =>
    ({
      type: "auth/CONCAT_PHONE_MODAL",
      phoneModal,
    } as const),
  setIsFetching: (isFetching: boolean) =>
    ({
      type: "auth/SET_IS_FETCHING",
      payload: { isFetching },
    } as const),
  setIsProfileCompleted: (isProfileCompleted: boolean) =>
    ({
      type: "auth/SET_IS_PROFILE_COMPLETED",
      payload: { isProfileCompleted },
    } as const),
  setResetPasswordModal: (resetPasswordModal: TPhoneModal) =>
    ({
      type: "auth/SET_RESET_PASSWORD_MODAL",
      payload: { resetPasswordModal },
    } as const),
  concatResetPasswordModal: (resetPasswordModal: Partial<TPhoneModal>) =>
    ({
      type: "auth/CONCAT_RESET_PASSWORD_MODAL",
      resetPasswordModal,
    } as const),
  resetResetPasswordModal: () =>
    ({
      type: "auth/RESET_RESET_PASSWORD_MODAL",
    } as const),
  setResetPasswordModalChange: (
    resetPasswordModalChange: TResetPasswordModalChange
  ) =>
    ({
      type: "auth/SET_RESET_PASSWORD_MODAL_CHANGE",
      payload: { resetPasswordModalChange },
    } as const),
  concatResetPasswordModalChange: (
    resetPasswordModalChange: Partial<TResetPasswordModalChange>
  ) =>
    ({
      type: "auth/CONCAT_RESET_PASSWORD_MODAL_CHANGE",
      resetPasswordModalChange,
    } as const),
  resetResetPasswordModalChange: () =>
    ({
      type: "auth/RESET_RESET_PASSWORD_MODAL_CHANGE",
    } as const),
  setResetPasswordModalChangeMessage: (
    resetPasswordModalChangeMessage: string | null
  ) =>
    ({
      type: "auth/SET_RESET_PASSWORD_MODAL_CHANGE_MESSAGE",
      payload: { resetPasswordModalChangeMessage },
    } as const),
  deleteAvatar: () =>
    ({
      type: "auth/DELETE_AVATAR",
    } as const),
  setAvatar: (avatar: string) =>
    ({
      type: "auth/SET_AVATAR",
      avatar,
    } as const),
  setIsFromBabilon: (isFromBabilon: boolean) =>
    ({
      type: "auth/SET_IS_FROM_BABILON",
      payload: { isFromBabilon },
    } as const),
};

export const signIn =
  (userData: TSignInUserBody): TBaseThunk<ActionsTypes, Promise<unknown>> =>
  (dispatch) => {
    dispatch(actions.setIsFetching(true));

    return signInUser(userData)
      .then((response) => {
        dispatch(actions.setUserDataAc(response.data));
        dispatch(
          actions.setIsProfileCompleted(response.data.completed_profile)
        );
        dispatch(actions.setIsFetching(false));
      })
      .catch((e) => {
        dispatch(actions.setErrorAC(e.response.data.error.message));
        dispatch(actions.setIsFetching(false));
      });
  };
export const signInByToken =
  (authData: TSignInUserByTokenBody): TBaseThunk<ActionsTypes, Promise<void>> =>
  (dispatch, getState) => {
    dispatch(actions.setIsFetching(true));
    return signInUserByToken(authData)
      .then((data) => {
        if (data) {
          dispatch(actions.setIsProfileCompleted(data.completed_profile));
          dispatch(
            actions.setUserDataAc({
              ...data.session,
              completed_profile: getState().auth.isProfileCompleted,
            })
          );
        }

        dispatch(actions.setIsFetching(false));
      })
      .catch((e) => {
        dispatch(actions.setErrorAC(e));
        dispatch(actions.setIsFetching(false));
      });
  };
export const signUp =
  (userData: TSignUpUserBody): Thunk =>
  (dispatch) => {
    dispatch(actions.setIsFetching(true));

    signUpUser(userData)
      .then((data) => {
        dispatch(actions.setIsProfileCompleted(data.completed_profile));
        dispatch(actions.setUserDataAc(data));
      })
      .catch((e) => dispatch(actions.setErrorAC(e.message)));

    dispatch(actions.setIsFetching(false));
  };
export const logout = (): Thunk => (dispatch) => {
  dispatch(actions.setIsFetching(true));
  logoutUser()
    .then(() => {
      dispatch(actions.deleteUserAC());
    })
    .catch((e) => dispatch(actions.setErrorAC(e)));

  dispatch(actions.setIsFetching(false));
};

function countDownTimer(
  dispatch: Dispatch<ActionsTypes>,
  getState: () => TAppState,
  method: "concatPhoneModal" | "concatResetPasswordModal"
) {
  const timer = setInterval(() => {
    const authMethod =
      method === "concatPhoneModal" ? "phoneModal" : "resetPasswordModal";
    const state = getState();
    const value =
      state.auth[authMethod].timer !== null
        ? // @ts-ignore
          state.auth[authMethod].timer - 1
        : 0;

    if (value <= 0) clearInterval(timer);

    dispatch(
      actions[method]({
        timer: value,
      })
    );
  }, 1000);
}

export const requestConfirmCode =
  (
    confirm: TSendConfirmCodeBody,
    userData: TSignUpUserBody
  ): TBaseThunk<ActionsTypes, Promise<"show" | undefined>> =>
  async (dispatch, getState) => {
    dispatch(actions.setIsFetching(true));

    try {
      const data = await requestConfirmCodeApi(confirm);
      if (data.code === ResponseCodes.Success) {
        if (data.status === "verified") {
          dispatch(signUp(userData));
        } else {
          dispatch(
            actions.setPhoneModal({
              timer: data.ttl,
              showModal: true,
              errorMessage: null,
            })
          );

          countDownTimer(dispatch, getState, "concatPhoneModal");

          dispatch(actions.setIsFetching(false));
          return "show";
        }
      } else {
        dispatch(
          actions.concatPhoneModal({
            errorMessage: data.error.message,
          })
        );
        dispatch(actions.setErrorAC(data.error.message));
      }
    } catch (e: any) {
      dispatch(
        actions.concatPhoneModal({
          errorMessage: e.response.data.error.message,
        })
      );
      dispatch(actions.setErrorAC(e.response.data.error.message));
    }

    dispatch(actions.setIsFetching(false));
    return;
  };

export const confirmPhone =
  (body: TConfirmPhoneBody, userData: TSignUpUserBody): Thunk =>
  async (dispatch) => {
    dispatch(actions.setIsFetching(true));

    try {
      const data = await confirmPhoneApi(body);

      if (data.code === ResponseCodes.Success) {
        dispatch(signUp(userData));
      } else {
        // dispatch(actions.setErrorAC(data.error.message))
        dispatch(
          actions.concatPhoneModal({
            errorMessage: data.error.message,
          })
        );
      }
    } catch (e: any) {
      dispatch(
        actions.concatPhoneModal({
          errorMessage: e.response.data.error.message,
        })
      );
    }

    dispatch(actions.setIsFetching(false));
  };

export const resetPassword =
  (
    email: string
  ): TBaseThunk<
    ActionsTypes,
    Promise<TResetPasswordResponseStatus | undefined>
  > =>
  async (dispatch, getState) => {
    dispatch(actions.concatResetPasswordModalChange({ isFetching: true }));

    try {
      const data = await resetPasswordApi(email);
      if (data.code === ResponseCodes.Success) {
        if (data.status === "codeSent") {
          dispatch(
            actions.setResetPasswordModal({
              timer: data.ttl,
              errorMessage: null,
              showModal: true,
            })
          );
          countDownTimer(dispatch, getState, "concatResetPasswordModal");
        } else if (data.status === "verified") {
          dispatch(
            actions.setResetPasswordModalChange({
              showModal: true,
              errorMessage: null,
              isFetching: false,
            })
          );
        }

        return data.status;
      } else if (data.code === ErrorCodes.FailedRequestConfirmCode) {
        dispatch(
          actions.concatResetPasswordModal({
            errorMessage: data.error.message,
          })
        );
      }
    } catch (e: any) {
      const data: ErrorResponse = e.response.data;
      dispatch(
        actions.concatResetPasswordModal({
          errorMessage: data.error.message,
        })
      );
    }

    dispatch(actions.concatResetPasswordModalChange({ isFetching: false }));
  };

export const resetPasswordConfirm =
  (body: TResetPasswordConfirmBody): Thunk =>
  async (dispatch) => {
    dispatch(actions.concatResetPasswordModalChange({ isFetching: true }));

    try {
      const data = await resetPasswordConfirmApi(body);
      if (data.code === ResponseCodes.Success) {
        dispatch(actions.resetResetPasswordModal());
        dispatch(
          actions.setResetPasswordModalChange({
            showModal: true,
            errorMessage: null,
            isFetching: false,
          })
        );
      } else {
        const errorMessage = (data as ErrorResponse).error.message;
        dispatch(actions.concatResetPasswordModalChange({ errorMessage }));
        dispatch(actions.concatResetPasswordModal({ errorMessage }));
      }
    } catch (e: any) {
      const errorMessage = (e.response.data as ErrorResponse).error.message;
      dispatch(actions.concatResetPasswordModalChange({ errorMessage }));
      dispatch(actions.concatResetPasswordModal({ errorMessage }));
    }

    dispatch(actions.concatResetPasswordModalChange({ isFetching: false }));
  };

export const setPassword =
  (body: TSetPasswordBody): Thunk =>
  async (dispatch) => {
    const handleError = (errorMessage: string) => {
      dispatch(actions.concatResetPasswordModalChange({ errorMessage }));
    };

    try {
      const data = await setPasswordApi(body);
      if (data.code === ResponseCodes.Success) {
        dispatch(
          actions.setResetPasswordModalChangeMessage(
            "Ваш пароль успешно изменён"
          )
        );
        dispatch(
          actions.concatResetPasswordModalChange({
            errorMessage: null,
            showModal: false,
          })
        );
      } else {
        handleError((data as ErrorResponse).error.message);
      }
    } catch (e: any) {
      const data: ErrorResponse = e.response.data;
      handleError(data.error.message);
    }
  };

export const signInViaBabilon =
  (body: TSignInViaBabilonBody): Thunk =>
  async (dispatch) => {
    try {
      const data = await signInViaBabilonApi(body);
      if (data.success) {
        dispatch(
          actions.setUserDataAc({
            completed_profile: data.completed_profile,
            token: data.session.token,
            user: data.session.user,
          })
        );
        dispatch(actions.setIsProfileCompleted(data.completed_profile));
      }
    } catch (e: any) {
      console.error(e);
    }
  };

export type ActionsTypes = InferActionsTypes<typeof actions>;
type Thunk = TBaseThunk<ActionsTypes, void>;
