import React from "react";
import cn from "clsx";

interface ColorPickerProps {
  colors: string[];
  value?: string;
  onChange?: (value?: string) => void;
  className?: string;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  className,
  colors,
  value,
  onChange,
}) => {
  return (
    <div className={cn("flex gap-2", className)}>
      {colors.map((color) => (
        <button
          key={color}
          style={{ backgroundColor: color }}
          className={cn("w-9 h-9 rounded-full p-1")}
          onClick={() => onChange?.(color)}
        >
          {value === color && (
            <div className="border-2 border-c-1 w-full h-full rounded-full" />
          )}
        </button>
      ))}
    </div>
  );
};

export default ColorPicker;
