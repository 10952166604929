import React from "react";
import cn from "clsx";
import Button from "../../../components/Button/Button";
import Input from "../../../shared/ui/Input/Input";

type Props = {
  className?: string;
};

const EstimateBlock: React.FC<Props> = ({ className }) => {
  return (
    <div className={cn("p-4 bg-c-2 rounded-4 text-16", className)}>
      <div className="grid grid-cols-2 gap-2 items-center">
        <span className="whitespace-nowrap">Госномер или VIN</span>
        <Input />
        <span className="whitespace-nowrap">Пробег в км</span>
        <Input />
        <span className="whitespace-nowrap">Город продажи</span>
        <Input />
      </div>
      <Button className="mt-4 w-full" theme="green">
        Оценить
      </Button>
      <Button className="mt-2 w-full" theme="gray">
        Ввести данные вручную
      </Button>
      <p className="mt-4 text-12 text-c-12 text-center">
        Оценка основана на реальных продажах авто с похожими параметрами и
        историей владения
      </p>
    </div>
  );
};

export default EstimateBlock;
