import React from "react";
import cn from "clsx";
import s from "./Socials.module.scss";
import { Link } from "react-router-dom";
import Path from "../../../pages/router.const";

export const Socials = React.memo(() => {
  return (
    <div className={s.block}>
      <div className={s.socials}>
        <div className={s.socialsInner}>
          <div className={s.socialsInnerItem}>
            <div className={cn(s.social, s.social__vkontakte)}>
              <i className="icon-vkontakte" />
            </div>
          </div>
          <div className={s.socialsInnerItem}>
            <div className={cn(s.social, s.social__odnoklassniki)}>
              <i className="icon-odnoklassniki" />
            </div>
          </div>
          <div className={s.socialsInnerItem}>
            <div className={cn(s.social, s.social__facebook)}>
              <i className="icon-facebook" />
            </div>
          </div>
          <div className={s.socialsInnerItem}>
            <div className={cn(s.social, s.social__youtube)}>
              <i className="icon-youtube-play" />
            </div>
          </div>
          <div className={s.socialsInnerItem}>
            <div className={cn(s.social, s.social__twitter)}>
              <i className="icon-twitter" />
            </div>
          </div>
          <div className={s.socialsInnerItem}>
            <div className={cn(s.social, s.social__instagram)}>
              <i className="icon-instagram" />
            </div>
          </div>
          <div className={s.socialsInnerItem}>
            <div className={cn(s.social, s.social__telegram)}>
              <i className="icon-telegram" />
            </div>
          </div>
        </div>
      </div>

      <div className={s.copyright}>
        MarketOnline -{" "}
        <a
          href="https://marketplace.mc2.software"
          target="_blank"
          rel="noopener noreferrer"
        >
          сайт объявлений
        </a>{" "}
        © ООО «MarketOnline» 2020-2023.
        <br /> <Link to={Path.Agreement}>Условия пользования</Link>.{" "}
        <Link to={Path.Policy}>Политика о данных пользователя</Link>
      </div>
    </div>
  );
});
