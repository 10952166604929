import React, { useState } from "react";
import cn from "clsx";
import Price from "../Price/Price";
import ContactButton from "../../pages/app/AdPage/ContactButton";
import { AdType } from "./ad.type";
import { Link, generatePath } from "react-router-dom";
import Path from "../../pages/router.const";
import Button from "../Button/Button";
import Tag from "../Tag/Tag";

type Props = {
  ad: AdType;
};

const AdItemColumn: React.FC<Props> = ({ ad }) => {
  const [showConfirmation, setShowConfirmation] = useState("");

  const onDelete = () => setShowConfirmation("Удалить объявление");
  const onCancel = () => setShowConfirmation("Снять с продажи объявление");

  const name = `${ad.properties.find((x) => x.id === "manufacturer")?.text} ${
    ad.properties.find((x) => x.id === "model")?.text
  }, ${ad.properties.find((x) => x.id === "year")?.value}`;

  return (
    <>
      <Link
        to={generatePath(Path.ViewAd, { id: ad.id })}
        className={cn(
          "flex items-start gap-10",
          ad.status === "sold" && "text-c-5"
        )}
      >
        <div
          className={cn(
            "grid grid-cols-2 gap-2",
            ad.status === "sold" && "opacity-25"
          )}
        >
          <img
            alt=""
            src={ad.photo[0]}
            className="col-span-2 bg-c-3 rounded-2 w-[240px] h-[160px] object-cover"
          />
          <img
            alt=""
            src={ad.photo[1]}
            className="bg-c-3 rounded-2 w-[116px] h-[80px] object-cover"
          />
          <img
            alt=""
            src={ad.photo[2]}
            className="bg-c-3 rounded-2 w-[116px] h-[80px] object-cover"
          />
        </div>
        <div className="flex-1 flex flex-col justify-between gap-10">
          <div>
            <div className="text-20 flex items-center justify-between">
              <div className="flex items-center gap-2">
                <h2 className="text-c-17">{name}</h2>
                {ad.isOwner && (
                  <Tag
                    theme={
                      ad.status === "active"
                        ? "green"
                        : ad.status === "sold"
                        ? "black"
                        : "gray"
                    }
                  >
                    {ad.status === "draft" && "Черновик"}
                    {ad.status === "active" && "Активно"}
                    {ad.status === "canceled" && "Снято с продажи"}
                    {ad.status === "sold" && "Продано"}
                  </Tag>
                )}
              </div>
              <Price value={ad.price} />
            </div>
            <div className="mt-6 flex">
              <div className="grid grid-cols-2 gap-2">
                {ad.properties.map((x, i) => (
                  <span key={i}>{x.text || x.value}</span>
                ))}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end gap-2">
            {showConfirmation && (
              <>
                <div className="text-c-17">
                  {showConfirmation} <span className="text-a">{name}</span>?
                </div>
                <Button>Удалить</Button>
                <Button theme="green" onClick={() => setShowConfirmation("")}>
                  Оставить
                </Button>
              </>
            )}
            {!showConfirmation && (
              <>
                {ad.isOwner && ad.status === "active" && (
                  <>
                    <Button onClick={onDelete}>Удалить</Button>
                    <Button onClick={onCancel}>Снять с продажи</Button>
                    <Button theme="green">Редактировать</Button>
                  </>
                )}
                {ad.isOwner && ad.status === "draft" && (
                  <>
                    <Button onClick={onDelete}>Удалить</Button>
                    <Button onClick={onCancel}>Снять с продажи</Button>
                    <Button theme="green">Продолжить заполнение</Button>
                  </>
                )}
                {ad.isOwner && ad.status === "canceled" && (
                  <>
                    <Button onClick={onDelete}>Удалить</Button>
                    <Button onClick={onCancel}>Снять с продажи</Button>
                    <Button theme="green">Выставить объявление</Button>
                  </>
                )}
                {ad.isOwner && ad.status === "sold" && (
                  <Button className="text-c-17" onClick={onDelete}>
                    Удалить
                  </Button>
                )}

                {ad.isFavorite && <Button>Удалить из избранного</Button>}

                {!ad.isOwner && <ContactButton value={ad.user.contacts} />}
              </>
            )}
          </div>
        </div>
      </Link>
      <div className="my-10 w-full h-[1px] bg-c-7" />
    </>
  );
};

export default AdItemColumn;
