import { ErrorResponse, ResponseCodes } from "../../api";
import {
  responseCart,
  responseToAddItemToCart,
  responseToDeleteItemToCart,
  TResponseCart,
  TAddItemToCartBody,
  TDeleteItemFromCartBody,
  makeOrder,
  TMakeOrderBody,
  setProductQuantity,
  TSetProductQuantityBody,
  deleteOrder as deleteOrderApi,
  qrOrder,
  TQrOrderBody,
  makeBabilonOrder as makeBabilonOrderApi,
} from "../../api/cart";
import {
  ActionsTypes as ProfileActionsTypes,
  actions as profileActions,
} from "../profile/actionCreators";
import { TOrder } from "../../types";
import { InferActionsTypes, TBaseThunk } from "../store";
import { TError, TOrderProductForm } from "./reducer";

export const actions = {
  setCart: (payload: TResponseCartNormalized) =>
    ({ type: "cart/SET_CART", payload } as const),
  setCartSuccess: (payload: boolean) =>
    ({ type: "cart/SET_ADDING_TO_CART_SUCCESS", payload } as const),
  setOrderSuccess: (orderSuccess: boolean) =>
    ({
      type: "cart/SET_ORDER_SUCCESS",
      payload: { orderSuccess },
    } as const),
  setProductQuantity: (itemId: number, quantity: number) =>
    ({
      type: "cart/SET_PRODUCT_QUANTITY",
      itemId,
      quantity,
    } as const),
  setItemError: (itemError: TError) =>
    ({
      type: "cart/SET_ITEM_ERROR",
      itemError,
    } as const),
  removeItemError: (item_id: number) =>
    ({
      type: "cart/REMOVE_ITEM_ERROR",
      item_id,
    } as const),
  resetCart: () =>
    ({
      type: "cart/RESET_CART",
    } as const),
  setQRCode: (QRCode: string | null) =>
    ({
      type: "cart/SET_QR_CODE",
      payload: { QRCode },
    } as const),
  setTransitionId: (transitionId: string | null) =>
    ({
      type: "cart/SET_TRANSITION_ID",
      payload: { transitionId },
    } as const),
  setOrderProductForm: (orderProductForm: TOrderProductForm) =>
    ({
      type: "cart/SET_ORDER_PRODUCT_FORM",
      payload: { orderProductForm },
    } as const),
  concatOrderProductForm: (orderProductForm: Partial<TOrderProductForm>) =>
    ({
      type: "cart/CONCAT_ORDER_PRODUCT_FORM",
      orderProductForm,
    } as const),
  resetOrderProductForm: () =>
    ({
      type: "cart/RESET_ORDER_PRODUCT_FORM",
    } as const),
  setOrderProductFormDataWasSaved: (orderProductFormDataWasSaved: boolean) =>
    ({
      type: "cart/SET_ORDER_PRODUCT_FORM_DATA_WAS_SAVED",
      payload: { orderProductFormDataWasSaved },
    } as const),
  resetItems: () =>
    ({
      type: "cart/RESET_ITEMS",
    } as const),
  setMakeOrderError: (makeOrderError: string | null) =>
    ({
      type: "cart/SET_MAKE_ORDER_ERROR",
      payload: { makeOrderError },
    } as const),
};

export const addItemToCart =
  (item: TAddItemToCartBody): TBaseThunk<ActionsTypes, Promise<void>> =>
  (dispatch) => {
    return responseToAddItemToCart(item).then((cart) => {
      const normalizedCart = normalizeCart(cart);
      dispatch(actions.setCart(normalizedCart));
      dispatch(actions.setCartSuccess(true));
    });
  };
export const deleteItemFromCart =
  (item: TDeleteItemFromCartBody): Thunk =>
  (dispatch) => {
    responseToDeleteItemToCart(item).then((cart) => {
      const normalizedCart = normalizeCart(cart);
      dispatch(actions.setCart(normalizedCart));
    });
  };
export const changeItemQuantityInCart =
  (item: TAddItemToCartBody): Thunk =>
  (dispatch) => {
    responseToAddItemToCart(item).then((cart) => {
      const normalizedCart = normalizeCart(cart);
      dispatch(actions.setCart(normalizedCart));
    });
  };

export const getCart = (): TBaseThunk<ActionsTypes> => (dispatch) => {
  return responseCart().then((cart) => {
    const normalizedCart = normalizeCart(cart);
    dispatch(actions.setCart(normalizedCart));
    const { city, date, email, house, phone, name, street, time } = cart;
    dispatch(
      actions.setOrderProductForm({
        city: city?.id,
        date: new Date(date),
        email,
        house,
        name,
        phone,
        street,
        time,
      })
    );
  });
};

export const orderProduct =
  (body: TMakeOrderBody): Thunk =>
  async (dispatch) => {
    try {
      const data = await makeOrder(body);
      if (data.code === ResponseCodes.Success) {
        dispatch(actions.setQRCode(data.qr_data));
        dispatch(actions.setTransitionId(data.transaction_id));
        dispatch(actions.setOrderProductFormDataWasSaved(true));
        dispatch(actions.setOrderSuccess(true));
        dispatch(actions.setMakeOrderError(null));
      }
    } catch (e: any) {
      const errorResponse: ErrorResponse = e.response.data;
      dispatch(actions.setMakeOrderError(errorResponse.error.message));
    }
  };

export const deleteOrder =
  (id: number): Thunk =>
  async (dispatch, getState) => {
    try {
      const data = await deleteOrderApi(id);
      if (data.code === ResponseCodes.Success) {
        dispatch(
          profileActions.setOrdersTab({
            count: getState().profile.ordersTab.count - 1,
            orders: getState().profile.ordersTab.orders.filter(
              (order) => order.id !== id
            ),
          })
        );
      }
    } catch (e: any) {
      console.error(e);
    }
  };

export const makeOrderImmediately =
  (order: TOrder): Thunk =>
  async (dispatch) => {
    try {
      const promises: Promise<any>[] = [];

      for (let item of order.items) {
        const promise = dispatch(
          addItemToCart({
            ...item,
            count: item.quantity,
          })
        );
        promises.push(promise);
      }

      Promise.all(promises).then(() => {
        const { date, email, house, name, phone, street, time, city } = order;
        dispatch(
          orderProduct({
            city_id: city.id,
            date,
            email,
            house,
            name,
            phone,
            street,
            time,
          })
        );
      });
    } catch (e: any) {
      console.error(e);
    }
  };

export const changeAdQuantity =
  (body: TSetProductQuantityBody): Thunk =>
  async (dispatch) => {
    function handleError(data?: ErrorResponse) {
      if (data)
        dispatch(
          actions.setItemError({
            item_id: body.item_id,
            message: data.error.message,
          })
        );
    }

    try {
      const data = await setProductQuantity(body);

      if (data.hasOwnProperty("status")) {
        const normalizedCart = normalizeCart(data);
        dispatch(actions.setProductQuantity(body.item_id, body.quantity));
        dispatch(actions.setCart(normalizedCart));
        dispatch(actions.removeItemError(body.item_id));
      } else {
        // @ts-expect-error
        const errorData = data as ErrorResponse;
        handleError(errorData);
      }
    } catch (e: any) {
      const errorData = e?.response?.data as ErrorResponse;
      handleError(errorData);
    }
  };

export const makeQrOrder =
  (body: TQrOrderBody): Thunk =>
  async (dispatch) => {
    try {
      const res = await qrOrder(body);
      if (
        res.status === ResponseCodes.StatusCodeSuccess ||
        res.status === ResponseCodes.StatusPostSuccess
      ) {
        dispatch(actions.setMakeOrderError(null));
        dispatch(actions.setQRCode(res.data.qr_data));
        dispatch(actions.setTransitionId(res.data.transaction_id));
      }
    } catch (e: any) {
      const errorResponse: ErrorResponse = e.response.data;
      dispatch(actions.setMakeOrderError(errorResponse.error.message));
      console.error(e, e.response.data);
    }
  };

export const makeBabilonOrder = (): Thunk => async (dispatch) => {
  try {
    const data = await makeBabilonOrderApi();

    if (data.code === ResponseCodes.Success) {
      dispatch(actions.setMakeOrderError(null));
      dispatch(actions.setOrderSuccess(true));
      dispatch(actions.setQRCode(data.qr_data));
      dispatch(actions.setTransitionId(data.transaction_id));
      dispatch(actions.setOrderProductFormDataWasSaved(true));
    }
  } catch (e: any) {
    const errorResponse: ErrorResponse = e.response.data;
    dispatch(actions.setMakeOrderError(errorResponse.error.message));
    console.error(e, e.response.data);
  }
};

export type ActionsTypes =
  | InferActionsTypes<typeof actions>
  | ProfileActionsTypes;
type Thunk = TBaseThunk<ActionsTypes, void>;

type TResponseCartNormalized = Omit<TResponseCart, "total_cost"> & {
  total_cost: number;
};

// TODO: убрать эту хрень, когда с бэкенда будет total_cost как число приходить
function normalizeCart(cart: TResponseCart): TResponseCartNormalized {
  const cartCopy: TResponseCartNormalized = {
    ...cart,
    total_cost: +cart.total_cost,
  };
  return cartCopy;
}
