import React from "react";
import CarFilter from "./Filter";
import CarGrid from "../../../components/CarGrid/CarGrid";
import AdList from "../../../components/AdList/AdList";
import { useCarListMock } from "../../../api/mock";
import { SuspencePreloader } from "../../../components/Preloader";

const CarFilterPage = () => {
  const { data, isLoading } = useCarListMock();

  if (isLoading) {
    return <SuspencePreloader />;
  }

  return (
    <div className="px-10 w-[1040px]">
      <h1 className="text-32 font-medium">Купить легковой автомобиль</h1>
      <p className="mt-2 text-c-12">В Ташкенте</p>
      <CarFilter className="mt-6" />
      <CarGrid className="mt-6" maxOptions={14} />
      <AdList sort type="column" className="mt-10" data={data} />
    </div>
  );
};

export default CarFilterPage;
