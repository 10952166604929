import React, { DetailedHTMLProps, forwardRef, TextareaHTMLAttributes } from 'react';
import cn from "clsx";

type TextAreaProps = DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
  const { className, ...restProps } = props;
  return (
    <div className={cn("relative text-16", className)}>
      <textarea
        className="w-full h-full bg-c-3 px-6 pt-2.5 pb-3 rounded-3 resize-none outline-none"
        ref={ref}
        {...restProps}
      />
      {props.maxLength && (
        <p className="absolute bottom-4 right-6 text-c-10 select-none">{`Осталось ${
          props.value ? props.maxLength - (props.value as string)?.length : props.maxLength
        } символов`}</p>
      )}
    </div>
  );
})

export default TextArea;
