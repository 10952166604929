import React from "react";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link to="/" className="text-a text-32 font-semibold select-none">
      autouz
    </Link>
  );
};

export default Logo;
