import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import cn from "clsx";
import { AuthoriztionModal } from "../AuthoriztionModal";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { signIn } from "../../../redux/auth/actionCreators";
import { TActiveAuthModal } from "../../../redux/auth/reducer";
import { TUserRole } from "../../../types";
import s from "../autorizationModal.module.scss";
import s2 from "./SignIn.module.scss";
import { Modal } from "../../common/Modal/Modal";
import { ResetPasswordStepper } from "../ResetPasswordStepper/ResetPasswordStepper";
import { InputWrapper } from "../../common/InputWrapper/InputWrapper";
import { resetWrappedInputStyle } from "../helpers";

type Props = {
  handleClose: () => void;
  currentType?: TActiveAuthModal;
  setCurrentType?: Dispatch<SetStateAction<TActiveAuthModal>>;
  role?: TUserRole;
};

type FormValues = {
  email: string;
  password: string;
  showPassword: boolean;
};

export const SignIn: FC<Props> = React.memo(
  ({ handleClose, setCurrentType = () => {}, role = "user", currentType }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register, handleSubmit, errors } = useForm<FormValues>();
    const [emailValue, setEmailValue] = useState("");

    const dispatch = useDispatch();
    const [showResetModal, setShowResetModal] = useState(false);

    const onSubmit = useCallback(
      async ({ email, password }: FormValues) => {
        setIsSubmitting(true);
        await dispatch(signIn({ email, password }));
        setIsSubmitting(false);
      },
      [dispatch]
    );
    const [showPassword, setShowPassword] = useState(false);

    return (
      <div>
        {!showResetModal && (
          <AuthoriztionModal
            currentType={currentType}
            setCurrentType={setCurrentType}
            isSubmitting={isSubmitting}
            role={role}
            leftTitle="Войти"
            buttonTitle="Войти"
            rightTitle="Регистрация"
            error={!!Object.keys(errors).length ? "Заполните все поля" : null}
            navList={[
              {
                title: "Компания",
                handler: () => setCurrentType("SignUpCompany"),
              },
              {
                title: "Частное лицо",
                handler: () => setCurrentType("SignUpUser"),
              },
            ]}
            handleClose={handleClose}
          >
            <form id="auth-form" onSubmit={handleSubmit(onSubmit)}>
              <div className={s.item}>
                <label>Email</label>

                <InputWrapper
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  classNames={[s.inputWrapper]}
                >
                  <div className={s2.inputWrapper}>
                    <input
                      className={s.input}
                      style={{
                        ...resetWrappedInputStyle("email", errors),
                        paddingLeft: 10,
                      }}
                      name="email"
                      type="email"
                      value={emailValue}
                      ref={register({
                        required: "Это поле обязательное",
                      })}
                      onChange={(e) => {
                        const value = e.target.value;
                        setEmailValue(value);
                      }}
                    />
                  </div>
                </InputWrapper>
              </div>
              <div className={s.item} style={{ margin: 0 }}>
                <label>Пароль</label>
                <div className={cn(s.inputWrapper, s2.inputWrapper)}>
                  <div
                    className={cn(
                      s2.inputPrefix,
                      s2.inputPrefix__right,
                      s2.inputPrefix__button
                    )}
                    onClick={() => setShowPassword((p) => !p)}
                  >
                    {showPassword && <i className="watcheye-eye-off" />}
                    {!showPassword && <i className="watcheye-eye" />}
                  </div>
                  <input
                    className={s.input}
                    style={
                      errors?.password
                        ? {
                            ...resetWrappedInputStyle("password", errors),
                            borderColor: "#f00",
                          }
                        : resetWrappedInputStyle("password", errors)
                    }
                    name="password"
                    type={showPassword ? "text" : "password"}
                    ref={register({
                      required: "Это поле обязательное",
                    })}
                  />
                </div>
              </div>
              <div>
                <button
                  className={s.btnReset}
                  type="button"
                  onClick={() => setShowResetModal(true)}
                >
                  Забыли пароль?
                </button>
              </div>
            </form>
          </AuthoriztionModal>
        )}

        {showResetModal && (
          <Modal handleClose={() => setShowResetModal(false)}>
            <ResetPasswordStepper setShowResetModal={setShowResetModal} />
          </Modal>
        )}
      </div>
    );
  }
);
