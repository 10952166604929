import { TAppState } from "../store";

export const getProductObj = (state: TAppState) => {
  return state.products.product;
};

export const getAdwordsList = (state: TAppState) => {
  return state.products.adwords;
};

export const getAdword = (id: number) => (state: TAppState) =>
  state.products.adwords.find((adword) => adword.id === id);

export const getAdwordsArchiveList = (state: TAppState) => {
  return state.products.adwordsArchive;
};

export const getProductsList = (state: TAppState) => {
  return state.products.products;
};

export const getRecords = (state: TAppState) => state.products.products.records;

export const getCreatedAdwords = (state: TAppState) =>
  state.products.products.records.filter(
    (record) => record.status === "created"
  );

export const getProductsTotalCount = (state: TAppState) => {
  return state.products.products.count;
};

export const getShowEditedModal = (state: TAppState) => {
  return state.products.showEditedModal;
};

export const getIsProductRendering = (state: TAppState) => {
  return state.products.isProductRendering;
};

export const getIsBlockAdwordSuccess = (state: TAppState) => {
  return state.products.isBlockAdwordSuccess;
};

export const getIsReportAdwordSuccess = (state: TAppState) => {
  return state.products.isReportAdwordSuccess;
};

export const getIsAddFavoritesAdwordSuccess = (state: TAppState) => {
  return state.products.isAddFavoritesAdwordSuccess;
};
