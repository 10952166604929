import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import cn from "clsx";

type Props = {
  items?: { text: string; path?: string }[];
  className?: string;
};

export const Breadcrumbs: FC<Props> = ({ items = [], className }) => {
  return (
    <div className={cn("flex gap-2 text-12 text-c-10", className)}>
      {items.map(({ text, path }, i, arr) => {
        return (
          <>
            {path ? (
              <NavLink key={i} to={path}>
                {text}
              </NavLink>
            ) : (
              <span className="text-c-17">{text}</span>
            )}
            {i !== arr.length - 1 && " → "}
          </>
        );
      })}
    </div>
  );
};
