import React from "react";
import {Modal} from "../common/Modal/Modal";
import s from "./ResultModal.module.scss";

type Props = {

    onClose: () => void;
};

const SuccessModal: React.FC<Props> = ({ onClose }) => {
    return (
        <Modal
            handleClose={onClose}
            displayCloseBtn={true}

        >
            <div className={s.closeButtonWrapper}>
                <button onClick={onClose} className={s.closeButton}>
                    &times;
                </button>
            </div>
            <div className={s.title}>Успех</div>
            <div className={s.message}>
                <p>Вы успешно зарегистрировались!</p>
                <p>Теперь вы можете войти в систему.</p>
            </div>
        </Modal>
    );
};

export default SuccessModal;
