import React from 'react';
import cn from "clsx";
import { TPhoto } from "../../types";
import GalleryItem from "./GalleryItem";

const MAX_PHOTO_AMOUNT = 9;

type Props = {
  value?: TPhoto[];
  onClick?: () => void;
  className?: string;
};

const Gallery: React.FC<Props> = ({ value, className, onClick }) => {
  return (
    <>
      <div className={cn("overflow-auto mobile:pb-4", className)}>
        <div className={cn("grid grid-cols-4 mobile:flex gap-2 mobile:px-10")}>
          {value?.slice(0, MAX_PHOTO_AMOUNT).map((photo, i) => (
            <GalleryItem
              key={i}
              url={photo.url}
              className={cn(
                i === 0
                  ? "col-span-4 row-span-4 h-[500px] mobile:h-[193px]"
                  : "w-[154px] h-[106px]",
                "mobile:w-[248px] mobile:h-[193px] object-cover"
              )}
              more={
                i === MAX_PHOTO_AMOUNT - 1 ? value.length - MAX_PHOTO_AMOUNT : 0
              }
              onClick={onClick}
            />
          ))}
        </div>
      </div>

    </>
  );
};

export default Gallery;
