import { TFetchBaseResponse } from "../../api/base"
import { ActionsType } from "./actionCreators"

const initialState = {
  cities: [] as TFetchBaseResponse["cities"],
  categories: [] as TFetchBaseResponse["categories"],
  conditions: [] as TFetchBaseResponse["conditions"],
  isLoading: true,
}

type TInitialState = typeof initialState

export const baseReducer = (
  state = initialState,
  action: ActionsType
): TInitialState => {
  switch (action.type) {
    case "base/SET_IS_LOADING":
      return {
        ...state,
        ...action.payload,
      }
    case "base/SET_BASE":
      return {
        ...state,
        ...action.payload,
      }
    default:
      return { ...state }
  }
}
