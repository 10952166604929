import { ErrorResponse, instance, Response, ResponseCodes } from "."
import { TAdword, TCity, TOrderStatus } from "../types"

export type TItem = {
  readonly id: number
  created_at: string
  updated_at: string
  ad: TAdword
  quantity: number
}

export type TResponseCart = {
  status: TOrderStatus
  total_items: number
  total_cost: string
  items: TItem[]
  city: TCity
  street: string
  house: string
  name: string
  phone: string
  email: string
  date: string
  time: string
  qr_data: string
  transaction_id: string
}

export const responseCart = async () => {
  const { data } = await instance.get<TResponseCart>("/basket/current")
  return data
}

export type TAddItemToCartBody = {
  id: number
  count: number
}

export const responseToAddItemToCart = async (item: TAddItemToCartBody) => {
  const response = await instance.put<TResponseCart>("/basket/add", {
    item_id: item.id,
    quantity: item.count || 1
  })
  const cart = response.data
  return cart
}

export type TDeleteItemFromCartBody = {
  id: number
}

export const responseToDeleteItemToCart = async (
  item: TDeleteItemFromCartBody
) => {
  const { data } = await instance.delete<TResponseCart>(
    `/basket/remove/${item.id}`
  )
  return data
}

export type TMakeOrderBody = {
  city_id: number
  street: string
  house: string
  name: string
  phone: string
  email: string
  date: string
  time: string
}

export type TMakeOrder = {
  transaction_id: string
  qr_data: string
}

type TMakeOrderResponse = {
  code: ResponseCodes.Success
} & TMakeOrder

export const makeOrder = (orderBody: TMakeOrderBody) => {
  return instance
    .put<TMakeOrderResponse | ErrorResponse>("/basket/order", orderBody)
    .then((res) => res.data)
}

export const makeBabilonOrder = () => {
  return instance
    .put<TMakeOrderResponse | ErrorResponse>("/basket/babilon/order")
    .then((r) => r.data)
}

export const deleteOrder = (id: number) => {
  return instance.delete<Response>(`/basket/order/${id}`).then((r) => r.data)
}

export type TSetProductQuantityBody = {
  item_id: number
  quantity: number
}

export type TSetProductQuantityResponse = {
  status: TOrderStatus
  total_items: number
  total_cost: string
  items: TItem[]
  city: TCity
  street: string
  house: string
  name: string
  phone: string
  email: string
  date: string
  time: string
  qr_data: string
  transaction_id: string
}

export const setProductQuantity = (body: TSetProductQuantityBody) => {
  return instance
    .put<TSetProductQuantityResponse>("/basket/update", body)
    .then((r) => r.data)
}

export type TQrOrderBody = {
  order_id: number
}

type TQrOrderResponse = {
  qr_data: string
  transaction_id: string
}

export const qrOrder = (body: TQrOrderBody) => {
  return instance.post<TQrOrderResponse>("/basket/order/qr", body)
}
