import React from "react";
import { Modal } from "../common/Modal/Modal";
import { ModerateModalContent } from "../../pages/app/Profile/Settings/ModerateModalContent/ModerateModalContent";

type Props = {
  onClose: () => void;
};

const SimpleModal: React.FC<Props> = ({ onClose, children }) => {
  if (!children) {
    return null;
  }
  return (
    <Modal
      handleClose={onClose}
      // onContinue={() => {
      //   setShowModal(false);
      //   history.push("/profile/settings");
      // }}
    >
      <ModerateModalContent>{children}</ModerateModalContent>
    </Modal>
  );
};

export default SimpleModal;
