import React from "react";
import cn from "clsx";

type Props = {
  className?: string;
  theme?: "green" | "gray" | "black";
};

const Tag: React.FC<Props> = ({ className, theme = "gray", children }) => {
  return (
    <div
      className={cn(
        "px-4 pt-1 pb-1.5 rounded-2",
        theme === "green" && "bg-d text-c-1",
        theme === "gray" && "bg-c-3",
        theme === "black" && "bg-c-13 text-c-1",
        className
      )}
    >
      {children}
    </div>
  );
};

export default Tag;
