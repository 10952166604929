import { useQuery } from '@tanstack/react-query';
import { getGeoAddress } from './geo.api';

export const useGetAddressesBySearch = (search: string) => {
  return useQuery(['geo', search], () => getGeoAddress(search, 'house'), {
    enabled: !!search,
    keepPreviousData: true,
  });
};

export const useGetAddressesByCoords = (coords: string) => {
  return useQuery(['geo', coords], () => getGeoAddress(coords, 'house'), {
    enabled: !!coords,
    keepPreviousData: true,
  });
};

