import React, { FC, useMemo, useState, useEffect } from "react";
import cn from "clsx";
import { useForm, SubmitHandler } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import s from "./ResetPasswordChangeModal.module.scss";
import { setPassword } from "../../../redux/auth/actionCreators";
import { Input } from "../../common/Input";
import { Button } from "../../common/Button/Button";
import {
  getResetPasswordModalChange,
  getResetPasswordModalChangeMessage,
} from "../../../redux/auth/selectors";
import {
  setResetPasswordFormData,
  getResetPasswordFormData,
} from "../../../utils/services";
import { InputWrapper } from "../../common/InputWrapper/InputWrapper";
import { Preloader } from "../../Preloader";

const schema = yup.object({
  password: yup.string().required("Обязательное поле"),
  confirm_password: yup.string().required("Обязательное поле"),
});

type FormValues = {
  msisdn: string;
  password: string;
  confirm_password: string;
};

export type Props = {
  setShowResetModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ResetPasswordChangeModal: FC<Props> = ({ setShowResetModal }) => {
  const dispatch = useDispatch();
  const modal = useSelector(getResetPasswordModalChange);
  const message = useSelector(getResetPasswordModalChangeMessage);
  const error = useMemo(() => modal.errorMessage, [modal.errorMessage]);
  const [initialValues] = useState(getResetPasswordFormData());
  const { register, handleSubmit, errors, watch, setError } =
    useForm<FormValues>({
      defaultValues: initialValues,
      mode: "all",
      resolver: yupResolver(schema),
    });

  const watchPasswords = watch(["password", "confirm_password"]);
  const passwordsMatch =
    watchPasswords.confirm_password === watchPasswords.password;

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    if (!passwordsMatch) {
      return setError("confirm_password", {
        type: "manual",
        message: "Пароли не совпадают",
        shouldFocus: true,
      });
    }
    setResetPasswordFormData(data);
    dispatch(
      setPassword({
        ...data,
        msisdn: getResetPasswordFormData().phone,
      })
    );
  };

  useEffect(() => {
    if (message) {
      const timeout = setTimeout(() => {
        setShowResetModal(false);
        clearTimeout(timeout);
      }, 1000);
    }
  }, [message, setShowResetModal]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={s.modal}>
      <input type="hidden" name="msisdn" ref={(ref) => register(ref)} />
      <div className={s.item}>
        <InputWrapper
          error={!!errors.password}
          helperText={errors.password?.message}
        >
          <Input
            input={{
              name: "password",
              placeholder: "Пароль",
              ref: (ref) => register(ref),
            }}
          />
        </InputWrapper>
      </div>
      <div className={s.item}>
        <InputWrapper
          error={!!errors.confirm_password}
          helperText={errors.confirm_password?.message}
        >
          <Input
            input={{
              name: "confirm_password",
              placeholder: "Подтвердите пароль",
              ref: (ref) => register(ref),
            }}
          />
        </InputWrapper>
      </div>
      {error && <div className={cn(s.item, s.error)}>{error}</div>}
      {message && <div className={cn(s.item, s.message)}>{message}</div>}
      <div className={s.item}>
        <Button
          type="submit"
          classNames={[s.btn]}
          theme={modal.isFetching ? "gray" : "default"}
          isDisabled={modal.isFetching}
        >
          Отправить
          <Preloader.Transition
            condition={modal.isFetching}
            preloaderProps={{
              classNames: [s.btnPreloader],
            }}
          />
        </Button>
      </div>
    </form>
  );
};
