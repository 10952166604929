import { TUser } from "../../types"
import { ActionsTypes } from "./actionCreators"

export type TActiveAuthModal =
  | "SignInAdmin"
  | "SignIn"
  | "SignUpCompany"
  | "SignUpUser"

export type TPhoneModal = {
  timer: number | null
  showModal: boolean
  errorMessage: string | null
}

export type TResetPasswordModalChange = {
  showModal: boolean
  errorMessage: string | null
  isFetching: boolean
}

const initialState = {
  user: null as TUser | null,
  token: null as string | null,
  error: null as string | null,
  loggedIn: false,
  activeAuthModal: null as TActiveAuthModal | null,
  phoneModal: {
    timer: null,
    showModal: false,
    errorMessage: null,
  } as TPhoneModal,
  resetPasswordModal: {
    timer: null,
    showModal: false,
    errorMessage: null,
  } as TPhoneModal,
  resetPasswordModalChange: {
    showModal: false,
    errorMessage: null,
    isFetching: false
  } as TResetPasswordModalChange,
  resetPasswordModalChangeMessage: null as string | null,
  isFetching: false,
  isProfileCompleted: false,
  isFromBabilon: false,
}

type TInintialState = typeof initialState

export function authReducer(
  state = initialState,
  action: ActionsTypes
): TInintialState {
  switch (action.type) {
    case "auth/SET_IS_FROM_BABILON":
    case "auth/SET_RESET_PASSWORD_MODAL":
    case "auth/SET_IS_PROFILE_COMPLETED":
    case "auth/SET_IS_FETCHING":
    case "auth/SET_PHONE_MODAL":
    case "auth/SET_RESET_PASSWORD_MODAL_CHANGE":
    case "auth/SET_RESET_PASSWORD_MODAL_CHANGE_MESSAGE":
      return {
        ...state,
        ...action.payload,
      }
    case "auth/SET_AVATAR":
      return {
        ...state,
        user: {
          ...state.user!,
          avatar: action.avatar,
        },
      }
    case "auth/DELETE_AVATAR":
      return {
        ...state,
        user: {
          ...state.user!,
          avatar: "",
        },
      }
    case "auth/CONCAT_RESET_PASSWORD_MODAL_CHANGE":
      return {
        ...state,
        resetPasswordModalChange: {
          ...state.resetPasswordModalChange,
          ...action.resetPasswordModalChange,
        },
      }
    case "auth/RESET_RESET_PASSWORD_MODAL_CHANGE":
      return {
        ...state,
        resetPasswordModalChange: {
          ...initialState.resetPasswordModalChange,
        },
      }
    case "auth/RESET_RESET_PASSWORD_MODAL":
      return {
        ...state,
        resetPasswordModal: {
          ...initialState.resetPasswordModal,
        },
      }
    case "auth/CONCAT_RESET_PASSWORD_MODAL":
      return {
        ...state,
        resetPasswordModal: {
          ...state.resetPasswordModal,
          ...action.resetPasswordModal,
        },
      }
    case "auth/CONCAT_PHONE_MODAL":
      return {
        ...state,
        phoneModal: {
          ...state.phoneModal,
          ...action.phoneModal,
        },
      }
    case "auth/CONCAT_USER":
      return {
        ...state,
        // @ts-expect-error
        user: {
          ...state.user,
          ...action.user,
        },
      }
    case "auth/SET_USER":
      localStorage.setItem("token", action.payload.token)
      localStorage.setItem("userId", action.payload.user.id.toString())
      return {
        ...state,
        user: { ...action.payload.user },
        token: `${action.payload.user.id}:${action.payload.token}`,
        error: null,
        loggedIn: true,
      }
    case "auth/DELETE_USER":
      localStorage.removeItem("token")
      localStorage.removeItem("userId")
      return { ...state, user: null, token: null, loggedIn: false }
    case "auth/SET_ERROR":
      return { ...state, error: action.error }
    case "auth/SET_ACTIVE_AUTH_MODAL":
      return { ...state, activeAuthModal: action.payload }
    default:
      return state
  }
}
