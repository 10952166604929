import React from "react";
import { getUser } from "../../redux/auth/selectors";
import { useSelector } from "react-redux";
import { getImageSrc } from "../../utils/helpers";

const Avatar = () => {
  const user = useSelector(getUser);

  return (
    <div className="w-10 h-10 rounded-full bg-d flex items-center justify-center text-24 text-c-1 font-medium uppercase">
      {user?.avatar ? (
        <img src={getImageSrc(user.avatar, "avatars")} alt="Аватар" />
      ) : (
        <div>{typeof user?.name === "string" ? user?.name[0] || "" : ""}</div>
      )}
    </div>
  );
};

export default Avatar;
