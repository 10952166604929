import React from "react";
import {Modal} from "../common/Modal/Modal";
import s from "./ResultModal.module.scss";

type Props = {
    error: string;
    onClose: () => void;
    onOpen: () => void;
};

const ErrorModal: React.FC<Props> = ({ error, onClose, onOpen }) => {
    return (
        <Modal
            handleClose={onClose}
        >
            <div className={s.closeButtonWrapper}>
                <button onClick={onClose} className={s.closeButton}>
                    &times;
                </button>
            </div>
            <div className={s.title}>Ошибка</div>
            <div className={s.error}>{error}</div>
        </Modal>
    );
};

export default ErrorModal;
