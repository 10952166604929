import {CSSProperties} from 'react'
import { DeepMap, FieldError } from 'react-hook-form'

export const resetWrappedInputStyle = <T extends { [key: string]: string }>(
  name: keyof T,
  errors: DeepMap<T, FieldError>
) => {
  const baseStyle: CSSProperties = {
    flex: "0 1 auto",
    margin: 0,
    width: "100%",
  }
  return errors[name] ? { ...baseStyle, borderColor: "#f00" } : baseStyle
}
