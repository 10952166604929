import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./redux/store";
import { ScrollToTop } from "./components/ScrollToTop/ScrollToTop";
import { MuiThemeProvider } from "@material-ui/core";
import { getMaterialUITheme } from "./utils/services";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const theme = getMaterialUITheme();
const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ScrollToTop />
          <App />
        </BrowserRouter>
      </QueryClientProvider>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById("root")
);
