import React from "react";
import AdItemGrid from "./AdItemGrid";
import AdItemColumn from "./AdItemColumn";
import { AdType } from "./ad.type";

type Props = {
  type: "grid" | "column";
  ad: AdType;
};

const AdItem: React.FC<Props> = ({ type, ad, children }) => {
  if (type === "grid") {
    return <AdItemGrid ad={ad} />;
  }
  if (type === "column") {
    return <AdItemColumn ad={ad}>{children}</AdItemColumn>;
  }
  return null;
};

export default AdItem;
