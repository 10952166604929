import React from "react";
import MotoFilter from "./Filter";
import CarGrid from "../../../components/CarGrid/CarGrid";
import AdList from "../../../components/AdList/AdList";
import { useCarListMock } from "../../../api/mock";
import { SuspencePreloader } from "../../../components/Preloader";
import { Breadcrumbs } from "../../../components/Breadcrumbs/Breadcrumbs";
import Path from "../../router.const";

const MotoFilterPage = () => {
  const { data, isLoading } = useCarListMock();

  if (isLoading) {
    return <SuspencePreloader />;
  }

  return (
    <div className="px-10 w-[1040px]">
      <Breadcrumbs
        items={[
          { text: "Мото", path: Path.CategoryMoto },
          { text: "Мотоциклы" },
        ]}
      />
      <h1 className="mt-1 text-32 font-medium">Мотоциклы</h1>
      <p className="mt-2">
        В Ташкенте
        <span className="ml-2 text-c-10 cursor-pointer">Изменить</span>
      </p>
      <MotoFilter className="mt-6" />
      <CarGrid className="mt-6" maxOptions={14} />
      <AdList sort type="column" className="mt-10" data={data} />
    </div>
  );
};

export default MotoFilterPage;
