import { instance } from '../../api';
import { CategoryOrder, CategoryValue } from '../cms/categories/cms.categories.types';
import { AdApiEndpoints } from './ad.constants';
import { NextCategoriesValues, PostAdCreateData, PostNextCategoriesValuesData } from './ad.types';

export const getCategoriesByRoot = (rootCategoryId: number) => {
  return instance.get<CategoryOrder[]>(`${AdApiEndpoints.AdCategoriesOrder}/${rootCategoryId}`).then(res => res.data);
};

export const getCategoryValue = (id: number) => {
  return instance.get<CategoryValue>(`${AdApiEndpoints.AdCategoryValues}/${id}`).then(res => res.data);
};

export const getRootCategoryValues = () => {
  return instance.get<CategoryValue[]>(AdApiEndpoints.AdCategoriesValuesRoot).then(res => res.data);
};

export const postNextCategoriesValues = (data: PostNextCategoriesValuesData) => {
  return instance.post<NextCategoriesValues[]>(AdApiEndpoints.AdCategoriesValuesNext, data).then(res => res.data);
};

export const postAdCreate = (data: PostAdCreateData) => {
  return instance.post(AdApiEndpoints.AdCreate, data).then(res => res.data);
};