import { ActionsTypes } from "./actionCreators"

const initialState = {
  isInitialized: false,
  viewingDocument: "",
  isMobile: null as boolean | null,
}

type TInitialState = typeof initialState

export function appReducer(
  state = initialState,
  action: ActionsTypes
): TInitialState {
  switch (action.type) {
    case "app/SET_VIEWING_DOCUMENT":
      return {
        ...state,
        ...action.payload,
      }
    case "app/SET_IS_INITIALIZED_SUCCESS":
      return {
        ...state,
        isInitialized: true,
      }
    default:
      return state
  }
}
