import React from "react";
import cn from "clsx";

type Props = {
  url: string;
  more?: number;
  className?: string;
  onClick?: () => void;
};

const GalleryItem: React.FC<Props> = ({ className, url, more, onClick }) => {
  const img = (
    <img
      alt="adphoto"
      // src={`${process.env.REACT_APP_API_URL}/photos/${url}`}
      src={url}
      className={cn("w-full rounded-2 cursor-pointer", className)}
      onClick={onClick}
    />
  );

  return more ? (
    <div className={cn("relative shrink-0", className)}>
      {img}
      <div
        className={cn(
          "absolute top-0 right-0 bottom-0 left-0",
          "rounded-2 bg-c-17/50 hover:bg-c-17/75 text-20 text-c-1",
          "flex items-center justify-center select-none cursor-pointer",
        )}
        onClick={onClick}
      >
        Ещё {more} фото
      </div>
    </div>
  ) : (
    img
  );
};

export default GalleryItem;
