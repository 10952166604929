import { instance } from "."
import { TCategory, TCity } from "../types"

type TCondition = {
  key: string
  name: string
}

export type TFetchBaseResponse = {
  cities: TCity[]
  conditions: TCondition[]
  categories: TCategory[]
}

export const fetchBase = async () => {
  const { data } = await instance.get<TFetchBaseResponse>("/base/options")
  return data
}
