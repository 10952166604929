import Path from '../../pages/router.const';
import { generatePath } from 'react-router';

type GetPagePathParams<T> = T extends Path.AdminCategoryTypeEdit
  ? { categoryTypeId: number }
  : T extends Path.AdminCategoryType
    ? { categoryTypeId: number }
    : T extends Path.AdminCategoryValue
      ? { categoryValueId: number }
      : T extends Path.AdminCategoryTypeAddValue
        ? { categoryTypeId: number }
        : T extends Path.AdminCategoryValueEdit
          ? { categoryValueId: number }
          : T extends Path.AdminCategoryValueEditOrder
            ? { categoryValueId: number }
            : T extends Path.CreateAd
              ? { categoryValueId: number }
              : undefined

export const getPagePath = <T extends Path>(path: T, params: GetPagePathParams<T>) =>
  //@ts-expect-error у generatePath замудреный тип, но по факту мы все верно прокидываем
  generatePath(path, params);
