import { instance } from ".";

export const getDoc = async (filename: string) => {
  try {
    const res = await instance.get<Blob>(`/docs/${filename}`, {
      responseType: "blob",
    });

    return res;
  } catch (e: any) {
    console.error(e);
  }
};
