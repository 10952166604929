import { ResponseCodes } from "../../api";
import * as cmsApi from "../../api/cms";
import * as adsApi from "../../api/ads";
import {
  fetchAdwords,
  fetchProduct,
  fetchProducts,
  TFetchProductsBody as TSearchObj,
  TFetchProductsResponse,
  TPostData,
} from "../../api/products";
import { TAdword } from "../../types";
import { InferActionsTypes, TBaseThunk } from "../store";

export const actions = {
  setErrorAC: (error: string | null) =>
    ({ type: "products/SET_ERROR", error } as const),
  setProductsAC: (products: TFetchProductsResponse) =>
    ({
      type: "products/SET_PRODUCTS",
      payload: { products },
    } as const),
  setProductAC: (payload: TAdword | null) =>
    ({
      type: "products/SET_PRODUCT",
      payload,
    } as const),
  setAdwordsAc: (payload: TAdword[]) =>
    ({
      type: "products/SET_ADWORDS",
      payload,
    } as const),
  setAdwordsArchiveAc: (payload: TAdword[]) =>
    ({
      type: "products/SET_ADWORDS_ARCHIVE",
      payload,
    } as const),
  addProducts: (products: TAdword[]) =>
    ({
      type: "products/ADD_PRODUCTS",
      products,
    } as const),
  setTotalCount: (count: number) =>
    ({
      type: "products/SET_TOTAL_COUNT",
      count,
    } as const),
  unpublishAdword: (id: number) =>
    ({
      type: "products/UNPUBLISH_ADWORD",
      id,
    } as const),
  editAdword: (adword: Partial<TAdword>) =>
    ({
      type: "products/EDIT_ADWORD",
      adword,
    } as const),
  approveAdword: (id: number) =>
    ({
      type: "products/APPROVE_ADWORD",
      id,
    } as const),
  blockAd: (id: number) =>
    ({
      type: "products/BLOCK_ADWORD",
      id,
    } as const),
  setShowEditedModal: (showEditedModal: boolean) =>
    ({
      type: "products/SET_SHOW_EDITED_MODAL",
      payload: { showEditedModal },
    } as const),
  setIsProductRendering: (isProductRendering: boolean) =>
    ({
      type: "products/SET_IS_PRODUCT_RENDERING",
      payload: { isProductRendering },
    } as const),
  setIsBlockAdwordSuccess: (isBlockAdwordSuccess: boolean | null) =>
    ({
      type: "products/SET_IS_BLOCK_ADWORD_SUCCESS",
      payload: { isBlockAdwordSuccess },
    } as const),
  setIsReportAdwordSuccess: (isReportAdwordSuccess: boolean | null) =>
    ({
      type: "products/SET_IS_REPORT_ADWORD_SUCCESS",
      payload: { isReportAdwordSuccess },
    } as const),
  setIsAddFavoritesAdwordSuccess: (
    isAddFavoritesAdwordSuccess: boolean | null
  ) =>
    ({
      type: "products/SET_IS_REPORT_ADWORD_SUCCESS",
      payload: { isAddFavoritesAdwordSuccess },
    } as const),
};

export const getMoreProducts =
  (searchObj: TSearchObj = {}): TThunk =>
  (dispatch) => {
    fetchProducts(searchObj)
      .then((data) => {
        dispatch(actions.addProducts(data.records));
        dispatch(actions.setTotalCount(data.count));
      })
      .catch((e) => dispatch(actions.setErrorAC("Ошибка получения продуктов")));
  };
export const getProducts =
  (searchObj: TSearchObj = {}): TBaseThunk<ActionsTypes, Promise<unknown>> =>
  (dispatch) => {
    return fetchProducts(searchObj)
      .then((data) => {
        dispatch(actions.setProductsAC(data));
        dispatch(actions.setErrorAC(null));
      })
      .catch((e) => dispatch(actions.setErrorAC("Ошибка получения продуктов")));
  };
export const getProduct =
  (id: number): TThunk =>
  (dispatch) => {
    dispatch(actions.setIsProductRendering(true));
    fetchProduct(id)
      .then((data) => {
        dispatch(actions.setProductAC(data.data));
      })
      .catch((e) => {
        dispatch(actions.setErrorAC("Ошибка получения продукта"));
        dispatch(actions.setIsProductRendering(false));
      });
  };
export const getAdwords =
  (postData: TPostData): TThunk =>
  (dispatch) => {
    fetchAdwords(postData)
      .then((data) => {
        if (postData.display) {
          dispatch(actions.setAdwordsAc(data.records));
        } else {
          dispatch(actions.setAdwordsArchiveAc(data.records));
        }
      })
      .catch((e) =>
        dispatch(actions.setErrorAC("Ошибка получения объявлений"))
      );
  };

export const unpublishAd =
  (id: number): TThunk =>
  async (dispatch) => {
    const data = await adsApi.unpublish(id);
    if (data.code === ResponseCodes.Success) {
      dispatch(actions.unpublishAdword(id));
    }
  };

export const editAd =
  (body: adsApi.TUpdateAd): TThunk =>
  async (dispatch) => {
    const data = await adsApi.updateAd(body);
    if (data.code === ResponseCodes.Success) {
      dispatch(actions.setShowEditedModal(true));
      // dispatch(actions.editAdword(body))
    }
  };

export const approveAdword =
  (id: number): TThunk =>
  async (dispatch) => {
    try {
      const data = await cmsApi.approveAd(id);
      if (
        data.code === ResponseCodes.StatusCodeSuccess ||
        data.code === ResponseCodes.Success
      ) {
        dispatch(actions.approveAdword(id));
      }
    } catch (e: any) {
      console.error(e);
    }
  };

export const reportAdword =
  (id?: number): TThunk =>
  async (dispatch) => {
    if (!id) {
      return;
    }
    try {
      const data = await cmsApi.reportAd(id);

      if (
        data.code === ResponseCodes.StatusCodeSuccess ||
        data.code === ResponseCodes.Success
      ) {
        dispatch(actions.setIsReportAdwordSuccess(true));
        // dispatch(actions.reportAd(id));
      }
    } catch (e: any) {
      dispatch(actions.setIsReportAdwordSuccess(false));
      if (e.code === "ERR_BAD_REQUEST") {
        alert("Сервер не выполнил запрос");
      }
      console.error(e);
    }
  };

export const addFavoritesAdword =
  (id?: number): TThunk =>
  async (dispatch) => {
    if (!id) {
      return;
    }
    try {
      const data = await cmsApi.addFavoritesAd(id);

      if (
        data.code === ResponseCodes.StatusCodeSuccess ||
        data.code === ResponseCodes.Success
      ) {
        dispatch(actions.setIsAddFavoritesAdwordSuccess(true));
        // dispatch(actions.addFavoritesAd(id));
      }
    } catch (e: any) {
      dispatch(actions.setIsAddFavoritesAdwordSuccess(false));
      if (e.code === "ERR_BAD_REQUEST") {
        alert("Сервер не выполнил запрос");
      }
      console.error(e);
    }
  };

export const blockAdword =
  (id: number): TThunk =>
  async (dispatch) => {
    try {
      const data = await cmsApi.blockAd(id);
      if (
        data.code === ResponseCodes.StatusCodeSuccess ||
        data.code === ResponseCodes.Success
      ) {
        dispatch(actions.setIsBlockAdwordSuccess(true));
        dispatch(actions.blockAd(id));
      }
    } catch (e: any) {
      dispatch(actions.setIsBlockAdwordSuccess(false));
      console.error(e);
    }
  };

export type ActionsTypes = InferActionsTypes<typeof actions>;
type TThunk = TBaseThunk<ActionsTypes, void>;
