import React, { useState } from "react";
import { Map, Placemark, YMaps } from "react-yandex-maps";
import cn from "clsx";

import Select from "./Select";
import Button from "../../../../components/Button/Button";
import Input from "../../../../shared/ui/Input/Input";
import TextArea from "../../../../shared/ui/TextArea/TextArea";
import { CARS } from "../data";
import ColorPicker from "../../../../shared/ui/ColorPicker/ColorPicker";

const QUESTIONS = [
  {
    text: "Марка",
    tip: "Выберите марку, чтобы продолжить",
    search: true,
    searchHeaderText: "Все марки",
    options: CARS,
  },
  {
    text: "Модель",
    tip: "Выберите модель, чтобы продолжить",
    search: true,
    searchHeaderText: "Все модели",
    options: [
      "100",
      "80",
      "A1",
      "A3",
      "A4",
      "A4 all road",
      "A5",
      "A6",
      "A6 all road",
    ],
  },
  {
    text: "Год",
    tip: "Выберите год, чтобы продолжить",
    search: false,
    options: [...new Array(30)]
      .map((x, i) => i)
      .map((x) => 2023 - x)
      .map(String),
  },
  {
    text: "Кузов",
    tip: "Выберите кузов, чтобы продолжить",
    search: false,
    options: ["Седан", "Универсал 5 дв."],
  },
  {
    text: "Поколение",
    tip: "Выберите поколение, чтобы продолжить",
    search: false,
    options: ["V (C8) Рестайлинг", "V (C8)"],
  },
  {
    text: "Двигатель",
    tip: "Выберите двигатель, чтобы продолжить",
    search: false,
    options: ["Бензин", "Дизель", "Гибрид"],
  },
  {
    text: "Привод",
    tip: "Выберите привод, чтобы продолжить",
    search: false,
    options: ["Передний", "Полный"],
  },
];

type Props = {
  adType: string;
};

const Step2: React.FC<Props> = ({ adType }) => {
  const [step, setStep] = useState(0);

  return (
    <>
      <div className="flex gap-4">
        <h2 className="text-32 font-medium">{adType}</h2>
        <h2 className="text-c-10 text-32 font-medium">Новое объявление</h2>
      </div>
      <div>
        {QUESTIONS.slice(0, step + 1).map((x, i) => (
          <div
            key={i}
            className={cn("flex gap-4 min-h-[43px] text-16", i !== 0 && "mt-4")}
          >
            <div className="w-[96px] py-2.5">{x.text}</div>
            <Select
              search={x.search}
              options={x.options}
              searchHeaderText={x.searchHeaderText}
              onSelect={(value) => setStep(i + (value ? 1 : 0))}
            />
          </div>
        ))}
        {step < QUESTIONS.length && (
          <p className="mt-10 text-16 text-c-12">{QUESTIONS[step].tip}</p>
        )}
        {step === QUESTIONS.length && (
          <>
            <div className="mt-4 flex gap-4 min-h-[43px] text-16 ">
              <div className="w-[96px] py-2.5">Цвет</div>
              <ColorPicker
                className="pt-1"
                colors={["#21C64F", "#126E2C", "#212529"]}
              />
            </div>
            <div className="mt-4 flex gap-4 min-h-[43px] text-16 ">
              <div className="w-[96px] py-2.5">Пробег</div>
              <div className="flex gap-2">
                <Input type="number" maxLength={6} className="w-[96px]" />
                <span className="py-2.5">км</span>
              </div>
            </div>
          </>
        )}
      </div>
      {step === QUESTIONS.length && (
        <>
          <div className="flex gap-4 min-h-[43px] text-16 ">
            <div className="w-[96px] py-2.5">Цена</div>
            <div className="flex gap-2">
              <Input type="number" maxLength={10} className="w-[240px]" />
              <span className="py-2.5">сум</span>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <h2 className="text-32 font-medium">Фото</h2>
            <p className="text-20 text-c-13">
              Не показывайте на фото контакты, надписи, людей, водяные знаки и
              посторонние предметы.
            </p>
            <div className="w-full h-[320px] rounded-6 border-2 border-dashed border-c-10 flex flex-col items-center justify-center gap-6">
              <h3 className="text-24 font-medium">Перетащите фото</h3>
              <p className="text-16 text-c-12">или</p>
              <Button className="text-16">Выберите файлы</Button>
            </div>
          </div>
          <div>
            <h2 className="text-32 font-medium">Комментарий продавца</h2>
            <p className="mt-4 text-20 text-c-13">
              Не указывайте ссылки, цену, контактные данные и не предлагайте
              услуги, иначе объявление не пройдёт модерацию.
            </p>
            <TextArea className="mt-6 w-full h-[240px]" maxLength={3000} />
          </div>
          <div>
            <h2 className="text-32 font-medium">Контакты</h2>
            <p className="mt-4 text-20 text-c-13">
              Мы взяли данные из настроек профиля, но для объявления их можно
              заменить.
            </p>
            <div className="mt-6 flex gap-4 min-h-[43px] text-16 ">
              <div className="w-[96px] py-2.5">Телефон</div>
              <Input type="tel" className="w-[240px]" />
            </div>
            <div className="mt-2 flex gap-4 min-h-[43px] text-16 ">
              <div className="w-[96px] py-2.5">Телеграм</div>
              <Input className="w-[240px]" />
            </div>
          </div>
          <div>
            <h2 className="text-32 font-medium">Адрес осмотра</h2>
            <div className="mt-6 flex gap-10 min-h-[43px] text-20">
              <div className="w-[200px] py-2.5">Город</div>
              <Input className="flex-1" />
            </div>
            <div className="mt-2 flex gap-10 min-h-[43px] text-20">
              <div className="w-[200px] py-2.5">Улица, метро, район</div>
              <Input className="flex-1" />
            </div>
            <div className="mt-6 rounded-4 overflow-hidden bg-c-3 w-full h-[294px]">
              <YMaps>
                <Map
                  width={"100%"}
                  height={"100%"}
                  state={{ center: [55.753215, 37.622504], zoom: 17 }}
                >
                  <Placemark geometry={[55.753215, 37.622504]} />
                </Map>
              </YMaps>
            </div>
          </div>
          <div>
            <div className="flex gap-4">
              <Button theme="green">Опубликовать</Button>
              <Button theme="transparent">Сохранить черновик</Button>
            </div>
            <p className="mt-4 text-20 text-c-10">
              Публикуя объявление, вы соглашаетесь с правилами auto uz
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default Step2;
