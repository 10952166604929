import React, {
  FC, useCallback, useEffect,
  useRef,
  useState
} from 'react';
import cn from "clsx";
import { useGetAddressesByCoords, useGetAddressesBySearch } from '../../geo.hooks';
import { useClickAway } from 'react-use';
import { useDebounce } from '../../../../shared/utils/hooks';
import { Map, Placemark, YMaps } from 'react-yandex-maps';


interface GeoMapProps {
  className?: string;
  onChange?: (value: string) => void;
}

const GeoMap: FC<GeoMapProps> = (props) => {
  const { className , onChange} = props;

  const searchRef = useRef<HTMLDivElement>(null)
  const mapRef = React.useRef(null);
  const placeMarkRef = React.useRef(null);

  const [search, setSearch] = useState('');
  const [coords, setCoords] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const debounceSearch = useDebounce(search, 300);

  const addressesBySearch = useGetAddressesBySearch(debounceSearch)
  const addressesByCoords = useGetAddressesByCoords(coords)

  useEffect(() => {
    if (addressesByCoords.data) {
      setSearch(addressesByCoords.data[0].name)
    }
  }, [addressesByCoords.data])

  useClickAway(searchRef, () => {
    setIsOpen(false)
  });

  const handleSetPlaceMarkRef = useCallback(ref => {
    if (ref) {
      placeMarkRef.current = ref;
      //@ts-ignore
      placeMarkRef.current.events.add("dragend", () => {
        //@ts-ignore
        const coords = placeMarkRef.current.geometry.getCoordinates();
        if (mapRef.current) {
          //@ts-ignore
          mapRef.current.setCenter(coords)
        }
        const preparedCoords = [...coords].reverse()

        setCoords(preparedCoords.join(', '))
        if (onChange) {
          onChange(preparedCoords.join(' '))
        }
      });
    }
  }, [])


  return (
    <div>
      <div className="mt-2 flex gap-10 min-h-[43px] text-20">
        <div className="w-[200px] py-2.5">Город, улица, метро</div>
        <div
          ref={searchRef}
          className={'relative grow'}
        >
          <input
            className={cn("text-16 bg-c-3 px-4 pt-2.5 pb-3 rounded-3 w-full", className)}
            value={search}
            onChange={e => {
              setSearch(e.target.value)
            }}
            onFocus={() => setIsOpen(true)}
          />
          {!!search && !!addressesBySearch.data?.length && isOpen && (
            <div
              className="absolute z-10 top-full w-full bg-c-4 rounded-2 overflow-auto max-h-[205px] flex flex-col select-none cursor-pointer">
              {addressesBySearch.data.map((option) => (
                <div
                  key={option.geo}
                  onClick={() => {
                    if (onChange) {
                      onChange(option.geo)
                    }
                    setSearch(option.name);
                    setIsOpen(false)

                    if (mapRef.current && placeMarkRef.current) {
                      const coords = option.geo.split(' ').map(Number).reverse()
                      //@ts-ignore
                      mapRef.current.setCenter(coords)
                      //@ts-ignore
                      placeMarkRef.current.geometry.setCoordinates(coords)
                    }
                  }}
                  className={cn(
                    "py-2 px-4 hover:bg-c-5"
                  )}
                >
                  {option.name}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="mt-6 rounded-4 overflow-hidden bg-c-3 w-full h-[294px]">
        <YMaps>
          <Map
            width={"100%"}
            height={"100%"}
            defaultState={{
              center: [55.753215, 37.622504],
              zoom: 17
            }}
            //@ts-ignore
            instanceRef={mapRef}
          >
            <Placemark
              defaultGeometry={[55.753215, 37.622504]}
              options={{
                draggable: true,
              }}
              //@ts-ignore
              instanceRef={handleSetPlaceMarkRef}
            />
          </Map>
        </YMaps>
      </div>
    </div>
  );
};

export default GeoMap;
