import React, { useEffect, useCallback } from "react";
import "./App.css";
import { Switch, useHistory, useLocation, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getUser,
  getLoggedIn,
  getActiveAuthModal,
} from "./redux/auth/selectors";
import { Auth } from "./components/autorization/Auth";
import { initializeApp } from "./redux/app/actionCreators";
import { getIsInitialized } from "./redux/app/selectors";
import { SuspencePreloader } from "./components/Preloader";
import { actions } from "./redux/auth/actionCreators";
import { getCart } from "./redux/cart/actionCreators";
import { useBabilonAuth } from "./hooks/useBabilonAuth";
import AppRouter from "./pages/app/AppRouter";
import Path from "./pages/router.const";
import { SuspenseRoute } from "./components/Router/SuspenseRoute";

const CmsApp = React.lazy(() => import("./pages/cms/CmsRouter"));

export const RedirectContext = React.createContext<(path: string) => void>(
  () => {}
);

const App = () => {
  const location = useLocation();
  const history = useHistory();
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const loggedIn = useSelector(getLoggedIn);
  const isInited = useSelector(getIsInitialized);
  const openAuthType = useSelector(getActiveAuthModal);

  useEffect(() => {
    dispatch(initializeApp());
  }, [dispatch]);

  useBabilonAuth(location.search);

  useEffect(() => {
    dispatch(actions.setActiveAuthModalAC(null));
  }, [user, dispatch]);

  useEffect(() => {
    if (loggedIn) {
      dispatch(getCart());
    }
  }, [loggedIn, dispatch]);

  const redirectWithSignIn = useCallback(
    (path: string) => {
      if (loggedIn) {
        history.push(path);
      } else {
        dispatch(actions.setActiveAuthModalAC("SignIn"));
        history.push(path);
      }
    },
    [dispatch, history, loggedIn]
  );

  return (
    <RedirectContext.Provider value={redirectWithSignIn}>
      {isInited && (
        // TODO: кажется надо грохнуть это див попозже на рефакторинге
        <div className="app">
          <Switch>
            <SuspenseRoute path={Path.Admin} component={CmsApp} />
            <SuspenseRoute path={Path.Home} component={AppRouter} />
          </Switch>
          {openAuthType && <Auth type={openAuthType} />}
        </div>
      )}
      <SuspencePreloader condition={!isInited} />
    </RedirectContext.Provider>
  );
};

export default withRouter(React.memo(App));
