import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router";
import cn from "clsx";
import { AuthoriztionModal } from "../AuthoriztionModal";
import ErrorModal from "../ErrorModal";
import SuccessModal from "../SuccesModal";
import { useForm } from "react-hook-form";
import s from "../autorizationModal.module.scss";
import s2 from "./SignUp.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { TUserRole } from "../../../types";
import { TActiveAuthModal } from "../../../redux/auth/reducer";
import { getIsFetching } from "../../../redux/auth/selectors";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputWrapper } from "../../common/InputWrapper/InputWrapper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { resetWrappedInputStyle } from "../helpers";
import { getSignUpFormData, setSignUpFormData } from "../../../utils/services";
// TODO(Smoreg): PR 1
import { signUpUser, TSignUpUserBody } from "../../../api/auth";
import { TLocationState } from "../SignUpStepper/SignUpStepper";

export type Props = {
  currentType?: TActiveAuthModal;
  setCurrentType: Dispatch<SetStateAction<TActiveAuthModal>>;
  handleClose: () => void;
  role: TUserRole;
};

export type FormValues = {
  role: TUserRole;
  name: string;
  email: string;
  msisdn: string;
  password: string;
  repeat_password: string;
};

const schema = yup.object().shape({
  name: yup.string().max(100, "Максимум 100 символов"),
  email: yup
    .string()
    .email("Некорректный адрес электронной почты")
    .required("Введите адрес электронной почты"),
  password: yup.string().required("Введите пароль"),
  repeat_password: yup
    .string()
    .required("Подтвердите пароль")
    .oneOf([yup.ref("password"), null], "Пароли не совпадают"),
});

export const SignUp: FC<Props> = React.memo(
  ({ currentType, setCurrentType, handleClose, role }) => {
    const history = useHistory<TLocationState>();
    const location = useLocation<TLocationState>();
    const dispatch = useDispatch();
    const isFetching = useSelector(getIsFetching);
    const [initialValues] = useState(getSignUpFormData());
    const { register, handleSubmit, errors, watch } = useForm<FormValues>({
      defaultValues: initialValues,
      mode: "onChange",
      resolver: yupResolver(schema),
    });

    const watchPasswords = watch(["password", "repeat_password"]);

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showAuthModal, setShowAuthModal] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");

    const handleShowSuccessModal = useCallback(() => {
      setShowSuccessModal(true);
      setShowAuthModal(false);
    }, []);

    const handleShowErrorModal = useCallback((errorMessage) => {
      setErrorMessage(errorMessage);
      setShowErrorModal(true);
      setShowAuthModal(false);
    }, []);

    const onSubmit = useCallback(
      async (data: FormValues) => {
        if (watchPasswords.password !== watchPasswords.repeat_password) return;

        setSignUpFormData(data);

        const { email, name, msisdn, password, role } = data;

        try {
          const signUpData: TSignUpUserBody = {
            role,
            name,
            msisdn,
            email,
            password,
          };
          const result = await signUpUser(signUpData);
          console.log(result);
          handleShowSuccessModal();
        } catch (error: any) {
          handleShowErrorModal(error.message);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        dispatch,
        history,
        location,
        watchPasswords.password,
        watchPasswords.repeat_password,
      ]
    );

    const [showPassword, setShowPassword] = useState(false);

    const handleRadioChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentType(event.target.value as TActiveAuthModal);
      },
      [setCurrentType]
    );

    return (
      <div>
        {showSuccessModal && <SuccessModal onClose={() => handleClose()} />}
        {showErrorModal && (
          <ErrorModal
            error={errorMessage}
            onClose={() => handleClose()}
            onOpen={() => {
              setShowErrorModal(true);
              setShowAuthModal(false);
            }}
          />
        )}
        {showAuthModal && (
          <AuthoriztionModal
            currentType={currentType}
            setCurrentType={setCurrentType}
            leftTitle="Регистрация"
            buttonTitle="Создать"
            error={!!Object.keys(errors).length ? "Заполните все поля" : null}
            rightTitle="Авторизация"
            navList={[
              {
                title: "Вход",
                handler: () => setCurrentType("SignIn"),
              },
            ]}
            isSubmitting={isFetching}
            handleClose={handleClose}
          >
            <form id="auth-form" onSubmit={handleSubmit(onSubmit)}>
              <input
                type="hidden"
                ref={register({
                  required: true,
                })}
                value={role}
                name="role"
              />
              <div className={s.radios}>
                <RadioGroup
                  aria-label="user-type"
                  value={currentType}
                  onChange={handleRadioChange}
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      marginBottom: 15,
                    }}
                  >
                    <FormControlLabel
                      value="SignUpUser"
                      control={<Radio color="primary" />}
                      label="Частное лицо"
                    />
                    <FormControlLabel
                      value="SignUpCompany"
                      control={<Radio color="primary" />}
                      label="Компания"
                    />
                  </div>
                </RadioGroup>
              </div>
              <div className={s.item}>
                <label>
                  {role === "company" ? "Компания" : "Пользователь"}
                </label>
                <InputWrapper
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  classNames={[s.inputWrapper]}
                >
                  <input
                    className={s.input}
                    style={resetWrappedInputStyle("name", errors)}
                    name="name"
                    type="text"
                    ref={register({
                      required: true,
                    })}
                  />
                </InputWrapper>
              </div>
              <div className={s.item}>
                <label>Электронная почта</label>
                <InputWrapper
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  classNames={[s.inputWrapper]}
                >
                  <input
                    className={s.input}
                    style={resetWrappedInputStyle("email", errors)}
                    name="email"
                    type="email"
                    ref={register}
                  />
                </InputWrapper>
              </div>
              <div className={s.item}>
                <label>Телефон</label>
                <InputWrapper
                  error={!!errors.msisdn}
                  helperText={errors.msisdn?.message}
                  classNames={[s.inputWrapper]}
                >
                  <input
                    className={s.input}
                    style={resetWrappedInputStyle("msisdn", errors)}
                    name="msisdn"
                    type="text"
                    ref={register}
                  />
                </InputWrapper>
              </div>
              <div className={s.item}>
                <label>Пароль</label>
                <div className={cn(s.inputWrapper, s2.inputWrapper)}>
                  <div
                    className={cn(
                      s2.inputPrefix,
                      s2.inputPrefix__right,
                      s2.inputPrefix__button
                    )}
                    onClick={() => setShowPassword((p) => !p)}
                  >
                    {showPassword && <i className="watcheye-eye-off" />}
                    {!showPassword && <i className="watcheye-eye" />}
                  </div>
                  <input
                    className={s.input}
                    name="password"
                    style={
                      errors.password
                        ? {
                            ...resetWrappedInputStyle("password", errors),
                            borderColor: "#ff0000",
                            paddingRight: 30,
                          }
                        : {
                            ...resetWrappedInputStyle("password", errors),
                            paddingRight: 30,
                          }
                    }
                    type={showPassword ? "text" : "password"}
                    ref={register}
                  />
                </div>
              </div>
              <div className={s.item}>
                <label>Подтвердите пароль</label>
                <input
                  className={s.input}
                  name="repeat_password"
                  style={
                    errors.repeat_password ? { borderColor: "#ff0000" } : {}
                  }
                  type={showPassword ? "text" : "password"}
                  ref={register}
                />
              </div>
              {errors.repeat_password && (
                <div className={cn(s.item, s.passwords_error)}>
                  {errors.repeat_password.message}
                </div>
              )}
            </form>
          </AuthoriztionModal>
        )}
      </div>
    );
  }
);
