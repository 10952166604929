import { useHistory, useParams } from 'react-router';
import React from 'react';
import Logo from '../../../../components/Logo/Logo';
import Button from '../../../../components/Button/Button';
import AdForm from '../../ui/AdForm';
import { useGetCategoryValue } from '../../ad.hooks';

const CreateAdPage = () => {
  const history = useHistory();
  const params = useParams<{categoryValueId: string}>()
  const categoryValueId = Number(params.categoryValueId)

  const categoryValue = useGetCategoryValue(categoryValueId)

  return (
    <div className="p-10">
      <header>
        <div className="flex justify-between items-center">
          <Logo />
          <h2 className="text-32 font-medium">Новое объявление</h2>
          <Button className="text-16" onClick={() => history.goBack()}>
            Закрыть
          </Button>
        </div>
        <div className="mt-2 w-full h-[1px] bg-c-7" />
      </header>
      {categoryValue.isLoading && (
        <div>Загрузка...</div>
      )}
      {!categoryValue.isLoading && categoryValue.data && (
        <div className="pt-10 flex flex-col gap-10 max-w-[636px]">
          <AdForm
            rootCategoryValue={categoryValue.data}
          />
        </div>
      )}
    </div>
  );
};

export default CreateAdPage;