import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { actions } from "../../redux/auth/actionCreators";
import { TActiveAuthModal } from "../../redux/auth/reducer";
import { SignIn } from "./SignIn/SignIn";
import { SignUpStepper } from "./SignUpStepper/SignUpStepper";
import Path from "../../pages/router.const";

type Props = {
  type: TActiveAuthModal;
};

export const Auth: FC<Props> = React.memo(({ type }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentType, setCurrentType] = useState(type);
  const handleClose = () => dispatch(actions.setActiveAuthModalAC(null));
  switch (currentType) {
    case "SignInAdmin":
      return (
        <SignIn
          role="admin"
          handleClose={() => history.push(Path.Home)}
          currentType={currentType}
          setCurrentType={setCurrentType}
        />
      );
    case "SignIn":
      return (
        <SignIn
          currentType={currentType}
          setCurrentType={setCurrentType}
          handleClose={handleClose}
        />
      );
    case "SignUpCompany":
      return (
        <SignUpStepper
          currentType={currentType}
          setCurrentType={setCurrentType}
          handleClose={handleClose}
          role="company"
        />
      );
    case "SignUpUser":
      return (
        <SignUpStepper
          currentType={currentType}
          setCurrentType={setCurrentType}
          handleClose={handleClose}
          role="user"
        />
      );
    default:
      return <SignIn handleClose={handleClose} />;
  }
});
