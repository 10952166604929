import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import cn from "clsx";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import { actions } from "../../redux/auth/actionCreators";
import { getError } from "../../redux/auth/selectors";
import styles from "./autorizationModal.module.scss";
import { TUserRole } from "../../types";
import { Modal } from "../common/Modal/Modal";
import { Preloader } from "../Preloader";
import { TActiveAuthModal } from "../../redux/auth/reducer";
import Path from "../../pages/router.const";

type TNavItem = {
  title: string;
  handler: () => void;
};

type Props = {
  currentType?: TActiveAuthModal;
  setCurrentType: Dispatch<SetStateAction<TActiveAuthModal>>;
  leftTitle: string;
  rightTitle: string;
  navList: TNavItem[];
  handleClose: () => void;
  buttonTitle: string;
  error: string | null;
  role?: TUserRole;
  isSubmitting?: boolean;
  displayCloseBtn?: boolean;
};

export const AuthoriztionModal: FC<Props> = React.memo(
  ({
    children,
    leftTitle,
    rightTitle,
    navList = [],
    handleClose,
    buttonTitle,
    error,
    role = "user",
    isSubmitting = false,
    displayCloseBtn = true,
    currentType,
    setCurrentType,
  }) => {
    const isAdmin = useMemo(() => role === "admin", [role]);
    const errorMsg = useSelector(getError);
    const dispatch = useDispatch();
    const [isAgreementChecked, setIsAgreementChecked] = useState(false);

    useEffect(() => {
      dispatch(actions.setErrorAC(null));
    }, [dispatch]);

    const handleAgreementChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        setIsAgreementChecked(e.target.checked);
      },
      []
    );

    return (
      <Modal
        handleClose={handleClose}
        isAdmin={isAdmin}
        displayCloseBtn={displayCloseBtn}
      >
        <div className={styles["modal-authorization-side--left"]}>
          <h2 className={styles["modal-authorization-side--left-title"]}>
            {leftTitle} или{" "}
            {currentType === "SignIn" ? (
              <button
                type="button"
                className={styles.btnTitle}
                onClick={() => setCurrentType("SignUpUser")}
              >
                зарегестрироваться
              </button>
            ) : (
              <button
                type="button"
                className={styles.btnTitle}
                onClick={() => setCurrentType("SignIn")}
              >
                Войти
              </button>
            )}
          </h2>
          {children}
          {!isAdmin && currentType !== "SignIn" && (
            <div className={styles.agreementBlock}>
              <Checkbox
                name="agree"
                color="primary"
                value={isAgreementChecked}
                onChange={handleAgreementChange}
              />
              <p className={styles["user-agreement"]}>
                При создании учетной записи вы принимаете условия нашего{" "}
                <a href={Path.Policy} target="_blank" rel="noreferrer">
                  Пользовательского соглашения
                </a>{" "}
                и подтверждаете, что ознакомились с{" "}
                <a href={Path.Agreement} target="_blank" rel="noreferrer">
                  Уведомлением о защите конфиденциальности пользователя.
                </a>
              </p>
            </div>
          )}
          {error && (
            <span
              style={{ color: "#ff0000", fontSize: 13, fontFamily: "Arial" }}
            >
              {error}
            </span>
          )}
          {errorMsg && (
            <span
              style={{
                color: "#ff0000",
                fontSize: 13,
                fontFamily: "Arial",
                marginTop: 10,
              }}
            >
              {errorMsg}
            </span>
          )}

          <button
            disabled={
              (currentType === "SignUpCompany" ||
                currentType === "SignUpUser") &&
              !isAgreementChecked
                ? true
                : false
            }
            form="auth-form"
            className={cn(styles.submitBtn, {
              [styles.submitBtn__submitting]: isSubmitting,
            })}
            type="submit"
          >
            {buttonTitle}
            <div className={styles.submitBtnPreloader}>
              <Preloader classNames={[styles.submitBtnPreloaderElement]} />
            </div>
          </button>
          {(currentType === "SignUpCompany" ||
            currentType === "SignUpUser") && (
            <button
              className={styles.btnHasAccount}
              type="button"
              onClick={() => setCurrentType("SignIn")}
            >
              Уже есть аккаунт
            </button>
          )}
        </div>
        {!isAdmin && (
          <div className={styles["modal-authorization-side--right"]}>
            <div
              className={styles["modal-authorization-side--right-mobile-top"]}
            >
              <button
                type="button"
                onClick={handleClose}
                className={cn(
                  styles["modal-authorization-side--right-mobile-top-btn"],
                  styles["modal-authorization-side--right-mobile-top-btn--back"]
                )}
              >
                <i className="icon-left" />
              </button>
              <button
                type="button"
                onClick={() =>
                  setCurrentType(isAdmin ? "SignInAdmin" : "SignIn")
                }
                className={cn(
                  styles["modal-authorization-side--right-mobile-top-btn"],
                  {
                    [styles[
                      "modal-authorization-side--right-mobile-top-btn--active"
                    ]]:
                      currentType === "SignIn" || currentType === "SignInAdmin",
                  }
                )}
              >
                Вход
              </button>
              <button
                type="button"
                onClick={() => setCurrentType("SignUpUser")}
                className={cn(
                  styles["modal-authorization-side--right-mobile-top-btn"],
                  {
                    [styles[
                      "modal-authorization-side--right-mobile-top-btn--active"
                    ]]:
                      currentType === "SignUpCompany" ||
                      currentType === "SignUpUser",
                  }
                )}
              >
                Регистрация
              </button>
            </div>
            {(currentType === "SignUpCompany" ||
              currentType === "SignUpUser") && (
              <div
                className={styles["modal-authorization-side--right-mobile-top"]}
              >
                <button
                  type="button"
                  onClick={() => setCurrentType("SignUpUser")}
                  className={cn(
                    styles["modal-authorization-side--right-mobile-top-btn"],
                    {
                      [styles[
                        "modal-authorization-side--right-mobile-top-btn--active"
                      ]]: currentType === "SignUpUser",
                    }
                  )}
                >
                  Пользователь
                </button>
                <button
                  style={{ marginLeft: 15 }}
                  type="button"
                  onClick={() => setCurrentType("SignUpCompany")}
                  className={cn(
                    styles["modal-authorization-side--right-mobile-top-btn"],
                    {
                      [styles[
                        "modal-authorization-side--right-mobile-top-btn--active"
                      ]]: currentType === "SignUpCompany",
                    }
                  )}
                >
                  Компания
                </button>
              </div>
            )}

            <h2 className={styles["modal-authorization-side--right-title"]}>
              {rightTitle}
            </h2>
            <ul className={styles["modal-authorization-side--right-buttons"]}>
              {navList.map(({ title, handler }) => (
                <li key={title}>
                  <button type="button" onClick={handler}>
                    {title}
                  </button>
                </li>
              ))}
            </ul>
            {currentType === "SignUpCompany" && (
              <div className={styles.subtitle}>
                или{" "}
                <span onClick={() => setCurrentType("SignUpUser")}>
                  Создать учётную запись частного лица
                </span>
              </div>
            )}
            {currentType === "SignUpUser" && (
              <div className={styles.subtitle}>
                или{" "}
                <span onClick={() => setCurrentType("SignUpCompany")}>
                  Создать учётную запись компании
                </span>
              </div>
            )}
          </div>
        )}
      </Modal>
    );
  }
);
