import React from "react";
import cn from "clsx";

import AdTypeItem from "./AdTypeItem";
import { CATEGORIES } from "../data";

type Props = {
  onSelect: (value: string) => void;
};

const Step1: React.FC<Props> = ({ onSelect }) => {
  return (
    <div className="mt-10 grid grid-cols-6 gap-4">
      {CATEGORIES.map((x) => (
        <AdTypeItem
          key={x.title}
          className={cn(
            x.colSpan === 3 && "col-span-3",
            x.colSpan === 2 && "col-span-2"
          )}
          text={x.title}
          icon={x.icon}
          subCategories={x.subCategories}
          onClick={() => onSelect(x.header)}
        />
      ))}
    </div>
  );
};

export default Step1;
