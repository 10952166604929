import React, { useEffect, useState } from 'react';
import cn from "clsx";
import { createPortal } from 'react-dom';
import closeIcon from '../../../assets/images/icons/close.svg';
import { twMerge } from 'tailwind-merge';
import { MoveUp, MoveDown } from 'lucide-react';

type GalleryModalProps = {
  onClose: () => void;
  photosUrls: Array<string>;
  info?: React.ReactNode;
  className?: string;
};

const IMAGE_HEIGHT = 140
const IMAGES_GAP = 8

const modalRoot = document.getElementById('modal-root')!;

const GalleryModal: React.FC<GalleryModalProps> = (props) => {
  const { className, onClose, photosUrls, info } = props;
  const navigationRef = React.useRef<HTMLDivElement>(null);

  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'initial';
    };
  }, []);

  useEffect(() => {
    switch (currentImageIndex) {
      case 0:
        return topScrollBar();

      case photosUrls.length - 1:
        return bottomScrollBar();

      default:
        return centerScrollBar()
    }
  }, [currentImageIndex]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress, true);

    return function () {
      document.removeEventListener('keydown', handleKeyPress, true);
    };
  }, []);

  const topScrollBar = () => {
    if (navigationRef.current) {
      navigationRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  const bottomScrollBar = () => {
    if (navigationRef.current) {
      navigationRef.current.scrollTo({
        top: navigationRef.current.scrollHeight,
        behavior: 'smooth',
      })
    }
  }

  const centerScrollBar = () => {
    if (navigationRef.current) {
      // находим какая по счету центральная картинка исходя из высоты 1 картинки и высоты видимой части всего столбца картинок
      const centerImageCount = Math.floor(Math.floor(navigationRef.current.offsetHeight / (IMAGE_HEIGHT + IMAGES_GAP) / 2)) + 1

      if (currentImageIndex >= centerImageCount - 1) {
        // если есть возможность прокрутить, то прокручиваем до центральной картинки
        navigationRef.current.scrollTo({
          top: (IMAGE_HEIGHT + IMAGES_GAP) * (currentImageIndex - centerImageCount + 1),
          behavior: 'smooth',
        })
      }
    }
  }

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault()
      handleDownClick()
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault()
      handleUpClick()
    }

    if (e.key === 'Escape') {
      return onClose()
    }
  }

  const handleImageClick = (imageIndex: number) => {
    setCurrentImageIndex(imageIndex);
  }

  const handleUpClick = () => {
    setCurrentImageIndex((prevIndex) => prevIndex === 0 ? photosUrls.length - 1 : prevIndex - 1 )
  }

  const handleDownClick = () => {
    setCurrentImageIndex(prevIndex => prevIndex === photosUrls.length - 1 ? 0 : prevIndex + 1)
  }


  const gallery = (
    <div className={cn('fixed top-0 right-0 bottom-0 left-0 bg-c-17/90 text-c-1 p-[32px]', className)}>
      <div className='h-full grid grid-cols-[200px_1fr_250px] gap-[24px]'>
        <div className='h-full overflow-hidden flex flex-col gap-[8px]'>
          <button
            tabIndex={0}
            onKeyDown={() => console.log('sdfff')}
            onClick={handleUpClick}
            className='h-[40px] flex justify-center items-center bg-c-17/40 rounded-3 hover:bg-c-17/60'
          >
            <MoveUp className={'w-[14px] h-[20px]'} />
          </button>
          <div ref={navigationRef} className={`h-[calc(100%-40px)] overflow-auto scrollbar-hide flex flex-col gap-[${IMAGES_GAP}px]`}>
            {photosUrls.map((image, index) => (
              <div key={index} onClick={() => handleImageClick(index)}>
                <img className={twMerge(cn(`w-[200px] h-[${IMAGE_HEIGHT}px] object-cover rounded-2 opacity-70 transition-opacity hover:opacity-100 cursor-pointer`, {
                  'border-2 opacity-100': index === currentImageIndex,
                }))} src={image} alt=''/>
              </div>
            ))}
          </div>
          <button
            onClick={handleDownClick}
            className='h-[40px] flex justify-center items-center bg-c-17 rounded-3 hover:bg-c-17/60'
          >
            <MoveDown className={'w-[14px] h-[20px]'} />
          </button>
        </div>
        <div>
          <img className='w-full max-h-[calc(100vh-64px)] object-cover rounded-2' src={photosUrls[currentImageIndex]} alt=''/>
        </div>
        <div className='pt-[50px]'>
          {info}
        </div>
      </div>
      <button className='absolute top-[24px] right-[24px]' onClick={onClose}>
        <img alt='' src={closeIcon}/>
      </button>
    </div>
  )

  return modalRoot ? createPortal(gallery, modalRoot) : null;
};

export default GalleryModal;
