import React, { useState } from "react";
import Input from "../../../../shared/ui/Input/Input";
import Button from "../../../../components/Button/Button";
import Modal from "../../../../components/Modal/Modal";

const MAX_OPTIONS_LENGTH = 5;

type Props = {
  search?: boolean;
  searchHeaderText?: string;
  options: string[];
  onSelect: (value?: string) => void;
};

const Select: React.FC<Props> = ({
  search,
  searchHeaderText,
  options,
  onSelect,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [value, setValue] = useState(-1);
  const [modalVisible, setModalVisible] = useState(false);

  const filteredOptions = options
    .map((option, id) => ({ id, option }))
    .filter((x) => x.option.toLowerCase().includes(searchValue.toLowerCase()));

  return (
    <>
      {value >= 0 && (
        <Button
          onClick={() => {
            setValue(-1);
            onSelect();
            setSearchValue("");
          }}
        >
          {options[value]}
        </Button>
      )}
      {value < 0 && (
        <div className="max-w-[500px]">
          {search && (
            <Input
              className="mb-2 w-full"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          )}
          <div className="flex gap-2 flex-wrap">
            {filteredOptions.slice(0, MAX_OPTIONS_LENGTH).map((o) => (
              <Button
                key={o.id}
                compact={options.length > 5}
                onClick={() => {
                  setValue(o.id);
                  onSelect(o.option);
                }}
              >
                {o.option}
              </Button>
            ))}
            {filteredOptions.length > MAX_OPTIONS_LENGTH && (
              <Button
                compact={options.length > 5}
                theme="transparent"
                className="text-c-10"
                onClick={() => setModalVisible(true)}
              >
                Еще {filteredOptions.length - MAX_OPTIONS_LENGTH}
              </Button>
            )}
          </div>
        </div>
      )}
      {modalVisible && (
        <Modal
          className="p-10 w-[640px]"
          handleClose={() => setModalVisible(false)}
        >
          <h3 className="text-24 font-medium">{searchHeaderText}</h3>
          <div className="mt-6 flex gap-4 items-center">
            <span>Поиск</span>
            <Input
              className="w-full"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          <div className="mt-10 grid grid-cols-4 gap-5">
            {filteredOptions.map((o) => (
              <button
                className="text-16 text-left hover:text-c-10"
                onClick={() => {
                  setValue(o.id);
                  onSelect(o.option);
                  setModalVisible(false);
                }}
              >
                {o.option}
              </button>
            ))}
          </div>
        </Modal>
      )}
    </>
  );
};

export default Select;
