import React, {
  ChangeEvent,
  CSSProperties,
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import cn from "clsx";
import { SearchSelect } from "./customSelector";
import styles from "./select.module.scss";

export type Option = {
  readonly id: string | number;
  name: string;
  isSeperator?: boolean;
};

type Props = {
  style?: CSSProperties;
  name: string;
  options: Option[];
  register?: Function;
  label?: string;
  defaultValue?: Option;
  labelClassName?: string;
  classNames?: string[];
  disableDefaultButtonStyles?: boolean;
  fixedLabel?: boolean;
  withSearch?: boolean;

  onChange?: (target: EventTarget & HTMLSelectElement, option: Option) => void;
  getSelectRef?: (
    selectRef: React.MutableRefObject<HTMLSelectElement | null>,
    reset: () => void
  ) => void;
  onSearch?: (target: string) => void;
};

export const Select: FC<Props> = React.memo(
  ({
    style = {},
    name,
    options = [],
    register,
    label,
    onChange = () => {},
    defaultValue,
    disableDefaultButtonStyles = false,
    labelClassName,
    classNames = [],
    fixedLabel = false,
    withSearch = false,
    getSelectRef,
    onSearch,
  }) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const selectRef = useRef<HTMLSelectElement | null>(null);

    const DEFAULT_SETTINGS = useMemo(
      () =>
        ({
          open: false,
          value: label ? "" : options[0]?.id.toString(),
          title: label || options[0]?.name,
          items: options,
        } as const),
      [label, options]
    );
    const [open, setOpen] = useState(DEFAULT_SETTINGS.open as boolean);
    const [value, setValue] = useState<string>(DEFAULT_SETTINGS.value);
    const [title, setTitle] = useState(DEFAULT_SETTINGS.title);
    const [items, setItems] = useState(DEFAULT_SETTINGS.items);

    useEffect(() => {
      if (onSearch) {
        setItems(options);
      }
    }, [onSearch, options]);

    const toggleDropdown = () => {
      setOpen((p) => !p);
    };

    const closeDropdown = () => {
      setOpen(false);
      setItems(options);
    };

    const selectItem = (title: string, value: string, option: Option) => {
      setValue(value);
      setTitle(title);
      if (selectRef.current) {
        onChange(selectRef.current, option);
      }
    };

    const searchItems = (e: ChangeEvent<HTMLInputElement>) => {
      if (onSearch) {
        onSearch(e.target.value);
        return;
      }
      setItems(
        options.filter((option) =>
          option.name.toLowerCase().includes(e.target.value.toLocaleLowerCase())
        )
      );
    };

    useEffect(() => {
      if (defaultValue) {
        setValue(defaultValue?.id.toString());
        setTitle(defaultValue?.name);
      }
    }, [defaultValue]);

    useEffect(() => {
      if (getSelectRef)
        getSelectRef(selectRef, () => {
          setItems(DEFAULT_SETTINGS.items);
          setOpen(DEFAULT_SETTINGS.open);
          setValue(DEFAULT_SETTINGS.value);
          setTitle(DEFAULT_SETTINGS.title);
        });
    }, [
      DEFAULT_SETTINGS.items,
      DEFAULT_SETTINGS.open,
      DEFAULT_SETTINGS.title,
      DEFAULT_SETTINGS.value,
      getSelectRef,
      selectRef,
    ]);

    return (
      <>
        <div
          style={style}
          onClick={toggleDropdown}
          className={cn(
            styles.rootElement,
            {
              [styles.container]: !disableDefaultButtonStyles,
              [styles.container__reset]: open && withSearch,
            },
            ...classNames
          )}
        >
          {(!open || !withSearch) && (
            <span className={labelClassName || styles.label}>
              {fixedLabel ? label : title}
            </span>
          )}
          {withSearch && open && (
            <input
              ref={inputRef}
              placeholder={fixedLabel ? label : title}
              className={styles.input}
              onClick={(e) => e.stopPropagation()}
              onChange={searchItems}
            />
          )}
          {open && (
            <div className={styles.dropdown}>
              <ul>
                {items.map(({ name, id, isSeperator }) => {
                  if (isSeperator) {
                    return (
                      <li key={id} className={styles.seperator}>
                        {name}
                      </li>
                    );
                  }

                  return (
                    <li
                      key={id}
                      onClick={() =>
                        selectItem(name, id.toString(), {
                          id,
                          name,
                          isSeperator,
                        })
                      }
                      data-value={id}
                    >
                      {name}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <select
            value={value}
            className={styles.select}
            name={name}
            ref={(ref) => {
              if (register) register(ref);
              selectRef.current = ref;
            }}
          >
            <option value={""} />
            {options.map(({ name, id }) => {
              return (
                <option key={id} value={id}>
                  {name}
                </option>
              );
            })}
          </select>
        </div>
        {open && <div onClick={closeDropdown} className={styles.overlay}></div>}
      </>
    );
  }
);

// @ts-expect-error
Select.Search = SearchSelect;
