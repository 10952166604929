import React, {
  FC,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import cn from "clsx";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import AvatarEditor from "react-avatar-editor";
import { Select } from "../../../components/common/Select";
import { getUser } from "../../../redux/auth/selectors";
import {
  getCities,
  getCategories,
  getConditions,
  getAddressSearchResult,
} from "../../../redux/base/selectors";
import styles from "./EditAd.module.scss";
import styles2 from "../CreateAd/createAd.module.scss";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { Preloader } from "../../../components/Preloader";
import { TAdCondition, TAdword } from "../../../types";
import {
  getAdword,
  getShowEditedModal,
} from "../../../redux/products/selectors";
import {
  getImageSrc,
  normalizePhoneNumber,
  formatPriceString,
} from "../../../utils/helpers";
import { useGetAdwords } from "../../../hooks/useGetAdwords";
import { editAd, actions } from "../../../redux/products/actionCreators";
import { deletePhoto, searchAddress } from "../../../redux/ads/actionCreators";
import { Modal } from "../../../components/common/Modal/Modal";
import { CreateAdModalSuccess } from "../CreateAd/CreateAdModalSuccess/CreateAdModalSuccess";
import { Warning } from "./Warning/Warning";
import { ModerateModalContent } from "../Profile/Settings/ModerateModalContent/ModerateModalContent";
import { Map, Placemark, YMaps } from "react-yandex-maps";
import debounce from "lodash.debounce";
import Path from "../../router.const";

export type FormValues = {
  category_id: number;
  city_id: number;
  title: string;
  description: string;
  phone: string;
  price: number;
  quantity: number;
  condition: TAdCondition;
  geo: string;
};

type Params = {
  id: string;
};

type Image = {
  ref: RefObject<AvatarEditor>;
  data_url: string;
  rotate: number;
};

export const EditAd: FC = React.memo(() => {
  const history = useHistory();
  const match = useRouteMatch<Params>();
  const currentAdword = useSelector(getAdword(+match.params.id));
  const displayModal = useSelector(getShowEditedModal);

  const adwordPhotosConverted: Image[] = useMemo(
    () =>
      currentAdword?.photos.map((photo) => {
        return {
          ...photo,
          ref: React.createRef(),
          rotate: -90,
          data_url: getImageSrc(photo.url),
        };
      }) || [],
    [currentAdword]
  );
  const defaultValues: FormValues = useMemo(
    () => ({
      category_id: currentAdword?.category_id || 0,
      city_id: currentAdword?.city_id || 0,
      condition: currentAdword?.condition || "new",
      description: currentAdword?.description || "",
      phone: currentAdword?.phone || "",
      price: currentAdword?.price || 0,
      quantity: currentAdword?.quantity || 0,
      title: currentAdword?.title || "",
      geo: currentAdword?.geo || "",
    }),
    [currentAdword]
  );
  const { register, errors, handleSubmit, reset } = useForm<FormValues>({
    defaultValues,
  });
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [images, setImages] = useState<Image[]>(adwordPhotosConverted || []);
  const [showUserBlockedModal, setShowUserBlockedModal] = useState(false);
  const user = useSelector(getUser);
  const cities = useSelector(getCities);
  const citiesOptions = useMemo(
    () =>
      cities.map((city) => ({
        id: city.id.toString(),
        name: city?.name,
      })),
    [cities]
  );
  const categories = useSelector(getCategories);
  const categoriesOptions = useMemo(
    () =>
      categories.map((cat) => ({
        id: cat.id.toString(),
        name: cat?.name,
      })),
    [categories]
  );
  const conditions = useSelector(getConditions);
  const maxNumber = useMemo(() => 69, []);
  const isMobile = useMediaQuery({ maxWidth: 900 });

  // GEO
  const [geo, setGeo] = useState<number[] | null>(null);

  const searchResults = useSelector(getAddressSearchResult);
  const searchResultOptions = useMemo(
    () =>
      searchResults.map((search) => ({ id: search.geo, name: search.name })),
    [searchResults]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onAddressSearch = useCallback(
    debounce((address) => dispatch(searchAddress(address)), 300),
    []
  );

  useGetAdwords();

  useEffect(() => {
    if (user?.status === "blocked") {
      setShowUserBlockedModal(true);
    } else {
      setShowUserBlockedModal(false);
    }
  }, [user]);

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  useEffect(() => {
    setImages(adwordPhotosConverted);
  }, [adwordPhotosConverted]);

  const onChange = useCallback((imageList: any, addUpdateIndex: number) => {
    // data for submit
    setImages(
      imageList.map((img: any) => ({ ...img, ref: React.createRef() }))
    );
  }, []);

  const onImageRotate = useCallback(
    (index: number) => {
      setImages(
        images.map((img, i) => {
          return index === i
            ? { ...img, rotate: img.rotate ? img.rotate - 90 : -90 }
            : img;
        })
      );
    },
    [images]
  );

  const onSubmit = useCallback(
    async (data: TAdword) => {
      setIsSubmitting(true);
      const request = {
        ...data,
        id: +match.params.id,
        city: data.city_id,
        price: data.price.toString().split(" ").join(""),
        photos: images
          .filter((img) => img.data_url.includes("base64"))
          .map((img) => img.data_url.split("base64,")[1]),
        display: true,
      };
      await dispatch(editAd(request));
      setIsSubmitting(false);
    },
    [dispatch, images, match.params.id]
  );

  const [priceValue, setPriceValue] = useState("");

  return (
    <>
      <div className={styles.createAd}>
        <form
          id="adForm"
          onSubmit={handleSubmit(onSubmit)}
          className={styles.form}
        >
          <ul className={styles.list}>
            <li className={styles.item}>
              <label>Категория</label>
              <Select
                style={{ flex: "0 1 50%" }}
                defaultValue={{
                  id: defaultValues.category_id.toString(),
                  name:
                    categoriesOptions.find(
                      (option) =>
                        option.id === defaultValues.category_id.toString()
                    )?.name || "",
                }}
                name="category_id"
                label="Все категории"
                options={categoriesOptions}
                register={register({
                  valueAsNumber: true,
                  required: "Это поле обязательное",
                })}
              />
            </li>
            <li className={styles.item}>
              <label>Состояние</label>
              <Select
                defaultValue={{
                  id: defaultValues.condition.toString(),
                  name:
                    conditions.find(
                      (cond) => cond.key === defaultValues.condition
                    )?.name || "",
                }}
                name={"condition"}
                style={{
                  width: isMobile ? "100%" : 100,
                }}
                register={register({
                  required: "Это поле обязательное",
                })}
                options={conditions.map((cond) => {
                  return { id: cond.key, name: cond?.name };
                })}
              />
            </li>

            <li className={styles.item}>
              <label>Название объявления</label>
              <input
                ref={register({
                  required: "Это поле обязательное",
                })}
                name="title"
                className={styles.input}
                type="text"
              />
            </li>
            <li className={styles.item}>
              <label>Описание объявления</label>
              <textarea
                className={styles.description}
                ref={register({
                  required: "Это поле обязательное",
                })}
                name="description"
              ></textarea>
            </li>
            <li className={styles.item}>
              <label>Количество</label>
              <div
                className={cn(
                  styles.withDimension,
                  styles.input,
                  styles.quantity
                )}
              >
                <input
                  ref={register({
                    valueAsNumber: true,
                    required: "Это поле обязательное",
                  })}
                  name="quantity"
                  type="number"
                />
              </div>
            </li>
            <li className={styles.item}>
              <label>Цена</label>
              <div
                className={
                  styles.withDimension + " " + styles.input + " " + styles.price
                }
              >
                <input
                  ref={register()}
                  name="price"
                  type="text"
                  value={formatPriceString(priceValue)}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replaceAll(/[^\d]/g, "");
                    setPriceValue(value);
                  }}
                />
              </div>
            </li>
            <li className={styles.item}>
              <label>Фотографии</label>
              <ImageUploading
                multiple
                value={images}
                // @ts-expect-error
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
              >
                {({ imageList, onImageUpload, onImageRemove, dragProps }) => (
                  // write your building UI
                  <div className={styles.uploadWrapper}>
                    {images.map((image, index) => {
                      return (
                        <div key={index} className={styles.imageItem}>
                          <AvatarEditor
                            ref={image.ref}
                            image={image["data_url"]}
                            style={{
                              borderRadius: 4,
                              marginRight: 10,
                              width: 106,
                              height: 86,
                              cursor: "auto",
                              objectFit: "contain",
                            }}
                            height={86}
                            position={{
                              x: 0.5,
                              y: 0.5,
                            }}
                            color={[255, 255, 255, 0]} // RGBA
                            rotate={image.rotate || 0}
                          />
                          <button
                            type="button"
                            className={styles.buttonRotate}
                            onClick={() => onImageRotate(index)}
                          />
                          <button
                            type="button"
                            className={styles.buttonDelete}
                            onClick={() => {
                              onImageRemove(index);
                              // @ts-expect-error
                              if (image.id) {
                                dispatch(
                                  // @ts-expect-error
                                  deletePhoto(+match.params.id, image.id)
                                );
                              }
                            }}
                          />
                        </div>
                      );
                    })}
                    <button
                      type="button"
                      className={styles.uploadBtn}
                      onClick={onImageUpload}
                      {...dragProps}
                    ></button>
                  </div>
                )}
              </ImageUploading>
            </li>
            <li className={styles.item}>
              <label>Город</label>
              <Select
                defaultValue={{
                  id: defaultValues.city_id.toString(),
                  name:
                    citiesOptions.find(
                      (city) => city.id === defaultValues.city_id.toString()
                    )?.name || "",
                }}
                name={"city_id"}
                label="Все города"
                register={register({
                  valueAsNumber: true,
                  required: "Это поле обязательное",
                })}
                options={citiesOptions}
              />
            </li>
            <li className={styles.item}>
              <label>Телефон</label>
              <input
                ref={register({
                  required: "Это поле обязательное",
                })}
                name="phone"
                value={user?.phone}
                disabled
                style={{ width: isMobile ? "100%" : 225 }}
                className={styles.input}
                type="tel"
                onChange={(e) => {
                  e.target.value = normalizePhoneNumber(e.target.value);
                }}
              />
            </li>
            <li className={styles.item}>
              <label>Адрес</label>
              <Select
                style={{ flex: 1 }}
                label="адрес"
                name="geo"
                options={searchResultOptions}
                register={register({
                  required: "Это поле обязательное",
                })}
                withSearch
                onSearch={onAddressSearch}
                onChange={(e, option) => {
                  const geo = ("" + option.id)
                    .split(" ")
                    .map((x) => +x)
                    .reverse();
                  setGeo(geo);
                }}
              />
            </li>
            {geo && (
              <li className={styles.item}>
                <YMaps>
                  <Map
                    width={"100%"}
                    height={450}
                    state={{ center: geo, zoom: 17 }}
                  >
                    <Placemark geometry={geo} />
                  </Map>
                </YMaps>
              </li>
            )}
          </ul>
        </form>
        <div className={styles.submitBtnWrapper}>
          <Warning errors={errors} />
          <button
            form="adForm"
            type="submit"
            disabled={isSubmitting}
            className={styles.submitBtn}
          >
            Разместить объявление
            <div className={styles.submitBtnPreloader}>
              <Preloader classNames={[styles.submitBtnPreloaderElement]} />
            </div>
          </button>
        </div>
      </div>

      {displayModal && (
        <Modal
          handleClose={() => {
            history.push(Path.Profile);
            return dispatch(actions.setShowEditedModal(false));
          }}
          classNames={[styles2.createAdModal]}
        >
          <CreateAdModalSuccess
            onClose={() => {
              history.push(Path.Profile);
              return dispatch(actions.setShowEditedModal(false));
            }}
          />
        </Modal>
      )}
      {showUserBlockedModal && (
        <Modal
          classNames={[styles.moderateModal]}
          handleClose={() => {
            setShowUserBlockedModal(false);
            history.replace(Path.Home);
          }}
        >
          <ModerateModalContent>
            <p>
              Вы не можете редактировать объявления, так как вы заблокированны
            </p>
          </ModerateModalContent>
        </Modal>
      )}
    </>
  );
});
