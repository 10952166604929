import React, { FC } from "react";
import cn from "clsx";
import styles from "./Modal.module.scss";
import { Button } from "../Button/Button";

type Props = {
  handleClose: () => void;
  isAdmin?: boolean;
  displayCloseBtn?: boolean;
  classNames?: string[];
  onContinue?: () => void;
};

export const Modal: FC<Props> = React.memo(
  ({
    children,
    handleClose,
    isAdmin = false,
    displayCloseBtn = true,
    classNames = [],
    onContinue,
  }) => {
    return (
      <>
        <div
          className={cn(styles.modalOverlay, {
            [styles.modalOverlay__admin]: isAdmin,
          })}
          onClick={handleClose}
        />

        <div
          className={cn(
            styles["modal-authorization"],
            {
              [styles["modal-authorization--admin"]]: isAdmin,
            },
            ...classNames
          )}
        >
          <div
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            {children}
          </div>
          {displayCloseBtn && (
            <button
              type="button"
              className={styles.closeBtn}
              onClick={handleClose}
            >
              <i className="icon-cancel" />
            </button>
          )}
          {onContinue && (
            <div className={styles.buttonContainer}>
              <Button
                button={{
                  onClick: onContinue,
                }}
              >
                Перейти
              </Button>
            </div>
          )}
        </div>
      </>
    );
  }
);
