import React, { useState } from "react";
import Input from "../../../../shared/ui/Input/Input";
import Button from "../../../../components/Button/Button";
import Modal from "../../../../components/Modal/Modal";

const MAX_VISIBLE_VALUES_LENGTH = 5;

interface CategoryValueSelectProps {
  values: {id: number, name: string}[];
  onSelect: (valueId: number) => void;
  selectedValueId?: number;
  onUnSelect: () => void;
  searchHeaderText?: string;
  search?: boolean;
}

const CategoryValueSelect: React.FC<CategoryValueSelectProps> = (props) => {
  const {
    values,
    selectedValueId,
    onSelect,
    onUnSelect,
    search,
    searchHeaderText,
  } = props;

  const [searchValue, setSearchValue] = useState("");
  const [valueId, setValueId] = useState<number | null>(selectedValueId || null);
  const [modalVisible, setModalVisible] = useState(false);

  const filteredValues = values
    .filter((value) => value.name.toLowerCase().includes(searchValue.toLowerCase()));

  return (
    <>
      {valueId && (
        <Button
          onClick={() => {
            setValueId(null);
            onUnSelect();
            setSearchValue("");
          }}
        >
          {values.find((value) => value.id === valueId)?.name}
        </Button>
      )}
      {valueId === null && (
        <div className="max-w-[500px]">
          {search && (
            <Input
              className="mb-2 w-full"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          )}
          <div className="flex gap-2 flex-wrap">
            {filteredValues.slice(0, MAX_VISIBLE_VALUES_LENGTH).map((value) => (
              <Button
                key={value.id}
                compact={values.length > 5}
                onClick={() => {
                  setValueId(value.id);
                  onSelect(value.id);
                }}
              >
                {value.name}
              </Button>
            ))}
            {filteredValues.length > MAX_VISIBLE_VALUES_LENGTH && (
              <Button
                compact={values.length > 5}
                theme="transparent"
                className="text-c-10"
                onClick={() => setModalVisible(true)}
              >
                Еще {filteredValues.length - MAX_VISIBLE_VALUES_LENGTH}
              </Button>
            )}
          </div>
        </div>
      )}
      {modalVisible && (
        <Modal
          className="p-10 w-[640px]"
          handleClose={() => setModalVisible(false)}
        >
          <h3 className="text-24 font-medium">{searchHeaderText}</h3>
          <div className="mt-6 flex gap-4 items-center">
            <span>Поиск</span>
            <Input
              className="w-full"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          <div className="mt-10 grid grid-cols-4 gap-5">
            {filteredValues.map((value) => (
              <button
                className="text-16 text-left hover:text-c-10"
                onClick={() => {
                  setValueId(value.id);
                  onSelect(value.id);
                  setModalVisible(false);
                }}
              >
                {value.name}
              </button>
            ))}
          </div>
        </Modal>
      )}
    </>
  );
};

export default CategoryValueSelect;
