export const filterFilesByFormats = (files: File[], formats: string[]): File[] => {
  return files.filter((file) =>
    formats.some((format) => file.name.toLowerCase().endsWith(format.toLowerCase()))
  );
};

export const convertToBase64 = (file: File): Promise<ArrayBuffer | string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event?.target?.result) {
        resolve(event.target.result);
      }
    };
    reader.onerror = (err) => reject(err);
    reader.readAsDataURL(file);
  });
