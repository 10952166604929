import React, { FC, useMemo } from "react"

type Props = {
  errors: any
}

export const Warning: FC<Props> = React.memo((errors) => {
  const gotErrors = useMemo(() => Object.keys(errors.errors).length, [
    errors.errors,
  ])

  return gotErrors ? (
    <span style={{ color: "#ff0000", fontSize: 13, fontFamily: "Arial" }}>
      Заполните все поля
    </span>
  ) : null
})
