import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  confirmPhone,
  requestConfirmCode,
  actions,
} from "../../../redux/auth/actionCreators";
import { getPhoneModal } from "../../../redux/auth/selectors";
import { getSignUpFormData } from "../../../utils/services";
import {
  PhoneConfirmModal,
  Props as PhoneConfirmModalProps,
} from "../PhoneConfirmModal/PhoneConfirmModal";
import { SignUp, Props as SignUpProps } from "../SignUp/SignUp";
import { clearSignUpFormData } from "../../../utils/services";
import { Modal } from "../../common/Modal/Modal";
import { TConfirmPhoneBody } from "../../../api/auth";

type TTab = {
  component: React.ComponentType<SignUpProps & PhoneConfirmModalProps>;
};

const PhoneConfirmModalTabWrapper: React.FC<PhoneConfirmModalProps> = (
  props
) => {
  const history = useHistory<TLocationState>();
  const location = useLocation<TLocationState>();
  const onClose = useCallback(() => {
    clearSignUpFormData();
    history.push({
      ...location,
      state: {
        activeStep: 0,
      },
    });
  }, [location, history]);
  return (
    <Modal handleClose={onClose}>
      <PhoneConfirmModal {...props} />
    </Modal>
  );
};

const TABS: TTab[] = [
  {
    component: SignUp,
  },
  {
    component: PhoneConfirmModalTabWrapper,
  },
];

export type TLocationState = {
  activeStep: number;
};

export const SignUpStepper: React.FC<SignUpProps> = (props) => {
  const history = useHistory<TLocationState>();
  const dispatch = useDispatch();
  const phoneModal = useSelector(getPhoneModal);
  const { state = { activeStep: 0 }, ...location } =
    useLocation<TLocationState>();

  const [canRequestCode, setCanRequestCode] = useState(false);

  const onPhoneConfirm = useCallback(
    (data: TConfirmPhoneBody) => {
      dispatch(confirmPhone(data, getSignUpFormData()));
    },
    [dispatch]
  );

  const requestCode = useCallback(() => {
    setCanRequestCode(false);
    const data = getSignUpFormData();
    dispatch(requestConfirmCode({ msisdn: data.phone }, data));
  }, [dispatch, setCanRequestCode]);

  const onGoStepBack = useCallback(() => {
    history.push(
      {
        ...location,
      },
      { activeStep: 0 }
    );
    dispatch(
      actions.setPhoneModal({
        timer: null,
        showModal: false,
        errorMessage: null,
      })
    );
  }, [dispatch, history, location]);

  const tab = TABS[state.activeStep];

  useEffect(() => {
    return () => {
      clearSignUpFormData();
      history.push({ ...location }, { activeStep: 0 });
    };
  }, []); // eslint-disable-line

  return (
    <tab.component
      {...props}
      onSubmit={onPhoneConfirm}
      countDownSeconds={phoneModal.timer}
      msisdn={getSignUpFormData().phone}
      errorMessage={phoneModal.errorMessage}
      onRequestCode={requestCode}
      canRequestCode={canRequestCode}
      setCanRequestCode={setCanRequestCode}
      onGoStepBack={onGoStepBack}
    />
  );
};
