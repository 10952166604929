import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getCategoriesByRoot,
  getCategoryValue,
  getRootCategoryValues,
  postAdCreate,
  postNextCategoriesValues
} from './ad.api';
import { NextCategoriesValues, PostAdCreateData, PostNextCategoriesValuesData } from './ad.types';

const staleTime = 5 * 60 * 1000
const nextCategoriesValuesKey = 'nextCategoriesValues'

export const useGetCategoriesByRoot = (rootCategoryId: number) => {
  return useQuery(['rootCategories', rootCategoryId], () => getCategoriesByRoot(rootCategoryId), {
    staleTime
  });
};

export const useGetCategoryValue = (id: number) => {
  return useQuery(['categoryValues', id], () => getCategoryValue(id), {
    staleTime
  });
};

export const useGetRootCategoryValues = () => {
  return useQuery(['rootCategoryValues'], getRootCategoryValues, {
    staleTime
  });
};

export const usePostNextCategoriesValues = () => {
  const queryClient = useQueryClient();
  return useMutation((data: PostNextCategoriesValuesData) => postNextCategoriesValues(data), {
    onSuccess: (data) => {
      queryClient.setQueryData([nextCategoriesValuesKey], data)
    }
  });
};

export const useGetNextCategoriesCache = () => {
  const queryClient = useQueryClient();
  return useQuery([nextCategoriesValuesKey], () => queryClient.getQueryData([nextCategoriesValuesKey]) as NextCategoriesValues[] || [])
}

export const usePostAdCreate = () => {
  return useMutation((data: PostAdCreateData) => postAdCreate(data) );
};