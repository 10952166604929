import React from "react";
import cn from "clsx";
import ColorPicker from "../../../shared/ui/ColorPicker/ColorPicker";
import Button from "../../../components/Button/Button";
import Input from "../../../shared/ui/Input/Input";
import { useUrlParams } from "../../../hooks/useUrlParams";
import Tabs from "../../../components/Tabs/Tabs";
import { useOptionsMock } from "../../../api/mock";
import Select from "../../../components/Select/Select";

type Props = {
  className?: string;
};

const CarFilter: React.FC<Props> = ({ className }) => {
  const { urlParams, setUrlParam } = useUrlParams();

  const { data } = useOptionsMock();

  const manufacturerId = urlParams["manufacturer"] as string;

  const models = manufacturerId
    ? data?.manufacturer.find((x) => x.id === manufacturerId)?.model
    : [];

  return (
    <div className={cn("p-6 rounded-6 bg-c-2", className)}>
      <Tabs
        tabs={[
          { text: "Все" },
          { id: "new", text: "Новые" },
          { id: "used", text: "С пробегом" },
        ]}
        selectedTab={urlParams["type"] as string}
        onSelect={(value) => setUrlParam("type", value)}
      />
      <div className="mt-6 flex gap-6">
        <Select
          placeholder="Марка"
          className="w-full"
          options={data?.manufacturer.map((m) => ({
            id: m.id,
            text: m.name,
          }))}
          onSelect={(value) => {
            setUrlParam("model");
            setUrlParam("manufacturer", value);
          }}
          selectedId={urlParams["manufacturer"] as string}
        />
        <Select
          className="w-full"
          placeholder="Модель"
          options={models?.map((m) => ({
            id: m.id,
            text: m.name,
          }))}
          onSelect={(value) => setUrlParam("model", value)}
          selectedId={urlParams["model"] as string}
          disabled={!manufacturerId}
        />
        <div className="flex items-center gap-4">
          <span>Цвет</span>
          <ColorPicker
            colors={["red", "green", "black", "grey", "hotpink"]}
            value={urlParams["color"] as string}
            onChange={(value) => setUrlParam("color", "" + value)}
          />
        </div>
      </div>
      <div className="mt-6 flex gap-6">
        <Select
          className="flex-1"
          placeholder="Кузов"
          options={data?.bodyType.map((m) => ({
            id: m.id,
            text: m.name,
          }))}
          onSelect={(value) => setUrlParam("body_type", value)}
          selectedId={urlParams["body_type"] as string}
        />
        <Select
          className="flex-1"
          placeholder="Коробка"
          options={data?.transmitionType.map((m) => ({
            id: m.id,
            text: m.name,
          }))}
          onSelect={(value) => setUrlParam("transmission_type", value)}
          selectedId={urlParams["transmission_type"] as string}
        />
        <div className="flex-1 flex gap-1">
          <Input
            className="w-full"
            placeholder="Год, от"
            type="number"
            value={urlParams["year_from"] as string}
            onChange={(e) => setUrlParam("year_from", e.target.value)}
          />
          <Input
            className="w-full"
            placeholder="до"
            type="number"
            value={urlParams["year_to"] as string}
            onChange={(e) => setUrlParam("year_to", e.target.value)}
          />
        </div>
      </div>
      <div className="mt-4 flex gap-6">
        <Select
          className="flex-1"
          placeholder="Привод"
          options={data?.driveType.map((m) => ({
            id: m.id,
            text: m.name,
          }))}
          onSelect={(value) => setUrlParam("drive_type", value)}
          selectedId={urlParams["drive_type"] as string}
        />
        <Select
          className="flex-1"
          placeholder="Двигатель"
          options={data?.engineType.map((m) => ({
            id: m.id,
            text: m.name,
          }))}
          onSelect={(value) => setUrlParam("engine_type", value)}
          selectedId={urlParams["engine_type"] as string}
        />
        <div className="flex-1 flex gap-1">
          <Input
            className="w-full"
            placeholder="Цена, от"
            type="number"
            value={urlParams["seats_min"] as string}
            onChange={(e) => setUrlParam("seats_min", e.target.value)}
          />
          <Input
            className="w-full"
            placeholder="до"
            type="number"
            value={urlParams["seats_max"] as string}
            onChange={(e) => setUrlParam("seats_max", e.target.value)}
          />
        </div>
      </div>
      <Button className="mt-6" theme="green">
        Показать 84 458 предложений
      </Button>
    </div>
  );
};

export default CarFilter;
