import React, { useMemo, useState } from "react";
import cn from "clsx";
import { useClickOutside } from "../../hooks/useClickOutside";
import { ReactComponent as Icon } from "../../assets/images/icons/icon-select.svg";

type Props = {
  className?: string;
  placeholder?: string;
  options?: { id: string; text: string }[];
  selectedId?: string;
  onSelect?: (id: string) => void;
  disabled?: boolean;
};

const Select: React.FC<Props> = ({
  className,
  placeholder,
  options,
  onSelect,
  selectedId,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useClickOutside(() => setIsOpen(false));
  const selectedOptionText = useMemo(() => {
    const option = options?.find((x) => x.id === selectedId);
    return option?.text;
  }, [options, selectedId]);

  return (
    <div
      ref={ref}
      className={cn(
        "relative",
        disabled && "opacity-50 pointer-events-none",
        className
      )}
    >
      <div
        className="w-full h-[43px] bg-c-3 px-4 pt-2.5 pb-3 rounded-3 cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        {selectedOptionText || <span className="text-c-10">{placeholder}</span>}
        <Icon className="absolute bottom-3 right-4" />
      </div>
      {isOpen && (
        <div className="absolute z-10 top-full w-full bg-c-4 rounded-2 overflow-auto max-h-[205px] flex flex-col select-none cursor-pointer">
          {options?.map((option) => (
            <div
              key={option.id}
              onClick={() => {
                onSelect?.(option.id === selectedId ? "" : option.id);
                setIsOpen(false);
              }}
              className={cn(
                "py-2 px-4 hover:bg-c-5",
                option.id === selectedId && "bg-c-6"
              )}
            >
              {option.text}
            </div>
          ))}
          {console.log(selectedId, options)}
        </div>
      )}
    </div>
  );
};

export default Select;
