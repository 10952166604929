import React, { FC } from "react"
import s from "./CreateAdModalSuccess.module.scss"
import { Button } from "../../../../components/common/Button/Button"

type Props = {
  onClose: () => void
}

export const CreateAdModalSuccess: FC<Props> = ({ onClose }) => {
  return (
    <div className={s.content}>
      <p>Пользователи увидят ваше объявление после проверки.</p>
      <p>
        Обычно она занимает несколько минут, в редких случаях нам нужно больше
        времени.
      </p>
      <Button theme="red" classNames={[s.close]} onClick={onClose}>
        Закрыть
      </Button>
    </div>
  )
}
