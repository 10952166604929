import React, { useState } from "react";
import cn from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedIn, getUser } from "../../redux/auth/selectors";
import { Link } from "react-router-dom";
import { getImageSrc } from "../../utils/helpers";
import { actions, logout } from "../../redux/auth/actionCreators";
import Path from "../../pages/router.const";
import { useClickOutside } from "../../hooks/useClickOutside";
import Avatar from "./Avatar";

const MENU = [
  { text: "Мои объявления", path: Path.ProfileAds },
  { text: "Избранное", path: Path.Favorites },
  { text: "Профиль", path: Path.Profile },
  { text: "Поддержка", path: Path.ProfileSupport },
  {
    text: "Выйти",
    path: Path.Home,
    onClick: (onExit: () => void) => onExit(),
  },
];

const AvatarButton: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const loggedIn = useSelector(getLoggedIn);
  const user = useSelector(getUser);
  const ref = useClickOutside(() => setIsOpen(false));

  const onExit = () => {
    dispatch(logout());
  };

  return loggedIn ? (
    <div className="relative" ref={ref}>
      <div
        className="flex items-center gap-3.5 cursor-pointer select-none"
        onClick={() => setIsOpen((value) => !value)}
      >
        <Avatar />
        <span className="text-20">{user?.name}</span>
      </div>
      <div
        className={cn(
          "absolute top-full px-4 py-6 bg-c-2 border border-c-4 rounded-4",
          "shadow-lalka",
          !isOpen && "hidden"
        )}
      >
        <div className="px-4 gap-4 flex items-center">
          <div className="w-10 h-10 rounded-full bg-d flex items-center justify-center text-24 text-c-1 font-medium uppercase">
            {user?.avatar ? (
              <img src={getImageSrc(user.avatar, "avatars")} alt="Аватар" />
            ) : (
              <div>
                {typeof user?.name === "string" ? user?.name[0] || "" : ""}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <span>{user?.email}</span>
            <span className="text-c-10">id {user?.id}</span>
          </div>
        </div>
        <div className="mt-4 flex flex-col">
          {MENU.map((x) => (
            <Link
              key={x.text}
              to={x.path}
              onClick={() => {
                setIsOpen(false);
                x.onClick?.(onExit);
              }}
              className="px-4 pt-1 pb-1.5 hover:bg-c-7 rounded-2"
            >
              {x.text}
            </Link>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <button
      onClick={() => dispatch(actions.setActiveAuthModalAC("SignIn"))}
      className="px-4 py-2 rounded-3 text-16 hover:bg-c-3 hover:text-c-15"
    >
      Войти
    </button>
  );
};

export default AvatarButton;
