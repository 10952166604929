import React, { FC } from "react";
import { CSSTransition } from "react-transition-group";
import cn from "clsx";
import styles from "./Preloader.module.scss";

type PreloaderProps = {
  isFullScreen?: boolean;
  classNames?: string[];
  preloaderColor?: string;
};

const PreloaderComponent: FC<PreloaderProps> = React.memo(
  ({ isFullScreen = false, classNames = [], preloaderColor = "#3ea1f9" }) => {
    return (
      <div
        className={cn(
          styles.content,
          { [styles.content__full]: isFullScreen },
          ...classNames
        )}
      >
        <div className={styles.loader}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.0"
            width="50px"
            height="50px"
            viewBox="0 0 128 128"
            xmlSpace="preserve"
          >
            <g>
              <path
                d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z"
                fill={preloaderColor}
                fillOpacity="1"
              />
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 64 64"
                to="360 64 64"
                dur="1400ms"
                repeatCount="indefinite"
              ></animateTransform>
            </g>
          </svg>
        </div>
      </div>
    );
  }
);

type TransitionPreloaderProps = {
  condition: boolean;
  preloaderProps?: PreloaderProps;
};

const TransitionPreloader: FC<TransitionPreloaderProps> = React.memo(
  ({ condition, preloaderProps = {} }) => {
    return (
      <CSSTransition
        in={condition}
        timeout={500}
        classNames={{
          enter: styles.contentEnter,
          enterActive: styles.contentEnterActive,
          enterDone: styles.contentEnterDone,
          exit: styles.contentExit,
          exitActive: styles.contentExitActive,
          exitDone: styles.contentExitDone,
        }}
        unmountOnExit
        mountOnEnter
      >
        <Preloader {...preloaderProps} />
      </CSSTransition>
    );
  }
);

export const Preloader = Object.assign(PreloaderComponent, {
  Transition: TransitionPreloader,
});

type SuspencePreloaderProps = {
  condition?: boolean;
};

export const SuspencePreloader: React.FC<SuspencePreloaderProps> = ({
  condition = true,
}) => {
  return (
    <Preloader.Transition
      condition={condition}
      preloaderProps={{ isFullScreen: true }}
    />
  );
};
