import React from "react";
import Price from "../Price/Price";
import { AdType } from "./ad.type";

type Props = {
  ad: AdType;
};

const AdItemGrid: React.FC<Props> = ({ ad }) => {
  return (
    <div className="flex flex-col gap-4">
      <img
        alt=""
        src={ad.photo[0]}
        width={320}
        height={240}
        className="bg-c-3 rounded-4"
      />
      <div className="flex flex-col gap-2">
        <Price value={ad.price} className="text-24 font-medium" />
        <p>
          {ad.properties.find((x) => x.id === "manufacturer")?.text}{" "}
          {ad.properties.find((x) => x.id === "model")?.text},{" "}
          {ad.properties.find((x) => x.id === "year")?.value}
        </p>
        <p className="text-c-11">
          {ad.properties.find((x) => x.id === "year")?.value} /{" "}
          {ad.properties.find((x) => x.id === "mileage")?.value} км
        </p>
      </div>
    </div>
  );
};

export default AdItemGrid;
